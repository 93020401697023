import { useMutation } from "@apollo/client";
import { DESTROY_CHARACTERISTIC } from "@graphql/mutations";
import { SweetAlert } from "@services";
import { SharedTableFields } from "../SharedTableFields";
import { ALL_STEPS_COUNTER } from "@graphql/queries";

export const CharacteristicsTableFields = (props) => {
  const { remove, values, errors } = props;

  const [destroyCharacteristic, { destroyLoading }] = useMutation(
    DESTROY_CHARACTERISTIC,
    {
      onCompleted: () => {
        SweetAlert("Characteristic has been deleted", "success");
      },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    }
  );

  const handleRemove = async (remove, index, id) => {
    try {
      await destroyCharacteristic({
        variables: {
          characteristicId: id,
        },
      });
      const removeCharacteristic = () => remove(index);
      removeCharacteristic();
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  const Fields = [
    {
      type: "text",
      name: "id",
    },
    {
      type: "text",
      name: "name",
      placeholder: "Enter name",
    },
    {
      type: "text",
      name: "value1",
      placeholder: "Enter value 1",
    },
    {
      type: "text",
      name: "value2",
      placeholder: "Enter value 2",
    },
    {
      type: "text",
      name: "value3",
      placeholder: "Enter value 3",
    },
    {
      type: "text",
      name: "value4",
      placeholder: "Enter value 4",
    },
    {
      type: "text",
      name: "value5",
      placeholder: "Enter value 5",
    },
    {
      type: "text",
      name: "value6",
      placeholder: "Enter value 6",
    },
    {
      type: "text",
      name: "value7",
      placeholder: "Enter value 7",
    },
    {
      type: "text",
      name: "value8",
      placeholder: "Enter value 8",
    },
  ];

  return (
    <SharedTableFields
      destroyLoading={destroyLoading}
      values={values}
      remove={remove}
      fields={Fields}
      lastFieldIndex={9}
      handleRemove={handleRemove}
      errors={errors}
    />
  );
};
