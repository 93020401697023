import { Link } from "react-router-dom";
import { notFoundImage } from "@images";

export const NotFound = () => {
  return (
    <div className="h-100 flex-column d-flex align-items-center justify-content-center">
      <img src={notFoundImage} alt="" className="img-fluid" />
      <Link to="/" className="btn btn-primary mt-3">
        Go to Dashboard
      </Link>
    </div>
  );
};
