import { gql } from "@apollo/client";
import { userAttributes } from "./mutations";

const valueDetails = `id
  position
  value`;

const valueDriverMostUsedAttrs = `id
  name
  category
  type`;

const benefitsMostUsedAttrs = `id
  name
  value
  verified
  unitOfMeasure`;

const valueDriverAttrsWithDimensions = `${valueDriverMostUsedAttrs}
  restrictDimensions {
    ${valueDetails}
    numericalValue
  }`;

const userBasicDetails = `id
  fullName
  email`;

const onboardingResponseDetails = `id
  responseQ1
  responseQ2
  responseQ3
  responseQ4
  responseQ5
  responseQ6
  responseQ7
  responseQ8
  responseQ9
  responseQ10
  responseQ11
  responseQ12
  responseQ13
  responseQ14
  user {
    ${userBasicDetails}
  }`;

const segmentDetails = `id
  importance
  marketSize
  name
  position`;

export const FETCH_USER = gql`
  query fetchUser {
    fetchUser {
      ${userAttributes}
    }
  }
`;

export const FETCH_INVITED_MEMBERS = gql`
  query invitedUsers($category: InvitedCategoryEnum!, $archive: Boolean) {
    invitedUsers(category: $category, archive: $archive) {
      ${userAttributes}
    }
  }
`;

export const ALL_USERS = gql`	
  query allUsers {
    allUsers(skipAdmin: true) {
      nodes {
        ${userBasicDetails}
        revokeAccess
        subscription
      }
    }
  }
`;

export const ALL_CONTACTORS = gql`
  query AllContactors($page: Int, $perPage: Int) {
    allContactors(page: $page, perPage: $perPage) {
      nextPage
      prevPage
      totalPages
      allContactors {
        ${userBasicDetails}
        enquireAbout
        message
      }
    }
  }
`;

export const GET_CHECKOUT_URL = gql`
  query getCheckoutUrl($subscriptionPlan: String!) {
    getCheckoutUrl(subscriptionPlan: $subscriptionPlan) {
      url
    }
  }
`;

export const GET_SESSION_URL = gql`
  query getSessionUrl {
    getSessionUrl {
      url
    }
  }
`;

export const EXPORT_USER_RESPONSES = gql`
  query exportUsersResponses {
    exportUsersResponses {
      result
    }
  }
`;

export const ALL_ONBOARDING_USER_RESPONSE = gql`
  query allOnboardingUserResponses($page: Int, $perPage: Int) {
    allOnboardingUserResponses(page: $page, perPage: $perPage) {
      nextPage
      prevPage
      totalPages
      userResponses {
        ${onboardingResponseDetails}
      }
    }
  } 
`;

export const SINGLE_ONBOARDING_USER_RESPONSES = gql`
  query singleOnboardingUserResponses($userId: ID!) {
    singleOnboardingUserResponses(userId: $userId) {
      ${onboardingResponseDetails}
    }
  }
`;

export const ADMIN_DASHBOARD_ANALYTICS = gql`
  query adminDashboardAnalytics {
    adminDashboardAnalytics {
      contactorsCount
      usersCount
      usersOnboardingResponsesCount
      paidUsersCount
    }
  }
`;

export const PRODUCT_BENEFITS = gql`
  query productBenefits($productId: ID!, $isMainView: Boolean) {
    productBenefits(productId: $productId, isMainView: $isMainView) {
      ${benefitsMostUsedAttrs}
      description
      quantifiable
      tangible
      status
      createdAt
      owner {
        fullName
        email
      }
    }
  }
`;

export const PRODUCT_VALUE_DRIVERS = gql`
  query productValueDrivers($productId: ID!,  $isMainView: Boolean) {
    productValueDrivers(productId: $productId, isMainView: $isMainView) {
      highestValue
      valueDrivers {
        ${valueDriverAttrsWithDimensions}
        benefit {
          id
        }
        createdAt
        owner {
          fullName
          email
        }
        description
        featureStatus
        unitOfMeasure
        
            status
      
      }
    }
  }
`;

export const CUSTOMER_CHARACTERISTICS = gql`
  query customerCharacteristics($productId: ID!) {
    customerCharacteristics(productId: $productId) {
      id
      name
      customerValues {
        ${valueDetails}
      }
    }
  }
`;

export const PRODUCT_SEGMENTS = gql`
  query productSegments($productId: ID!) {
    productSegments(productId: $productId) {
      ${segmentDetails}
      valuesOfSegments {
        customerValue {
          value
        }
      }
    }
  }
`;

export const PRODUCT_WEIGHT_ALLOCATIONS = gql`
  query productWeightAllocations($productId: ID!) {
    productWeightAllocations(productId: $productId) {
      id
      segment {
        id
      }
      valueDriver {
        id
      }
      value
    }
  }
`;

export const PRODUCT_WEIGHT_SEQUENCE = gql`
  query productWeightInSequence($productId: ID!) {
    productWeightInSequence(productId: $productId) {
      valueDriver {
        ${valueDriverMostUsedAttrs}
      }
      segments {
        segmentId
        name
        weightAllocation
      }
    }
  }
`;

export const SEGMENTS_AND_VALUES = gql`
  query segmentsAndValues($productId: ID!) {
    segmentsAndValues(productId: $productId) {
      characteristics {
        id
        name
        customerValues {
          ${valueDetails}
        }
        selectedValues {
          segmentPosition
          value {
            ${valueDetails}
          }
        }
      }
      segments {
        ${segmentDetails}
      }
    }
  }
`;

export const ALL_STEPS_COUNTER = gql`
  query allStepsCounter($productId: ID!) {
    allStepsCounter(productId: $productId) {
      benefitsCount
      characteristicsCount
      customerValuesCount
      dimensionsCount
      segmentsCount
      valueDriversCount
    }
  }
`;

export const EXPORT_VALUE_DRIVERS = gql`
  query exportValueDrivers($productId: ID!) {
    exportValueDrivers(productId: $productId) {
      exportCsvData
    }
  }
`;

export const EXPORT_BENEFITS = gql`
  query exportBenefits {
    exportBenefits {
      exportCsvData
    }
  }
`;

export const EXPORT_CUSTOMER_CHARACTERISTICS = gql`
  query exportCustomerCharacteristics($productId: ID!) {
    exportCustomerCharacteristics(productId: $productId) {
      exportCsvData
    }
  }
`;

export const PRICE_MODEL = gql`
  query priceModelByTabular($productId: ID!) {
    priceModelByTabular(productId: $productId) {
      segments {
        segment {
          ${segmentDetails}
        }
        priceModel {
          competitorPricing
          deltaBwBundles
          id
          psychologicalPricing
          totalPrice
        }
        selectedDimension {
          id
          value
        }
      }
      valueDriver {
        ${valueDriverAttrsWithDimensions}
      }
    }
  }
`;

export const PRODUCT_DASHBOARD_ANALYTICS = gql`
  query ProductDashboardAnalytics($productId: ID!) {
    productDashboardAnalytics(productId: $productId) {
      benefits {
        count
        totalValue
        unvarifiedCount
        verifiedCount
        allBenefits {
          ${benefitsMostUsedAttrs}
        }
      }
      characteristics {
        name
        totalValues
      }
      segmentsPricing {
        segmentName
        totalPrice
        valueDriversCount
        psychologicalPricing
      }
      valueDrivers {
        count
        coreCount
        hygienicCount
        nonCoreCount
        totalDimensions
        allValueDrivers {
          ${valueDriverMostUsedAttrs}
        }
      }
      customerSegments {
        count
        nonStrategicCount
        potentialReach
        strategicCount
      }
      myTodos {
        addBenefits
        addCustomerSegments
        addValueDriversAndDimensions
        calculatePriceModel
        connectBenefitsWithValueDriver
      }
    }
  }
`;
export const USER_PRODUCTS = gql`
  query UserProducts(
    $keyword: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $scenario: String
  ) {
    userProducts(
      filter: { keyword: $keyword }
      first: $first
      last: $last
      after: $after
      before: $before
      scenario: $scenario
    ) {
      edges {
        cursor
      }
      nodes {
        currency
        id
        isCurrentProduct
        name
        slug
        role
        specification
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
