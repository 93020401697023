import { NavLink } from "react-router-dom";

export const SettingsSidebar = () => {
  const links = [
    {
      name: "Profile",
      link: "/profile",
    },
    {
      name: "Edit Profile",
      link: "/edit-profile",
    },
    {
      name: "Change Password",
      link: "/change-password",
    },
  ];

  return (
    <>
      <p className="fs-24 fw-700 mb-28">Settings</p>
      <div className="d-flex setting-header mb-32">
        {links?.map((item, index) => (
          <NavLink to={item.link} key={index} className="pb-2 me-3">
            {item.name}
          </NavLink>
        ))}
      </div>
    </>
  );
};
