import { useState } from "react";
import { SubmitButton } from "@components";
import "./style.scss";
import { useMutation } from "@apollo/client";
import { CREATE_CONTACTOR } from "@graphql/mutations";
import { SweetAlert } from "@services";

export const Contact = () => {
  const [variables, setVariables] = useState({
    fullName: "",
    email: "",
    password: "",
    enquireAbout: "",
    message: "",
  });

  const [createContactor, { loading, error }] = useMutation(CREATE_CONTACTOR);

  const handleChange = (e) => {
    setVariables({ ...variables, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createContactor({
        variables,
      });
      SweetAlert(
        "Your query has been successfully submitted. We will get back to you soon",
        "success"
      );
      e.target.reset();
    } catch (e) {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    }
  };

  return (
    <div className="container-xxl py-5">
      <h5 className="h5 text-center">Need Help?</h5>
      <p className="text-gray-500 text-center mb-5">
        We got you covered. Let us know how can we help you?
      </p>
      <div className="col-lg-8 mx-auto">
        {error && (
          <div className="mb-2 text-danger">
            An error occured. Please try again or check your network connection.
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="mb-2">
              Full Name <span className="text-danger">*</span>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              name="fullName"
              placeholder="Enter your full name"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Email Address <span className="text-danger">*</span>{" "}
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Enter your email address"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Enquire About <span className="text-danger">*</span>{" "}
            </label>
            <select
              className="form-select"
              name="enquireAbout"
              onChange={handleChange}
              required
            >
              <option value="">Select one option</option>
              <option value="Inquire about pricing plan">
                Inquire about pricing plan
              </option>
              <option value="Need help with pricing flow">
                Need help with pricing flow
              </option>
            </select>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Message <span className="text-danger">*</span>{" "}
            </label>
            <textarea
              placeholder="Type your message here"
              className="form-control"
              rows="8"
              name="message"
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="text-center mt-5">
            <SubmitButton loading={loading} size="lg" text="Send" />
          </div>
        </form>
      </div>
    </div>
  );
};
