import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Authentication, HandleChange } from "@services";
import { SubmitButton, InputField } from "@components";
import { Form } from "react-bootstrap";

export const LoginPartial = (props) => {
  const { mutation, error, loading } = props;
  const searchParams = useSearchParams();
  const plan = searchParams[0].get("plan");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [variables, setVariables] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  return (
    <form
      onSubmit={(e) =>
        Authentication(e, mutation, variables, dispatch, navigate)
      }
    >
      {error && <div className="mb-2 text-danger">{error.message}</div>}
      <div className="mb-3">
        <InputField
          onChange={(e) => HandleChange(e, variables, setVariables)}
          type="email"
          required={true}
          name="email"
          placeholder="Enter email address"
          text="Email Address"
        />
      </div>
      <div className="mb-3">
        <InputField
          onChange={(e) => HandleChange(e, variables, setVariables)}
          type="password"
          required={true}
          name="password"
          placeholder="Enter your password"
          text="Password"
        />
      </div>
      <div className="d-flex align-items-center justify-content-between mb-40">
        <Form.Check
          type="checkbox"
          className="fs-14"
          id="remember_me"
          label="Remember Me"
          onChange={() =>
            setVariables({
              ...variables,
              rememberMe: !variables.rememberMe,
            })
          }
        />
        <Link to={`/forgot-password${plan ? `?plan=${plan}` : ""}`}>
          Forgot Password
        </Link>
      </div>
      <SubmitButton loading={loading} width="100" size="lg" text="Login" />
    </form>
  );
};
