import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { SET_PASSWORD } from "@graphql/mutations";
import { Authentication, HandleChange } from "@services";
import { SubmitButton, InputField } from "@components";
import { BelowAuthForm } from "./BelowAuthForm";

export const SetPassword = () => {
  const dispatch = useDispatch();
  const [setPassword, { error, loading }] = useMutation(SET_PASSWORD);

  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const token = searchParams[0].get("token");
  const invitationToken = searchParams[0].get("invitation_token");

  const [variables, setVariables] = useState({
    resetPassword: invitationToken ? false : true,
    token: invitationToken ? invitationToken : token,
    fullName: "",
    password: "",
    passwordConfirmation: "",
  });

  return (
    <>
      <div className="text-center">
        <h5 className="text-gray-700 mb-32">
          {invitationToken ? "Set Your Password" : " Reset Password"}
        </h5>
      </div>
      <form
        onSubmit={(e) =>
          Authentication(e, setPassword, variables, dispatch, navigate)
        }
      >
        {error && <div className="mb-2 text-danger">{error.message}</div>}
        {invitationToken && (
          <div className="mb-3">
            <InputField
              onChange={(e) => HandleChange(e, variables, setVariables)}
              type="text"
              required={true}
              name="fullName"
              placeholder="Enter your full name"
              text="Full Name"
            />
          </div>
        )}
        <div className="mb-3">
          <InputField
            onChange={(e) => HandleChange(e, variables, setVariables)}
            type="password"
            required={true}
            name="password"
            placeholder="Enter a strong 8 digit password"
            text="Enter New Password"
          />
        </div>
        <div className="mb-3">
          <InputField
            onChange={(e) => HandleChange(e, variables, setVariables)}
            type="password"
            required={true}
            name="passwordConfirmation"
            placeholder="Re-enter your password"
            text="Confirm New Password"
          />
        </div>
        <SubmitButton
          loading={loading}
          width="100"
          size="lg"
          text={invitationToken ? "Set Your Password" : " Reset Password"}
        />
      </form>
      <BelowAuthForm page="reset-password" />
    </>
  );
};
