import { useDispatch, useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { setShow } from "@store/canvasSlice";
import { logo } from "@images";
import { Link } from "react-router-dom";
import { SidebarPartial } from "./SidebarPartial.js";

export default function SidebarCanvas() {
  const dispatch = useDispatch();
  const canvasSet = useSelector((state) => state.canvas.show);

  return (
    <Offcanvas
      className="sidebar expand"
      show={canvasSet}
      onHide={() => dispatch(setShow())}
    >
      <Offcanvas.Header className="px-0 pt-0" closeButton>
        <Offcanvas.Title>
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-0">
        <SidebarPartial />
      </Offcanvas.Body>
    </Offcanvas>
  );
}
