import { useLocation } from "react-router-dom";
import { SharedTableHeadings } from "./SharedTableHeadings";

export const WeightPriceTableHeadings = (props) => {
  const location = useLocation();
  const { segmentsCount, segmentNames } = props;
  let Headings;
  const unSubscribedHeadings = [
    {
      text: "Id",
      width: "w-13-p",
    },
    {
      text: "Value Driver",
      tooltip: "The features you defined in Step 2",
      width: "w-13-p",
    },
  ];

  const totalColumn = [
    {
      text: location?.pathname.includes("price") ? "" : "Total",
      tooltip: location?.pathname.includes("price")
        ? ""
        : "The total indicates your unique selling proposition",
      width: "w-4-p",
    },
  ];

  const segmentsHeadings = [];
  for (let i = 0; i < segmentsCount; i++) {
    const newObject = {
      text: segmentNames[i] || "Segment " + (i + 1),
      width: "w-13-p",
    };

    segmentsHeadings.push(newObject);
  }
  Headings = unSubscribedHeadings.concat(segmentsHeadings.concat(totalColumn));
  return <SharedTableHeadings headings={Headings} />;
};
