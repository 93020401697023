import { Link } from "react-router-dom";
import "./style.scss";

export const SubmitButton = (props) => {
  const { loading, text, size, width, rounded } = props;

  return (
    <button
      type="submit"
      className={`btn btn-primary ${width ? `w-${width}` : ""} ${
        size ? `btn-${size}` : ""
      } ${rounded ? "rounded-pill" : ""}`}
      disabled={loading}
    >
      {loading && (
        <i
          className={`fa-solid fa-spinner fa-spin me-8${
            size === "lg" ? " font-lg" : ""
          }`}
        ></i>
      )}
      {text}
    </button>
  );
};

export const ButtonComponent = (props) => {
  const {
    type,
    image,
    icon,
    link,
    text,
    size,
    width,
    rounded,
    iconType,
    onClick,
    disabled,
    position,
    customClass,
    onlyIcon,
  } = props;

  const classes = `btn ${disabled ? "disabled" : ""} ${
    type ? `btn-${type}` : ""
  } ${width ? `w-${width}` : ""} ${size ? `btn-${size}` : ""} ${
    rounded ? "rounded-pill" : ""
  } ${icon || image ? "icon" : ""} ${onlyIcon ? "only-icon" : ""} ${
    customClass ? customClass : ""
  }`;

  const imageIcon = () => {
    return (
      <>
        {image && <img src={image} alt={text} className={position} />}
        {icon && <i className={`fa-${iconType} fa-${icon} ${position}`}></i>}
      </>
    );
  };

  const buttonBody = () => {
    return (
      <>
        {position === "start" && imageIcon()}

        {text}

        {position === "end" && imageIcon()}
      </>
    );
  };

  return (
    <>
      {link ? (
        <Link to={link} className={classes}>
          {buttonBody()}
        </Link>
      ) : (
        <button onClick={onClick} type="button" className={classes}>
          {buttonBody()}
        </button>
      )}
    </>
  );
};
