export const LoadingComponent = (props) => {
  const height = props?.height;
  return (
    <div
      className={`d-flex min-vh-${height} align-items-center justify-content-center`}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
