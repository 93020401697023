import { SharedTableHeadings } from "../SharedTableHeadings";
import { plus, spinner } from "@images";
import { useMutation } from "@apollo/client";
import { SweetAlert } from "@services/SweetAlert";
import { CREATE_SEGMENT } from "@graphql/mutations";
import { SEGMENTS_AND_VALUES, ALL_STEPS_COUNTER } from "@graphql/queries";
import { useParams } from "react-router-dom";
import { SalesFunnelModal } from "@components/SalesFunnelModal";

export const SegmentsTableHeadings = (props) => {
  const { segmentsCount, plan, isTeamMember } = props;
  const params = useParams();
  const [createSegment, { loading }] = useMutation(CREATE_SEGMENT, {
    variables: { productId: params?.id },
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
    refetchQueries: [
      { query: SEGMENTS_AND_VALUES, ALL_STEPS_COUNTER },
      "segmentsAndValues",
      "allStepsCounter",
    ],
  });

  const createNewSegment = async () => {
    if (segmentsCount === 8) {
      SweetAlert("You can't add more than 8 segments.", "error");
    } else {
      try {
        await createSegment();
      } catch (e) {
        console.log(e);
      }
    }
  };

  let Headings;
  const unSubscribedHeadings = [
    {
      text: "Id",
    },
    {
      text: "Characteristic Title",
      tooltip: "Customer Characteristics",
      width: "w-13-p",
    },
  ];
  const plusColumn = [
    {
      text: (
        <div className={isTeamMember ? "d-none " : ""}>
          {plan !== "start" ? (
            segmentsCount < 8 && (
              <img
                src={loading ? spinner : plus}
                alt="Add New"
                className="cursor-pointer"
                onClick={createNewSegment}
              />
            )
          ) : (
            <SalesFunnelModal className="border-0 bg-transparent">
              <img
                src={plus}
                className={isTeamMember ? "pe-none " : ""}
                alt="Upgrade Plan"
              />
            </SalesFunnelModal>
          )}
        </div>
      ),
      width: "w-4-p",
    },
  ];

  const segmentsHeadings = [];
  for (let i = 0; i < segmentsCount; i++) {
    const newObject = {
      text: "Segment " + (i + 1),
      width: "w-13-p",
    };
    segmentsHeadings.push(newObject);
  }

  Headings = unSubscribedHeadings.concat(segmentsHeadings);
  return (
    <SharedTableHeadings
      page="segments"
      headings={Headings.concat(plusColumn)}
    />
  );
};
