import { useMutation } from "@apollo/client";
import { ButtonComponent } from "@components/Buttons";
import { ModalComponent } from "@components/Modal";
import { SalesFunnelModal } from "@components/SalesFunnelModal";
import { TooltipComponent } from "@components/Tooltip";
import { INVITE_USERS } from "@graphql/mutations";
import { FETCH_INVITED_MEMBERS } from "@graphql/queries";
import { SweetAlert } from "@services/SweetAlert";
import { userData } from "@store/authSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export const TopBar = () => {
  const teamMembers = useSelector(
    (state) => state.auth.user?.subscriptionPlan?.features?.team_members?.limit
  );
  const totalInvitees = useSelector((state) => state.auth.user?.totalInvitees);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [inviteModalShow, setinviteModalShow] = useState(false);
  const [CreateInvitation, { loading, error }] = useMutation(INVITE_USERS, {
    onCompleted: () => {
      SweetAlert("User Invite Sent", "success");
      setinviteModalShow(false);
      setEmail("");
    },
    refetchQueries: [{ query: FETCH_INVITED_MEMBERS }, "invitedUsers"],
    onError: (e) => {
      SweetAlert(e, "error");
      console.log(e);
    },
  });
  const HandleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async (e) => {
    const { data } = await CreateInvitation({
      variables: {
        email: email,
      },
    });
    dispatch(userData(data?.createInvitation));
  };
  const links = [
    {
      name: "Team Members",
      link: "/team-member",
    },
    {
      name: "Invites",
      link: "/invites",
    },
  ];
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <p className="fs-24 fw-700 mb-28">Team Management</p>
        {totalInvitees === 5 ? (
          <TooltipComponent
            overlayText={"You can't add more than 5 team members"}
            placement="top"
          >
            <div>
              <ButtonComponent
                type="primary"
                customClass="flex-shrink-0 op-0-5 pe-none"
                text="Invite Team Members"
              />
            </div>
          </TooltipComponent>
        ) : (
          <div>
            {totalInvitees < teamMembers ? (
              <ButtonComponent
                type="primary"
                customClass="flex-shrink-0"
                text="Invite Team Members"
                onClick={() => setinviteModalShow(!inviteModalShow)}
              />
            ) : (
              <SalesFunnelModal className="btn btn-primary flex-shrink-0">
                Invite Team Members
              </SalesFunnelModal>
            )}
          </div>
        )}
      </div>
      <div className="d-flex setting-header mb-3">
        {links?.map((item, index) => (
          <NavLink to={item.link} key={index} className="pb-2 me-3">
            {item.name}
          </NavLink>
        ))}
      </div>
      <ModalComponent
        closeModal={() => setinviteModalShow(false)}
        showModal={inviteModalShow}
        closeButtonText="Close"
        submitButtonText={
          loading ? (
            <i className="fa-solid fa-spinner fa-spin"></i>
          ) : (
            "Send Invite"
          )
        }
        submitButtonType={"primary"}
        handleSubmit={handleSubmit}
        loading={loading}
        closeButtonType="btn btn-light"
        title={"Invite Team Members"}
      >
        {error && <div className="text-danger mb-2">{error?.message}</div>}
        <label htmlFor="email">Email Address</label>
        <input
          id="email"
          type="email"
          className="form-control"
          onChange={(e) => HandleChange(e)}
        />
      </ModalComponent>
    </>
  );
};
