import { InputField, ModalComponent, TooltipComponent } from "@components";
import { useState } from "react";
import { trash } from "@images";
import { Field } from "formik";
import { useOutletContext } from "react-router-dom";

export const SharedTableFields = (props) => {
  const {
    remove,
    values,
    errors,
    fields,
    lastFieldIndex,
    handleRemove,
    destroyLoading,
  } = props;
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState("");
  const [inputFocus] = useState([]);
  const isTeamMember = useOutletContext()[1].isTeamMemberOfCurrentProduct;
  const variables = values?.variables;
  return (
    <>
      {variables.map((_, index) => (
        <tr key={index}>
          {fields?.map((item, fieldIndex) => {
            const fieldVariableName = variables?.[index]?.[item?.name];
            const notQuantifiable =
              item?.name === "value" &&
              variables?.[index]?.quantifiable === false;
            if (notQuantifiable) {
              variables[index].value = 0;
            }
            const fieldName = `variables.${index}.${item?.name}`;
            const fieldErrors = errors?.variables?.[index]?.[item?.name];
            const tooltip = (boolean) => {
              inputFocus[index.toString() + fieldIndex] = boolean;
            };
            return (
              <td key={fieldIndex} className={fieldIndex === 0 ? "d-none" : ""}>
                <TooltipComponent
                  overlayText={
                    inputFocus[index.toString() + fieldIndex] === true
                      ? undefined
                      : fieldVariableName !== ("" || true || false)
                      ? fieldVariableName
                      : undefined
                  }
                  placement="top"
                >
                  <div
                    className={
                      fieldIndex === lastFieldIndex
                        ? "d-flex align-items-center flex-field"
                        : ""
                    }
                  >
                    <div>
                      <Field
                        onMouseOver={() => tooltip(false)}
                        onMouseOut={() => tooltip(false)}
                        onFocus={() => tooltip(true)}
                        onBlur={() => tooltip(false)}
                        as={InputField}
                        className={`${
                          fieldErrors
                            ? "border-red ms-n10"
                            : item?.type !== "switch"
                            ? " ms-n10"
                            : ""
                        }${
                          item?.type !== "switch"
                            ? " form-control"
                            : " form-check-input"
                        }`}
                        placeholder={item?.placeholder}
                        fieldtype={item?.type}
                        type={item?.type === "switch" ? "checkbox" : item?.type}
                        text={
                          item?.type === "switch"
                            ? fieldVariableName
                              ? "Yes"
                              : "No"
                            : ""
                        }
                        name={fieldName}
                        disabled={notQuantifiable}
                        readOnly={item?.name === "id"}
                      />
                      {fieldErrors && (
                        <div className="text-danger hide ms-n10">
                          {fieldErrors}
                        </div>
                      )}
                    </div>
                    {isTeamMember
                      ? ""
                      : fieldIndex === lastFieldIndex && (
                          <img
                            src={trash}
                            alt="trash"
                            onClick={() => {
                              setShow(true);
                              setIndex(index);
                            }}
                            className="cursor-pointer ps-1"
                          />
                        )}
                  </div>
                </TooltipComponent>
              </td>
            );
          })}
        </tr>
      ))}
      <ModalComponent
        loading={destroyLoading}
        closeModal={() => setShow(false)}
        size="sm"
        showModal={show}
        title="Confirm Delete"
        closeButtonText="No, keep"
        submitButtonText="Yes, Delete"
        submitButtonType="danger"
        closeButtonType="light"
        handleSubmit={() => {
          handleRemove(remove, index, values?.variables?.[index].id);
          setShow(false);
        }}
      >
        Are you sure you want to delete this? This action can’t be undone
      </ModalComponent>
    </>
  );
};
