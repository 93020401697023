import { empty } from "@images";

export const NoDataFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center bg-light">
      <div className="py-5 text-center">
        <img src={empty} alt={empty} />
        <p className="mt-2 text-gray-500">No Data Found</p>
      </div>
    </div>
  );
};
