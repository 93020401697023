import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  benefitIcon,
  driverIcon,
  characteristicsIcon,
  segmentIcon,
  priceIcon,
  rightArrow,
} from "@images";
import { LeavePage } from "@components";

export const CalculationSteps = (props) => {
  const location = useLocation();
  const [page, setPage] = useState("");
  const params = useParams();
  const { bulkMutation, variables } = props;

  const handleButtonClick = (link) => {
    bulkMutation(link, variables);
  };

  useEffect(() => {
    stepperLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const startPath = `/product/${params?.id}`;

  const stepperLocation = () => {
    switch (location.pathname) {
      case `${startPath}/benefits`:
        setPage({
          benefit: "active",
          driver: "pending",
          characteristics: "pending",
          segments: "pending",
          weight: "pending",
          price: "pending",
        });
        break;
      case `${startPath}/benefitSuggestion`:
        setPage({
          benefit: "active",
          driver: "pending",
          characteristics: "pending",
          segments: "pending",
          weight: "pending",
          price: "pending",
        });
        break;
      case `${startPath}/value-drivers`:
        setPage({
          benefit: "completed",
          driver: "active",
          characteristics: "pending",
          segments: "pending",
          weight: "pending",
          price: "pending",
        });
        break;
      case `${startPath}/valueDriverSuggestion`:
        setPage({
          benefit: "completed",
          driver: "active",
          characteristics: "pending",
          segments: "pending",
          weight: "pending",
          price: "pending",
        });
        break;
      case `${startPath}/characteristics`:
        setPage({
          benefit: "completed",
          driver: "completed",
          characteristics: "active",
          segments: "pending",
          weight: "pending",
          price: "pending",
        });
        break;

      case `${startPath}/segments`:
        setPage({
          benefit: "completed",
          driver: "completed",
          characteristics: "completed",
          segments: "active",
          weight: "pending",
          price: "pending",
        });
        break;

      case `${startPath}/weight`:
        setPage({
          benefit: "completed",
          driver: "completed",
          characteristics: "completed",
          segments: "completed",
          weight: "active",
          price: "pending",
        });
        break;
      case `${startPath}/price`:
        setPage({
          benefit: "completed",
          driver: "completed",
          characteristics: "completed",
          segments: "completed",
          weight: "completed",
          price: "active",
        });
        break;

      default:
    }
  };

  const StepperPages = [
    {
      name: "Benefits",
      slug: "benefits",
      icon: benefitIcon,
      state: page.benefit,
    },
    {
      name: "Value Drivers",
      slug: "value-drivers",
      icon: driverIcon,
      state: page.driver,
    },
    {
      name: "Customer Characteristics",
      slug: "characteristics",
      icon: characteristicsIcon,
      state: page.characteristics,
    },
    {
      name: "Customer Segments",
      slug: "segments",
      icon: segmentIcon,
      state: page.segments,
    },
    {
      name: "Weight Allocation",
      slug: "weight",
      icon: segmentIcon,
      state: page.weight,
    },
    {
      name: "Value Modelling",
      slug: "price",
      icon: priceIcon,
      state: page.price,
    },
  ];

  return (
    <>
      <LeavePage />
      {StepperPages.map((step, index) => (
        <div key={index} className={`d-flex align-items-center`}>
          <div
            onClick={() => handleButtonClick(step.slug)}
            className={`py-3 simple text-center text-xxl-left cursor-pointer ${step.state}`}
          >
            <span className="status mx-auto me-xxl-2 mb-2 mb-xxl-0 d-block d-xxl-inline-block">
              <img src={step.icon} alt={step.icon} />
            </span>
            <span className="fs-14 step-text d-none d-lg-inline">
              {step.name}
            </span>
          </div>
          {index === StepperPages.length - 1 ? null : (
            <div>
              <img
                src={rightArrow}
                alt="rightArrow"
                className=" mx-3 ms-xxl-3 me-xxl-4"
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
};
