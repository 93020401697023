import { SharedTableHeadings } from "../SharedTableHeadings";
import { useOutletContext } from "react-router-dom";
import { useState } from "react";

export const BenefitsSuggestionTableHeadings = (props) => {
  const { currency } = props;

  const Headings = [
    {
      text: "Id",
    },
    {
      text: "Benefits",
      tooltip: "List all benefits your solution offers to your customers",
      width: "w-187-p",
    },
    {
      text: "Assumptions",
      tooltip:
        "Assumptions are verified or unverified suppositions you make about what exactly constitutes the value offered to your customers",
      width: "w-187-p",
    },
    {
      text: "Verified Assumption",
      tooltip:
        "Your customers indicate that your assumptions on the benefits you provide are valid? Toggle yes/no",
      width: "w-160-p",
    },
    {
      text: "Quantifiable",
      tooltip:
        "You are able to derive a monetary value from your benefit? Toggle yes/no",
      width: "w-120-p",
    },
    {
      text: "Tangible",
      tooltip:
        "The benefit is directly measurable, e.g. increase in cash flow (tangible). The benefit is not directly measurable, e.g. increase in customer satisfaction (intangible)",
      width: "w-100-p",
    },
    {
      text: <div className="currency-select">&nbsp;{currency}</div>,
      width: "w-120-p",
    },
    {
      text: "UoM",
      tooltip:
        "Unit of Measure, e.g. EUR or USD per month, or per year. Increase in NPS score,...",
    },
    {
      text: "Suggested By",
      tooltip: "Person who suggested the benefits",
      width: "w-120-p",
    },
    {
      text: "Actions",
      width: "w-120-p",
    },
  ];

  const HeadingsTeamMembers = [
    {
      text: "Id",
    },
    {
      text: "Benefits",
      tooltip: "List all benefits your solution offers to your customers",
      width: "w-227-p",
    },
    {
      text: "Assumptions",
      tooltip:
        "Assumptions are verified or unverified suppositions you make about what exactly constitutes the value offered to your customers",
      width: "w-227-p",
    },
    {
      text: "Verified Assumption",
      tooltip:
        "Your customers indicate that your assumptions on the benefits you provide are valid? Toggle yes/no",
      width: "w-160-p",
    },
    {
      text: "Quantifiable",
      tooltip:
        "You are able to derive a monetary value from your benefit? Toggle yes/no",
      width: "w-120-p",
    },
    {
      text: "Tangible",
      tooltip:
        "The benefit is directly measurable, e.g. increase in cash flow (tangible). The benefit is not directly measurable, e.g. increase in customer satisfaction (intangible)",
      width: "w-100-p",
    },
    {
      text: <div className="currency-select">&nbsp;{currency}</div>,
      width: "w-120-p",
    },
    {
      text: "UoM",
      tooltip:
        "Unit of Measure, e.g. EUR or USD per month, or per year. Increase in NPS score,...",
      width: "w-120-p",
    },
    {
      text: "Status",
      tooltip: "Current Status",
      width: "w-120-p",
    },
  ];

  const isTeamMember = useOutletContext()[1].isTeamMemberOfCurrentProduct;
  const [fieldsArray] = useState(isTeamMember ? HeadingsTeamMembers : Headings);

  return <SharedTableHeadings headings={fieldsArray} />;
};
