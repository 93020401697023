import { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useQuery } from "@apollo/client";
import { USER_PRODUCTS } from "@graphql/queries";
import { LoadingComponent, NullFound } from "@components";
import ProductsTable from "./ProductsTable";
import AddProductModal from "./AddProductModal";
import { SalesFunnelModal } from "@components/SalesFunnelModal";
import { useOutletContext } from "react-router-dom";

export default function Products() {
  const user = useOutletContext();
  const features = user?.subscriptionPlan.features;
  const [showModal, setshowModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });

  const { loading, error, data, refetch } = useQuery(USER_PRODUCTS, {
    variables: {
      keyword: keyword,
      after: currentCursor.after,
      before: currentCursor.before,
      first: 8,
    },
    fetchPolicy: "network-only",
  });

  const allProducts = data?.userProducts?.nodes;

  const handleSearch = async (event) => {
    const { value } = event.target;
    setKeyword(value);
    setCurrentCursor({ after: null, before: null });
    await refetch({ scenario: "search", keyword: value, first: 8 });
  };

  const handleFilter = async (event) => {
    const { value } = event.target;
    setCurrentCursor({ after: null, before: null });
    await refetch({ scenario: "filter", keyword: value, first: 8 });
  };

  const handleNextPage = () => {
    if (data?.userProducts?.pageInfo?.hasNextPage) {
      setCursorStack([...cursorStack, currentCursor]);
      setCurrentCursor({
        after: data?.userProducts?.pageInfo?.endCursor,
        before: null,
      });
    }
  };

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };

  return (
    <div className="container-xl py-5">
      <div className="row mb-4">
        <div className="col-sm-4 col-md-6">
          <h5 className="fw-bold">My Products</h5>
        </div>
        <div className="col-sm-8 col-md-6">
          <div className="d-flex align-items-center gap-2">
            <DebounceInput
              minLength={1}
              type="text"
              value={keyword}
              debounceTimeout={800}
              onChange={handleSearch}
              className="form-control"
              placeholder="Search by Name"
            />
            {features?.products?.limit < allProducts?.length ||
            features?.products?.limit === "unlimited" ? (
              <button
                className="btn btn-primary text-nowrap"
                onClick={() => {
                  setshowModal(true);
                }}
              >
                + Add Product
              </button>
            ) : (
              <SalesFunnelModal className="btn btn-primary text-nowrap">
                + Add Product
              </SalesFunnelModal>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center mb-4 gap-2">
        Sort by:
        <select
          defaultValue=""
          className="form-select w-auto"
          onChange={handleFilter}
        >
          <option disabled value="">
            Select option
          </option>
          <option value="no_of_segments">No. of Segments</option>
          <option value="Core">Core</option>
          <option value="Non Core">Non-Core</option>
          <option value="Hygienic">Hygienic</option>
          <option value="Functional">Functional</option>
          <option value="Idea-stage">Idea Stage</option>
          <option value="In development">In Development</option>
        </select>
      </div>
      {error && <div className="mb-2 text-danger">{error.message}</div>}
      {loading ? (
        <LoadingComponent height="272" />
      ) : allProducts?.length ? (
        <div>
          <ProductsTable data={allProducts} onRefetch={refetch} />
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-light btn-sm rounded-0"
              onClick={handlePreviousPage}
              disabled={!data?.userProducts?.pageInfo?.hasPreviousPage}
            >
              Previous
            </button>
            <button
              className="btn btn-light btn-sm rounded-0"
              onClick={handleNextPage}
              disabled={!data?.userProducts?.pageInfo?.hasNextPage}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <NullFound text="No Data Found" />
      )}

      <AddProductModal
        showModal={showModal}
        closeModal={() => {
          setshowModal(false);
        }}
        refetch={refetch}
      />
    </div>
  );
}
