import Modal from "react-bootstrap/Modal";
import { ButtonComponent } from "../Buttons";
import "./style.scss";

export const ModalComponent = (props) => {
  const {
    showModal,
    closeModal,
    title,
    handleSubmit,
    submitButtonText,
    closeButtonText,
    children,
    size,
    submitButtonType,
    closeButtonType,
    backdrop,
    keyboard,
    loading,
  } = props;

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      centered
      size={size}
      backdrop={backdrop || true}
      keyboard={keyboard || false}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h6">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {closeButtonText && (
          <ButtonComponent
            text={closeButtonText}
            disabled={loading}
            type={closeButtonType}
            onClick={closeModal}
          />
        )}
        {submitButtonText && (
          <ButtonComponent
            text={submitButtonText}
            disabled={loading}
            type={submitButtonType}
            onClick={handleSubmit}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
