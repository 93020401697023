import { useState } from "react";
import { useMutation } from "@apollo/client";
import { SEND_RESET_PASSWORD_INSTRUCTIONS } from "@graphql/mutations";
import { SweetAlert, HandleChange } from "@services";
import { SubmitButton, InputField } from "@components";
import { BelowAuthForm } from "./BelowAuthForm";

export const ForgotPassword = () => {
  const [variables, setVariables] = useState({ email: "" });
  const [sendResetPasswordInstructions, { error, loading }] = useMutation(
    SEND_RESET_PASSWORD_INSTRUCTIONS
  );

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendResetPasswordInstructions({
        variables,
      });
      SweetAlert(
        "You will receive an email with instructions on how to reset your password in a few minutes.",
        "success"
      );
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  return (
    <>
      <div className="text-center">
        <h5 className="text-gray-700 mb-1">Forgot Password?</h5>
        <p className="text-gray-400 mb-32">
          We will send you an email to reset your password.
        </p>
      </div>
      <form onSubmit={handleFormSubmit}>
        {error && <div className="mb-2 text-danger">{error.message}</div>}
        <div className="mb-40">
          <InputField
            onChange={(e) => HandleChange(e, variables, setVariables)}
            type="email"
            required={true}
            name="email"
            placeholder="Enter your email address"
            text="Email address"
          />
        </div>
        <SubmitButton
          loading={loading}
          width="100"
          size="lg"
          text="Send Email"
        />
      </form>
      <BelowAuthForm page="forgot-password" />
    </>
  );
};
