import { ButtonComponent } from "@components";
import { useParams } from "react-router-dom";

export const NoFound = (props) => {
  const params = useParams();
  const { text, link } = props;

  return (
    <div className="d-flex justify-content-center pt-5 px-2">
      <div className="card mw-730 w-100 bg-gray-50">
        <div className="card-body text-center py-64 px-5">
          <h4 className="mb-2">Add {text} First</h4>
          <p className="mb-36">
            This page will only be available when you have {text.toLowerCase()}{" "}
            added.
          </p>
          <ButtonComponent
            link={`/product/${params?.id}/${link}`}
            type="primary"
            size="lg"
            text={`Add ${text}`}
          />
        </div>
      </div>
    </div>
  );
};
