import { useDispatch } from "react-redux";
import { collapse } from "@store/sidebarSlice";
import { logo } from "@images";
import { setShow } from "@store/canvasSlice";
import { Link } from "react-router-dom";

export const AdminHeader = () => {
  const dispatch = useDispatch();
  return (
    <div className="border-bottom w-100 py-2 bg-white position-fixed top-header">
      <div className="container-fluid">
        <div className="d-flex align-items-center">
          <i
            className="fa-solid fa-bars cursor-pointer me-3 d-block d-lg-none"
            onClick={() => dispatch(setShow())}
          ></i>
          <i
            className="fa-solid fa-bars cursor-pointer me-3 d-none d-lg-block"
            onClick={(e) => dispatch(collapse())}
          ></i>
          <Link to="/">
            <img src={logo} alt="Logo" width={182} />
          </Link>
        </div>
      </div>
    </div>
  );
};
