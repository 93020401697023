import { gql } from "@apollo/client";

// export const userAttributes = `
//   defaultProductCurrency
//   email
//   fullName
//   id
//   imageUrl
//   invitationAccepted
//   invitationSentAt
//   invitationStatus
//   memberOfProductId
//   memberOfProduct{
//     id
//     slug
//     name
//   }
//   ownProduct{
//     id
//     slug
//     name
//   }
//   currentProductId
//   productId
//   provider
//   revokeAccess
//   isTeamMemberOfCurrentProduct
//   role
//   subscription
//   revokeProductAccess
//   subscriptionInterval
//   subscriptionPlan{
//     title
//     features
//   }`;

export const userAttributes = `
  defaultProductCurrency
  email
  fullName
  id
  imageUrl
  invitationAccepted
  invitationSentAt
  invitationStatus
  memberOfProducts{
    id
    slug
    name
  }
  currentProductId
  productId
  provider
  revokeAccess
  totalInvitees
  isTeamMemberOfCurrentProduct
  role
  subscription
  revokeProductAccess
  subscriptionInterval
  subscriptionPlan{
    title
    features
  }`;

export const INVITE_USERS = gql`
  mutation CreateInvitation($email: String!) {
    createInvitation(input: { email: $email }) {
      ${userAttributes}
    }
  }
`;

export const UPDATE_BENEFITS_STATUS = gql`
  mutation UpdateBenefitStatus(
    $productId: ID!
    $benefitId: ID!
    $status: StatusEnum!
  ) {
    updateBenefitStatus(
      input: { productId: $productId, benefitId: $benefitId, status: $status }
    ) {
      description
      id
      name
      quantifiable
      status
      tangible
      unitOfMeasure
      value
      verified
    }
  }
`;

export const UPDATE_VALUE_DRIVER_STATUS = gql`
  mutation updateValueDriverStatus(
    $productId: ID!
    $valueDriverId: ID!
    $status: StatusEnum!
  ) {
    updateValueDriverStatus(
      input: {
        productId: $productId
        valueDriverId: $valueDriverId
        status: $status
      }
    ) {
      id
    }
  }
`;

export const REVOKE_ACCESS = gql`
  mutation RevokeAccess($userId: ID!, $revokeAccess: Boolean!) {
    revokeTeamMember(
      input: {
        accessAttributes: { userId: $userId, revokeAccess: $revokeAccess }
      }
    ) {
      revokeAccess
    }
  }
`;

//delete

export const DELETE_TEAM_MEMBER = gql`
  mutation RemoveMember(
    $allowToRemove: Boolean!
    $authenticityResource: String!
  ) {
    removeMember(
      input: {
        allowToRemove: $allowToRemove
        authenticityResource: $authenticityResource
      }
    ) {
      ${userAttributes}
    }
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation($invitationToken: String!) {
    acceptInvitation(input: { invitationToken: $invitationToken }) {
      token
      resource {
        ${userAttributes}
      }
    }
  }
`;

//resend
export const RESEND_INVITATION = gql`
  mutation ResendInvitation($id: ID!) {
    resendInvitation(input: { id: $id }) {
      email
      fullName
      id
    }
  }
`;

const userObject = `user{
    ${userAttributes}
  }`;

const loginUserAttrinutes = `token
  ${userObject}`;

export const LOGIN = gql`
  mutation loginUser($email: String!, $password: String!, $rememberMe: Boolean!) {
    loginUser(input: {loginAttributes: {email: $email, password: $password, rememberMe: $rememberMe}}) {
      ${loginUserAttrinutes}
    }
  }
`;

export const REGISTER = gql`
  mutation registerUser($fullName: String!, $email: String!, $password: String!, $passwordConfirmation: String!, $rememberMe: Boolean!) {
    registerUser(input: {registerAttributes: {fullName: $fullName, email: $email, password: $password, passwordConfirmation: $passwordConfirmation, rememberMe: $rememberMe}}) {
      ${loginUserAttrinutes}
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $fullName: String!, $email: String!, $image: Upload) {
    updateUser(input: {profileAttributes: {id: $id, fullName: $fullName, email: $email, image: $image}}) {
      ${userAttributes}
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation updateUser(
    $id: ID!
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updateUser(
      input: {
        profileAttributes: {
          id: $id
          email: $email
          password: $password
          passwordConfirmation: $passwordConfirmation
        }
      }
    ) {
      id
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword($resetPassword: Boolean!, $fullName: String, $password: String!, $passwordConfirmation: String!, $token: String!) {
    setPassword(input: {setPasswordAttributes: {resetPassword: $resetPassword, fullName: $fullName, password: $password, passwordConfirmation: $passwordConfirmation, token: $token}}) {
      token
      resource {
        ${userAttributes}
      }
    }
  }
`;

export const SEND_RESET_PASSWORD_INSTRUCTIONS = gql`
  mutation sendResetPasswordInstructions($email: String!) {
    sendResetPasswordInstructions(input: { email: $email }) {
      id
    }
  }
`;

export const MODIFY_ACCESS_USER = gql`
  mutation modifyAccessUser($userId: ID!, $revokeAccess: Boolean!) {
    modifyAccessUser(
      input: {
        accessAttributes: { userId: $userId, revokeAccess: $revokeAccess }
      }
    ) {
      id
      revokeAccess
    }
  }
`;

export const CREATE_CONTACTOR = gql`
  mutation CreateContactor(
    $email: String!
    $fullName: String!
    $enquireAbout: String!
    $message: String!
  ) {
    createContactor(
      input: {
        contactorsAttributes: {
          email: $email
          fullName: $fullName
          enquireAbout: $enquireAbout
          message: $message
        }
      }
    ) {
      contactor {
        id
      }
    }
  }
`;

export const ONBOARDING_USER_RESPONSE = gql`
  mutation onboardingUserResponse($userId: ID!) {
    onboardingUserResponse(input: { userId: $userId }) {
      userResponses {
        id
      }
    }
  }
`;

export const ADD_UPDATE_ONBOARDING_USER_RESPONSE = gql`
  mutation addUpdateOnboardingUserResponse(
    $responseQ1: String
    $responseQ2: String
    $responseQ3: String
    $responseQ4: String
    $responseQ5: String
    $responseQ6: String
    $responseQ7: String
    $responseQ8: String
    $responseQ9: String
    $responseQ10: String
    $responseQ11: String
    $responseQ12: String
    $responseQ13: String
    $responseQ14: String
  ) {
    addUpdateOnboardingUserResponse(
      input: {
        userResponseAttributes: {
          responseQ1: $responseQ1
          responseQ2: $responseQ2
          responseQ3: $responseQ3
          responseQ4: $responseQ4
          responseQ5: $responseQ5
          responseQ6: $responseQ6
          responseQ7: $responseQ7
          responseQ8: $responseQ8
          responseQ9: $responseQ9
          responseQ10: $responseQ10
          responseQ11: $responseQ11
          responseQ12: $responseQ12
          responseQ13: $responseQ13
          responseQ14: $responseQ14
        }
      }
    ) {
      userResponses {
        id
      }
    }
  }
`;

export const CREATE_BENEFIT = gql`
  mutation createBenefit($productId: ID!) {
    createBenefit(input: { benefitAttributes: { productId: $productId } }) {
      id
    }
  }
`;

export const UPDATE_BULK_BENEFITS = gql`
  mutation updateBulkBenefits($input: UpdateBulkBenefitsInput!) {
    updateBulkBenefits(input: $input) {
      id
    }
  }
`;

export const DESTROY_BENEFIT = gql`
  mutation destroyBenefit($benefitId: ID!) {
    destroyBenefit(input: { benefitId: $benefitId }) {
      id
    }
  }
`;

export const CREATE_VALUE_DRIVER = gql`
  mutation createValueDriver($productId: ID!) {
    createValueDriver(
      input: { valueDriverAttributes: { productId: $productId } }
    ) {
      valueDriver {
        id
      }
    }
  }
`;

export const UPDATE_VALUE_DRIVER = gql`
  mutation updateValueDriver(
    $name: String
    $description: String
    $type: String
    $featureStatus: String
    $category: String
    $unitOfMeasure: String
    $id: ID!
    $benefitId: ID!
  ) {
    updateValueDriver(
      input: {
        valueDriverAttributes: {
          name: $name
          description: $description
          type: $type
          featureStatus: $featureStatus
          category: $category
          unitOfMeasure: $unitOfMeasure
          id: $id
          benefitId: $benefitId
        }
      }
    ) {
      valueDriver {
        id
      }
    }
  }
`;

export const DESTROY_VALUE_DRIVER = gql`
  mutation destroyValueDriver($valueDriverId: ID!) {
    destroyValueDriver(input: { valueDriverId: $valueDriverId }) {
      id
    }
  }
`;

export const BULK_ACTION_ON_DIMENSIONS = gql`
  mutation bulkActionOnDimensions($input: BulkActionInput!) {
    bulkActionOnDimensions(input: $input) {
      id
    }
  }
`;

export const CREATE_CHARACTERISTIC = gql`
  mutation createCharacteristic($productId: ID!) {
    createCharacteristic(
      input: { characteristicAttributes: { productId: $productId } }
    ) {
      id
    }
  }
`;

export const BULK_ACTION_ON_CHARACTERISTICS = gql`
  mutation bulkActionsOnCharacteristics($input: BulkActionsInput!) {
    bulkActionsOnCharacteristics(input: $input) {
      id
    }
  }
`;

export const DESTROY_CHARACTERISTIC = gql`
  mutation destroyCharacteristic($characteristicId: ID!) {
    destroyCharacteristic(input: { characteristicId: $characteristicId }) {
      id
    }
  }
`;

export const BULK_UPDATE_SEGMENTS = gql`
  mutation bulkUpdateSegments($input: BulkUpdateInput!) {
    bulkUpdateSegments(input: $input) {
      id
    }
  }
`;

export const ADD_UPDATE_VALUE_IN_SEGMENT = gql`
  mutation addUpdateValueInSegment(
    $segmentId: ID!
    $customerCharacteristicId: ID!
    $customerValueId: ID!
    $position: Int!
  ) {
    addUpdateValueInSegment(
      input: {
        valueAttributes: {
          segmentId: $segmentId
          customerCharacteristicId: $customerCharacteristicId
          customerValueId: $customerValueId
          position: $position
        }
      }
    ) {
      id
    }
  }
`;

export const BULK_ACTIONS_WEIGHT_ALLOCATIONS = gql`
  mutation bulkActionsWeightAllocation($input: BulkAddUpdateInput!) {
    bulkActionsWeightAllocation(input: $input) {
      id
    }
  }
`;

export const ADD_UPDATE_PRICE_MODEL = gql`
  mutation addUpdatePriceModel($input: AddUpdatePriceModelInput!) {
    addUpdatePriceModel(input: $input) {
      id
    }
  }
`;

export const ADD_UPDATE_PRICE_MODEL_VALUE = gql`
  mutation addUpdatePriceModelValue($input: AddUpdateValueInput!) {
    addUpdatePriceModelValue(input: $input) {
      dimension {
        id
      }
    }
  }
`;

export const IMPERSONATE_USER = gql`
  mutation impersonateUser($userId: ID!) {
    impersonateUser(input: { userId: $userId }) {
      impersonatedToken
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: ID!, $currency: String) {
    updateProduct(input: {productArguments: {id: $id, currency: $currency}}) {
      ${userObject}
    }
  }
`;

export const SIGN_IN_WITH_GOOGLE = gql`
  mutation signInWithGoogle($accessToken: String!) {
    signInWithGoogle(input: {accessToken: $accessToken}) {
      ${loginUserAttrinutes}
    }
  }
`;

export const CREATE_SEGMENT = gql`
  mutation createSegment($productId: ID!) {
    createSegment(input: { segmentAttributes: { productId: $productId } }) {
      id
    }
  }
`;

export const DESTROY_SEGMENT = gql`
  mutation destroySegment($segmentId: ID!) {
    destroySegment(input: { segmentId: $segmentId }) {
      id
    }
  }
`;

export const IMPORT_VALUE_DRIVERS = gql`
  mutation importValueDrivers($file: Upload!, $productId: ID!) {
    importValueDrivers(input: { productId: $productId, file: $file }) {
      errors {
        message
      }
      success
    }
  }
`;

export const IMPORT_BENEFITS = gql`
  mutation importBenefits($file: Upload!, $productId: ID!) {
    importBenefits(input: { productId: $productId, file: $file }) {
      errors {
        message
      }
      success
    }
  }
`;

export const SWITCH_PRODUCT = gql`
  mutation switchProduct($productId: ID!) {
    switchProduct(input: { productId: $productId }) {
      ${userAttributes}
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($name: String, $currency: String) {
    createProduct(
      input: { productArguments: { name: $name, currency: $currency } }
    ) {
      id
    }
  }
`;
export const DESTROY_PRODUCT = gql`
  mutation destroyProduct($id: ID!) {
    destroyProduct(input: { id: $id }) {
      id
    }
  }
`;
export const NEW_UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: ID!, $name: String, $currency: String) {
    updateProduct(
      input: { productArguments: { id: $id, name: $name, currency: $currency } }
    ) {
      id
    }
  }
`;

export const BULK_ARCHIVE_MEMBERS = gql`
  mutation bulkArchiveMembers($id: [ID!]!) {
    bulkArchiveMembers(input: { id: $id }) {
      id
    }
  }
`;
