import { useQuery } from "@apollo/client";
import {
  GET_CHECKOUT_URL,
  PRODUCT_DASHBOARD_ANALYTICS,
} from "@graphql/queries";
import { SweetAlert } from "@services";
import { useSearchParams, useOutletContext, Link } from "react-router-dom";
import {
  StripeLoading,
  LoadingComponent,
  ErrorComponent,
  ButtonComponent,
} from "@components";
import Alert from "react-bootstrap/Alert";
import ProgressBar from "react-bootstrap/ProgressBar";
import "../style.scss";
import ListGroup from "react-bootstrap/ListGroup";
import { checkList, uncheck, empty, star } from "@images";
import { Cardsdash } from "./Cardsdash";

export const Dashboard = () => {
  const user = useOutletContext();
  const currency = user?.defaultProductCurrency?.symbol;
  const searchParams = useSearchParams();
  const plan = searchParams[0].get("plan");
  const { loading } = useQuery(GET_CHECKOUT_URL, {
    skip: !plan,
    variables: { subscriptionPlan: plan },
    onCompleted: (data) => {
      window.location.assign(data?.getCheckoutUrl?.url);
    },
    onError: (e) => {
      SweetAlert(e, "error");
    },
  });

  const productId = user?.currentProductId;
  const {
    loading: dashboardLoading,
    error,
    data,
  } = useQuery(PRODUCT_DASHBOARD_ANALYTICS, {
    variables: { productId },
    fetchPolicy: "network-only",
  });

  if (dashboardLoading) return <LoadingComponent height="100" />;

  if (error)
    return (
      <ErrorComponent
        heading={error.message}
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const benefits = data?.productDashboardAnalytics?.benefits;
  const customerCharacteristics =
    data?.productDashboardAnalytics.characteristics;
  const valueDrivers = data?.productDashboardAnalytics?.valueDrivers;
  const segmentPricing = data?.productDashboardAnalytics?.segmentsPricing;
  const customerSegments = data?.productDashboardAnalytics?.customerSegments;
  const myTodos = data?.productDashboardAnalytics.myTodos;

  const dimensionValue = +(
    valueDrivers?.totalDimensions / valueDrivers?.count
  ).toFixed(2);
  const averageBenefitValue = +(benefits?.totalValue / benefits?.count).toFixed(
    2
  );

  const myTodoName = [
    {
      tick: myTodos?.addBenefits,
      name: "Add benefits",
      link: `/product/${productId}/benefits`,
    },
    {
      tick: myTodos?.addValueDriversAndDimensions,
      name: "Add value drivers & dimensions",
      link:
        benefits?.count > 0 ? `/product/${productId}/value-drivers` : undefined,
    },
    {
      tick: customerCharacteristics?.length > 0,
      name: "Add customer characteristics",
      link:
        valueDrivers?.totalDimensions > 0
          ? `/product/${productId}/characteristics`
          : undefined,
    },
    {
      tick: myTodos?.addCustomerSegments,
      name: "Connect segments with customer characteristics",
      link:
        customerCharacteristics?.length > 0
          ? `/product/${productId}/segments`
          : undefined,
    },
    {
      tick: myTodos?.calculatePriceModel,
      name: "Calculate your price models",
      link:
        valueDrivers?.totalDimensions > 0 && customerCharacteristics?.length > 0
          ? `/product/${productId}/price`
          : undefined,
    },
  ];

  const trueCount = myTodoName.filter((todo) => todo.tick).length;
  const progressFormula = (trueCount / myTodoName.length) * 100;

  return plan ? (
    <StripeLoading loading={loading} />
  ) : (
    <div className="container-xxl py-5">
      <h5 className="mb-4">Your Dashboard</h5>
      {user?.subscription === false && (
        <Alert variant="primary bg-primary-50 mb-4">
          <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
            <div className="d-flex align-items-center me-3">
              <img src={star} className="me-2" alt="star" />
              <div>
                <p className="fw-800 text-primary">Unlock All Features</p>
                <p className="fs-14 text-gray-500">
                  Unleash the power of{" "}
                  <Link to="/" className="text-reset">
                    GetPricingPower.com
                  </Link>{" "}
                  and get access to UNLIMITED features.
                </p>
              </div>
            </div>
            <ButtonComponent
              link="/pricing"
              type="primary"
              text="Upgrade Now"
            />
          </div>
        </Alert>
      )}
      <div className="row gx-3">
        <div className="col-md-6 col-lg-6 col-xl-4 mb-4">
          <div className="card border p-3 h-100 d-flex justify-content-between flex-column">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6>Value Drivers</h6>
              {user?.subscription === true && (
                <Link to={`/product/${productId}/value-drivers`}>
                  <i className="fa-solid fa-arrow-right ms-3 fs-20"></i>
                </Link>
              )}
            </div>
            <div className="row gx-3">
              <div className="col-12 col-sm-6">
                <div className="card p-2 bg-primary-50 mb-3">
                  <p className="mb-2 text-gray-500">Value drivers</p>
                  <h6>{valueDrivers?.count || "0"}</h6>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="card p-2 bg-primary-50 mb-3">
                  <p className="mb-2 text-gray-500">Dimensions</p>
                  <h6>{valueDrivers?.totalDimensions || "0"}</h6>
                </div>
              </div>
            </div>
            <p className="mb-3">
              {isNaN(dimensionValue) ? "0" : dimensionValue} dimensions/value
              driver on average
            </p>
            <div className="row gx-1 gx-sm-20">
              <div className="col-4">
                <div className="dashboard-badge success">
                  {valueDrivers?.coreCount || "0"} Core
                </div>
              </div>
              <div className="col-4">
                <div className="dashboard-badge primary">
                  {valueDrivers?.nonCoreCount || "0"} Non Core
                </div>
              </div>
              <div className="col-4">
                <div className="dashboard-badge gray">
                  {valueDrivers?.hygienicCount || "0"} Hygienic
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 mb-4">
          <div className="card border p-3 h-100 d-flex justify-content-between flex-column">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6>Benefits</h6>
              {user?.subscription === true && (
                <Link to={`/product/${productId}/benefits`}>
                  <i className="fa-solid fa-arrow-right ms-3 fs-20"></i>
                </Link>
              )}
            </div>
            <div className="row gx-1 gx-sm-20">
              <div className="col-12 col-sm-6">
                <div className="card p-2 bg-primary-50 mb-3">
                  <p className="mb-2 text-gray-500">No. of Benefits</p>
                  <h6>{benefits?.count || "0"}</h6>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="card p-2 bg-primary-50 mb-3">
                  <p className="mb-2 text-gray-500">Total Value</p>
                  <h6>
                    {currency}
                    {+(benefits?.totalValue).toFixed(2) || "0"}
                  </h6>
                </div>
              </div>
            </div>
            <p className="mb-3">
              {currency}
              {isNaN(averageBenefitValue) ? "0" : averageBenefitValue} average
              value per benefit
            </p>
            <div className="row gx-3">
              <div className="col-6">
                <div className="dashboard-badge success">
                  {benefits?.verifiedCount || "0"} Verified
                </div>
              </div>
              <div className="col-6">
                <div className="dashboard-badge danger">
                  {benefits?.unvarifiedCount || "0"} Unverified
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 mb-4">
          <div className="card border p-3 h-100 d-flex justify-content-between flex-column">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6>Customer Segments</h6>
              {user?.subscription === true && (
                <Link to={`/product/${productId}/segments`}>
                  <i className="fa-solid fa-arrow-right ms-3 fs-20"></i>
                </Link>
              )}
            </div>
            <div className="row gx-3">
              <div className="col-12 col-sm-6">
                <div className="card p-2 bg-primary-50 mb-3">
                  <p className="mb-2 text-gray-500">No. of Segments</p>
                  <h6>
                    {myTodos?.addCustomerSegments
                      ? customerSegments?.count
                      : "0"}
                  </h6>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="card p-2 bg-primary-50 mb-3">
                  <p className="mb-2 text-gray-500">Potential Reach</p>
                  <h6>
                    {customerSegments?.potentialReach || "0"} % of the market
                  </h6>
                </div>
              </div>
            </div>
            <p className="mb-3">Potential reach</p>
            <div className="row">
              <div className="col-6">
                <div className="dashboard-badge success">
                  {customerSegments?.strategicCount || "0"} Strategic
                </div>
              </div>
              <div className="col-6">
                <div className="dashboard-badge danger">
                  {myTodos?.addCustomerSegments
                    ? customerSegments?.nonStrategicCount
                    : "0"}{" "}
                  Non-strategic
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card border p-3 h-100 d-flex flex-column">
            <h6 className="mb-3">My To-dos</h6>
            <ProgressBar
              variant="primary"
              now={progressFormula}
              label={`${progressFormula}%`}
              className="todo-progress fs-12 fw-800"
            />
            <div className="d-flex justify-content-between mt-1">
              <p className="fs-12 text-gray-600">0%</p>
              <p className="fs-12 text-gray-600">100%</p>
            </div>
            <ListGroup variant="flush" className="pt-3 border-0">
              {myTodoName.map((todo, index) => (
                <ListGroup.Item
                  className="px-0 border-0 pb-2 pt-0 d-flex align-items-center"
                  key={index}
                >
                  <Link
                    to={todo.link}
                    className={`${
                      user?.subscription === false || !todo.link
                        ? "text-gray-500 pe-none"
                        : "text-primary"
                    }`}
                  >
                    <img
                      src={todo.tick ? checkList : uncheck}
                      alt={checkList}
                      className="me-3"
                    />
                    {todo.name}
                  </Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </div>
        <div className="col-lg-12 col-xl-6 mb-4">
          <div className="card border p-3 h-100 d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6>Value per segment</h6>
              {user?.subscription === true && (
                <Link to={`/product/${productId}/price`}>
                  <i className="fa-solid fa-arrow-right ms-3 fs-20"></i>
                </Link>
              )}
            </div>
            <div className="row">
              {segmentPricing?.length ? (
                segmentPricing?.map((segmentPrice, index) => (
                  <div className="col-12 col-sm-6" key={index}>
                    <div className="card px-12 py-18 bg-primary-50 mb-3">
                      <p className="mb-2 text-gray-500">
                        {segmentPrice.segmentName || "Segment " + (index + 1)}
                      </p>
                      <h6>
                        {currency}
                        {parseInt(segmentPrice.psychologicalPricing) || "0"}
                        /month
                      </h6>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <div className="py-5 text-center">
                    <img src={empty} alt={empty} />
                    <p className="mt-2 text-gray-500">No Data Found</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Cardsdash
          currency={currency}
          title="Benefits"
          mapping={benefits?.allBenefits}
        />
        <Cardsdash
          currency={currency}
          title="Value Drivers"
          mapping={valueDrivers?.allValueDrivers}
        />
        <Cardsdash
          currency={currency}
          title="Customer Characteristics"
          mapping={customerCharacteristics}
        />
        <Cardsdash
          currency={currency}
          myTodos={myTodos}
          title="Value per segment"
          mapping={segmentPricing}
        />
      </div>
    </div>
  );
};
