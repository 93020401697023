import "../style.scss";
import React, { useEffect, useState } from "react";
import { Formik, FieldArray, Field } from "formik";
import * as Yup from "yup";
import {
  LoadingComponent,
  TooltipComponent,
  InputField,
  InfoTooltip,
  ButtonComponent,
} from "@components";
import { spinner } from "@images";
import {
  useNavigate,
  useParams,
  useOutletContext,
  Link,
} from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  ALL_STEPS_COUNTER,
  EXPORT_VALUE_DRIVERS,
  PRODUCT_BENEFITS,
  PRODUCT_VALUE_DRIVERS,
} from "@graphql/queries";
import { ValueDriverModal } from "./ValueDriverModal";
import { SweetAlert } from "@services";
import {
  BULK_ACTION_ON_DIMENSIONS,
  CREATE_VALUE_DRIVER,
  IMPORT_VALUE_DRIVERS,
  UPDATE_VALUE_DRIVER_STATUS,
} from "@graphql/mutations";
import { CalculationSteps } from "../CalculationSteps";
import { NoFound } from "../NoFound";
import { ImportModal } from "@components/ImportModal";
import ExampleCSV from "../value-drivers-template.csv";
import { exportcsv } from "src/utils/exportcsv";
import humanizeString from "humanize-string";
import { errorIcon, successIcon } from "@images/index";
import { Form } from "react-bootstrap";
import { Badges } from "@components/Badges";
import { SalesFunnelModal } from "@components/SalesFunnelModal";

export const ValueDriversSuggestion = () => {
  const user = useOutletContext()[1];
  const plan = user.subscriptionPlan.title;
  const benefitsCount = useOutletContext()[0].benefitsCount;
  const valueDriversCount = useOutletContext()[0].valueDriversCount;
  const isTeamMember = user.isTeamMemberOfCurrentProduct;
  const params = useParams();
  const navigate = useNavigate();

  const [
    updateValueDriverStatus,
    {
      loading: updateValueDriverStatusLoading,
      error: updateValueDriverStatusError,
    },
  ] = useMutation(UPDATE_VALUE_DRIVER_STATUS, {});

  const [benefitIds, setBenefitIds] = useState();
  const [addValueDriver, setAddValueDriver] = useState();
  const [allValues, setAllValues] = useState();
  const { loading } = useQuery(PRODUCT_BENEFITS, {
    skip: benefitsCount === 0,
    variables: { productId: params?.id },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setBenefitIds(data?.productBenefits);
    },
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
  });

  const [exportValueDrivers, { loading: eloading }] = useLazyQuery(
    EXPORT_VALUE_DRIVERS,
    {
      variables: { productId: params?.id },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        exportcsv(data.exportValueDrivers.exportCsvData);
      },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
    }
  );

  const [createValueDriver, { loading: cloading }] = useMutation(
    CREATE_VALUE_DRIVER,
    {
      variables: { productId: params?.id },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
    }
  );

  const [showValueDriverModal, setShowValueDriverModal] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [highestValue, setHighestValue] = useState();
  const [dimval, setDimVal] = useState([1, 2, 3, 4]);
  const [currentStatusArray, setCurrentStatusArray] = useState([]);
  const [modalState, setModalState] = useState();

  const [modalValue, setModalValue] = useState();

  const [bulkActionOnDimensions, { loading: bloading, error: berror }] =
    useMutation(BULK_ACTION_ON_DIMENSIONS, {
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    });

  const handleUpdateValueDriverStatusApprove = async (
    productId,
    valueDriverId,
    remove,
    index
  ) => {
    try {
      await updateValueDriverStatus({
        variables: {
          productId,
          valueDriverId,
          status: "APPROVED",
        },
      });
      const removeValueDriver = () => remove(index);
      removeValueDriver();
      SweetAlert("ValueDriver has been approved", "success");
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateValueDriverStatusReject = async (
    productId,
    valueDriverId,
    remove,
    index
  ) => {
    try {
      await updateValueDriverStatus({
        variables: {
          productId,
          valueDriverId,
          status: "DECLINED",
        },
      });
      const removeValueDriver = () => remove(index);
      removeValueDriver();
      SweetAlert("ValueDriver has been declined", "success");
    } catch (error) {
      console.log(error);
    }
  };

  const onCompletedQuery = (data) => {
    const allValueDrivers = data?.productValueDrivers;
    const highestValue = allValueDrivers?.highestValue;
    setHighestValue(highestValue);
    const valueDrivers = allValueDrivers?.valueDrivers;
    const variables = [];
    for (let i = 0; i < valueDrivers.length; i++) {
      const valueDriver = valueDrivers[i];
      const variable = {
        valueDriver: { ...valueDriver, benefitId: valueDriver?.benefit?.id },
      };

      for (let i = 0; i < 8; i++) {
        const dimensionTitleKey = `dimension_${i + 1}_Title`;
        const dimensionValueKey = `dimension_${i + 1}_Value`;
        const dimension = valueDriver?.restrictDimensions[i];

        variable[dimensionTitleKey] = dimension?.value;
        variable[dimensionValueKey] = dimension?.numericalValue
          ? dimension?.numericalValue
          : "";
      }

      for (let h = 5; h <= 8; h++) {
        if (highestValue === h) {
          for (let v = 4; v < h; v++) {
            variable[`dimension_${v + 1}_Title`] = valueDriver
              .restrictDimensions[v]
              ? valueDriver.restrictDimensions[v].value
              : "";
            variable[`dimension_${v + 1}_Value`] = valueDriver
              .restrictDimensions[v]
              ? valueDriver.restrictDimensions[v].numericalValue || ""
              : "";
          }
        }
      }
      variables.push(variable);
      setInitialValues({
        variables,
      });
    }
  };

  const { loading: vloading, data: valueDriverData } = useQuery(
    PRODUCT_VALUE_DRIVERS,
    {
      skip: benefitsCount === 0,
      variables: { productId: params?.id, isMainView: false },
      fetchPolicy: "network-only",
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
      onCompleted: (data) => {
        const valueDrivers = data?.productValueDrivers.valueDrivers;

        const variables2 = [];

        for (let i = 0; i < valueDrivers.length; i++) {
          const valueDriverItem = valueDrivers[i];

          const variable2 = {
            status: valueDriverItem?.status,
          };

          variables2.push(variable2);
        }
        setCurrentStatusArray(variables2);
        onCompletedQuery(data); //here
      },
    }
  );
  const [productValueDrivers] = useLazyQuery(PRODUCT_VALUE_DRIVERS, {
    skip: benefitsCount === 0,
    variables: { productId: params?.id },
    fetchPolicy: "network-only",
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
    onCompleted: (data) => {
      onCompletedQuery(data);
    },
  });
  const data = {
    name: "Dimension",
  };
  const [tabHeader, settabHeader] = useState([]);

  useEffect(() => {
    for (let i = dimval.length; i < highestValue; i++) {
      dimval.push(i + 1);
    }
    for (let i = 0; i < 8; i++) {
      tabHeader.push(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highestValue]);

  const thTabRemove = (index) => {
    const dataRow = [...tabHeader];
    dataRow.splice(index, 1);
    settabHeader(dataRow);
  };

  const errorsShow = () => {
    const els = document.querySelectorAll(".text-danger");
    for (let x = 0; x < els.length; x++) els[x].style.display = "block";
    const list = document.querySelectorAll(".border-red");
    for (let l = 0; l < list.length; l++)
      list[l].classList.add("border-danger");
  };

  const inputFieldSchema = Yup.object().shape({
    valueDriver: Yup.object(),
    dimension_1_Title:
      isTeamMember && Yup.string().required("Dimension 1 is required"),
    dimension_1_Value: Yup.number(),
    dimension_2_Title: Yup.string(),
    dimension_2_Value: Yup.number(),
    dimension_3_Title: Yup.string(),
    dimension_3_Value: Yup.number(),
    dimension_4_Title: Yup.string(),
    dimension_4_Value: Yup.number(),
    dimension_5_Title: Yup.string(),
    dimension_5_Value: Yup.number(),
    dimension_6_Title: Yup.string(),
    dimension_6_Value: Yup.number(),
    dimension_7_Title: Yup.string(),
    dimension_7_Value: Yup.number(),
    dimension_8_Title: Yup.string(),
    dimension_8_Value: Yup.number(),
  });

  const bulkMutation = async (link, values) => {
    const navigation = () => {
      if (link === "/dashboard?plan=true") {
        navigate("/dashboard?plan=true");
      } else {
        navigate(`/product/${params?.id}/${link}`);
      }
    };
    if (values?.variables === undefined || values?.variables?.length === 0) {
      navigation();
    } else {
      const variables = [];
      // eslint-disable-next-line array-callback-return
      values?.variables?.map((item) => {
        for (let v = 1; v <= 8; v++) {
          variables.push({
            position: v,
            value: item?.[`dimension_${v}_Title`] || "",
            numericalValue: item?.[`dimension_${v}_Value`] || 0,
            valueDriverId: item?.valueDriver?.id,
          });
        }
      });
      try {
        await bulkActionOnDimensions({
          variables: { input: { dimensionAttributes: variables } },
        });
        navigation();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleButtons = (values, errors, e, link) => {
    if (e?.target?.id === "next" && errors?.variables) {
      errorsShow();
    } else {
      bulkMutation(link, values);
    }
  };

  const [importErrors, setImportErrors] = useState();
  const [importValueDrivers, { loading: iloading, error: ierror }] =
    useMutation(IMPORT_VALUE_DRIVERS, {
      variables: { productId: params?.id },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
        productValueDrivers();
      },
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    });

  const importFile = async (file) => {
    try {
      const { data } = await importValueDrivers({
        variables: { file },
      });
      if (data?.importValueDrivers?.success === true) {
        setImportErrors(false);
        SweetAlert("Value Drivers imported successfully.", "success");
        productValueDrivers();
      } else {
        setImportErrors(data?.importValueDrivers?.errors);
        productValueDrivers();
      }
    } catch (e) {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
    }
  };

  if (loading || vloading) return <LoadingComponent height="calculation" />;

  const instructions = [
    <div>
      <Link
        to={ExampleCSV}
        download="ValueDrivers-Template.csv"
        rel="noreferrer"
        target="_blank"
        className="text-decoration-underline"
      >
        Download
      </Link>{" "}
      the template
    </div>,
    "Don't change or add more header names.",
    "Value Driver name must be unique and it is mandatory field.",
    "Each Value Driver has a Benefit and it will link with any existing Benefit with their name (so write correct Benefit name).",
    "Adding a Dimension is optional, and numerical values are not mandatory. Do not allow to enter only a numerical value without a Dimension.",
    "If you're adding Dimensions, follow the sequence: 1, 2, 3, ...",
    "If you're using an existing Value Driver and want to add Dimensions, follow the rules as above.",
    "Don't refresh the page while importing the data.",
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          variables: Yup.array().of(inputFieldSchema),
        })}
      >
        {({ values, errors }) => {
          return (
            <form>
              <div className="border-bottom">
                <div className="container-fluid">
                  <div className="stepper d-flex justify-content-center align-items-center">
                    <CalculationSteps
                      bulkMutation={bulkMutation}
                      variables={values}
                    />
                    <div className="ms-4 ms-xl-5 d-flex align-items-center">
                      <button
                        disabled={bloading || benefitsCount === 0}
                        type="button"
                        id="previous"
                        className="btn btn-light btn-sm me-2"
                        onClick={(e) =>
                          handleButtons(values, errors, e, `benefits`)
                        }
                      >
                        Previous
                      </button>
                      <button
                        disabled={
                          bloading ||
                          benefitsCount === 0 ||
                          valueDriversCount === 0
                        }
                        type="button"
                        id="next"
                        className="btn btn-primary btn-sm"
                        onClick={(e) =>
                          handleButtons(values, errors, e, `characteristics`)
                        }
                      >
                        Next
                      </button>
                      {bloading && (
                        <i className="fa-solid fa-spinner fa-spin ms-2"></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {benefitsCount === 0 ? (
                <NoFound text="Benefits" link="benefits" />
              ) : (
                <>
                  {errors?.variables && (
                    <div className="alert alert-warning text-danger text-center hide">
                      There are some errors in the form. Kindly fill the form
                      properly without errors
                    </div>
                  )}
                  {berror && (
                    <div className="alert alert-warning text-danger text-center">
                      {berror?.message}
                    </div>
                  )}
                  {updateValueDriverStatusError && (
                    <div className="alert alert-warning text-danger text-center">
                      {updateValueDriverStatusError?.message}
                    </div>
                  )}
                  <div className="container-xxl py-4">
                    <div className="d-flex align-items-center justify-content-between mb-3 gap-2">
                      <h6>Value Drivers</h6>
                      <div className="d-flex gap-2 align-items-center">
                        <Link
                          to={`/product/${params?.id}/value-drivers`}
                          className=" text-black me-4"
                        >
                          Main View
                        </Link>
                        <Link to={`/product/${params?.id}/value-drivers`}>
                          <Form.Check
                            type="switch"
                            id="one"
                            className="pe-none"
                            checked
                            readOnly
                          />
                        </Link>
                        <Link to={`#`} className=" text-black me-4  ">
                          Suggestion View
                        </Link>
                        {plan === "lead" ? (
                          <>
                            {!isTeamMember && (
                              <ImportModal
                                instructions={instructions}
                                setErrors={setImportErrors}
                                errors={importErrors}
                                error={ierror}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <InputField
                                    type="file"
                                    onChange={(e) =>
                                      importFile(e.target.files[0])
                                    }
                                    accept=".csv"
                                  />
                                  {iloading && (
                                    <img
                                      src={spinner}
                                      width={40}
                                      alt="Laoding"
                                    />
                                  )}
                                </div>
                              </ImportModal>
                            )}
                            {valueDriversCount === 0 ? (
                              <TooltipComponent overlayText="Add at-least one value driver to export">
                                <div className="d-inline-block">
                                  <ButtonComponent
                                    type="outline-primary"
                                    size="sm"
                                    icon="download"
                                    position="start"
                                    disabled={true}
                                    iconType="solid"
                                    text="Export"
                                  />
                                </div>
                              </TooltipComponent>
                            ) : (
                              !isTeamMember && (
                                <ButtonComponent
                                  type="outline-primary"
                                  size="sm"
                                  icon={
                                    eloading ? "spinner fa-spin" : "download"
                                  }
                                  position="start"
                                  iconType="solid"
                                  text="Export"
                                  onClick={() => exportValueDrivers()}
                                />
                              )
                            )}
                          </>
                        ) : (
                          <>
                            <SalesFunnelModal className="btn btn-outline-primary btn-sm">
                              <i className="fas fa-upload me-2"></i>Import
                            </SalesFunnelModal>
                            {!isTeamMember && (
                              <SalesFunnelModal className="btn btn-outline-primary btn-sm">
                                <i className="fas fa-download me-2"></i>Export
                              </SalesFunnelModal>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="table-responsive position-relative">
                      <table className="table user-table calculation-table mb-0 driver-table">
                        <thead>
                          <tr className="border-bottom">
                            <th className="w-145-p">
                              <div className="d-flex align-items-center">
                                Value Driver Name
                                <InfoTooltip text="A Value Driver is a feature of the product or service you offer" />
                              </div>
                            </th>
                            <th>
                              Dimension 1
                              <InfoTooltip text="The dimensions of a value driver are are the different options to which value is offered to the customer, from the lowest to highest degree, for one value driver." />
                            </th>
                            <th>Dimension 2</th>
                            <th>Dimension 3</th>
                            <th>Dimension 4</th>
                            <th>Dimension 5</th>
                            <th>Dimension 6</th>
                            <th>Dimension 7</th>
                            <th>Dimension 8</th>
                            <th>{isTeamMember ? "Status" : " Actions"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <td className="p-0" colSpan={20}>
                            <div className="bg-primary p-2 text-center text-white">
                              Titles are required for all dimensions in value
                              driver
                            </div>
                          </td>
                          <FieldArray name="variables">
                            {({ push, remove }) => (
                              <>
                                {values?.variables?.map((val, index) => {
                                  //here
                                  const pairs = Object.keys(val).reduce(
                                    (result, item, index, arr) => {
                                      if ((index + 1) % 2 === 0) {
                                        if (item !== "valueDriver") {
                                          result.push([item, arr[index + 1]]);
                                        }
                                      }
                                      return result;
                                    },
                                    []
                                  );
                                  const valueDriverAttrs = [];
                                  if (val?.valueDriver?.type === "") {
                                    valueDriverAttrs.push(
                                      val?.valueDriver?.category
                                    );
                                  } else if (
                                    val?.valueDriver?.category === ""
                                  ) {
                                    valueDriverAttrs.push(
                                      val?.valueDriver?.type
                                    );
                                  } else {
                                    valueDriverAttrs.push(
                                      val?.valueDriver?.type,
                                      val?.valueDriver?.category
                                    );
                                  }
                                  return (
                                    <tr
                                      key={index}
                                      className={
                                        !currentStatusArray?.[index]?.status
                                          ? ""
                                          : isTeamMember &&
                                            currentStatusArray?.[index]
                                              ?.status !== "pending"
                                          ? "pe-none"
                                          : ""
                                      }
                                    >
                                      <td
                                        className={
                                          !isTeamMember
                                            ? "pe-none"
                                            : "cursor-pointer"
                                        }
                                        onClick={() => {
                                          setModalState(index);
                                          setAllValues(values);
                                          const attrs =
                                            values?.variables[index]
                                              ?.valueDriver;
                                          const valueDriver = {
                                            id: attrs?.id,
                                            name: attrs?.name,
                                            category: attrs?.category,
                                            featureStatus: attrs?.featureStatus,
                                            description: attrs?.description,
                                            type: attrs?.type,
                                            benefitId: attrs?.benefitId,
                                            unitOfMeasure: attrs?.unitOfMeasure,
                                          };
                                          setModalValue(valueDriver);
                                          setShowValueDriverModal(true);
                                        }}
                                      >
                                        {val?.valueDriver?.name || ""}
                                        <br />
                                        <div className="d-flex text-gray-500 fs-12 gap-3">
                                          {valueDriverAttrs.join(" • ")}
                                        </div>
                                      </td>
                                      {pairs.map((pair, parentIndex) => (
                                        <td
                                          key={parentIndex}
                                          className={
                                            !isTeamMember ? "pe-none" : ""
                                          }
                                        >
                                          {pair.map((objKey, childIndex) => (
                                            <div key={childIndex}>
                                              <Field
                                                as={InputField}
                                                className={`form-control ${
                                                  errors?.variables?.[index]?.[
                                                    objKey
                                                  ]
                                                    ? "border-red ms-n10"
                                                    : "ms-n10"
                                                }`}
                                                type={
                                                  objKey?.includes("Value")
                                                    ? "number"
                                                    : "text"
                                                }
                                                value={
                                                  values?.variables?.[index]?.[
                                                    objKey
                                                  ]
                                                }
                                                name={`variables.${index}.${objKey}`}
                                                placeholder={`Enter ${humanizeString(
                                                  objKey || ""
                                                )}`}
                                              />
                                              <div className="text-danger hide ms-n10">
                                                {
                                                  errors?.variables?.[index]?.[
                                                    objKey
                                                  ]
                                                }
                                              </div>
                                            </div>
                                          ))}
                                        </td>
                                      ))}

                                      {isTeamMember ? (
                                        <td>
                                          <Badges
                                            status={
                                              currentStatusArray?.[index]
                                                ?.status || "pending"
                                            }
                                          />
                                        </td>
                                      ) : (
                                        <td>
                                          <div className="d-flex gap-2 align-items-center">
                                            <div className="flex-column gap-3">
                                              <div>
                                                <p>
                                                  {valueDriverData
                                                    .productValueDrivers
                                                    ?.valueDrivers[index]?.owner
                                                    ?.fullName ||
                                                    valueDriverData
                                                      .productValueDrivers
                                                      ?.valueDrivers[index]
                                                      ?.owner?.email}
                                                </p>
                                                <p className="text-gray-500">
                                                  {
                                                    valueDriverData
                                                      .productValueDrivers
                                                      ?.valueDrivers[index]
                                                      ?.createdAt
                                                  }
                                                </p>
                                              </div>

                                              <div className="d-flex gap-2 align-items-center">
                                                <div
                                                  className={`d-flex ${
                                                    updateValueDriverStatusLoading
                                                      ? "pe-none op-0-5"
                                                      : ""
                                                  }`}
                                                  onClick={() =>
                                                    handleUpdateValueDriverStatusApprove(
                                                      params?.id,
                                                      values?.variables?.[index]
                                                        ?.valueDriver.id,
                                                      remove,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={successIcon}
                                                    alt="accept"
                                                    className="btn btn-light border-1 "
                                                  />
                                                </div>
                                                <div
                                                  className={`d-flex ${
                                                    updateValueDriverStatusLoading
                                                      ? "pe-none op-0-5"
                                                      : ""
                                                  }`}
                                                  onClick={() =>
                                                    handleUpdateValueDriverStatusReject(
                                                      params?.id,
                                                      values?.variables?.[index]
                                                        ?.valueDriver.id,
                                                      remove,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={errorIcon}
                                                    alt="reject"
                                                    className="btn btn-light border-1  "
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })}
                                {isTeamMember && (
                                  <tr className="row-action-btn">
                                    <td
                                      colSpan={12}
                                      className="cursor-pointer"
                                      onClick={async () => {
                                        const newValueDriver =
                                          createValueDriver();

                                        newValueDriver
                                          .then((result) => {
                                            const valueDriver =
                                              result?.data?.createValueDriver
                                                ?.valueDriver;
                                            if (!cloading) {
                                              setModalState("");
                                              setAllValues(values);
                                              setModalValue(valueDriver);
                                              setShowValueDriverModal(true);
                                              setAddValueDriver(() => push);
                                            }
                                          })
                                          .catch((error) => {
                                            // Handle any errors
                                          });
                                      }}
                                    >
                                      + Add Value Driver
                                    </td>
                                  </tr>
                                )}
                                {cloading && (
                                  <tr className="text-center spinner-table-loading">
                                    <td colSpan={8}>
                                      <div
                                        className="spinner-border text-primary"
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            )}
                          </FieldArray>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </form>
          );
        }}
      </Formik>
      {showValueDriverModal && (
        <ValueDriverModal
          allValues={allValues}
          addValueDriver={addValueDriver}
          modalState={modalState}
          setInitialValues={setInitialValues}
          benefitIds={benefitIds}
          modalValue={modalValue}
          initialValues={initialValues}
          showValueDriverModal={showValueDriverModal}
          setShowValueDriverModal={setShowValueDriverModal}
        />
      )}
    </>
  );
};
