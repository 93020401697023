import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { logo, question } from "@images";
import { Link, NavLink } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logoutThunk } from "@store/authSlice";
import { ProfilePic } from "@pages/Settings/ProfilePic";
import { ButtonComponent } from "@components";
import {
  accountSetting,
  billing,
  logout,
  onboarding,
  teamMembers,
} from "@images/index";

export const Header = (props) => {
  const dispatch = useDispatch();
  const user = props?.user;
  const isTeamMember = user?.isTeamMemberOfCurrentProduct;
  const adminToken = localStorage.getItem("adminToken");
  const backToAdmin = (e) => {
    e.target.disabled = true;
    localStorage.removeItem("adminToken");
    localStorage.setItem("token", adminToken);
    window.location.assign("/admin-panel");
  };

  return (
    <header>
      {adminToken && (
        <div className="mb-5">
          <div className="border-bottom bg-primary-50 fixed-top">
            <div className="container-xxl fs-14 p-2 d-flex align-items-center justify-content-between gap-2">
              <div>
                You are currently signed in as{" "}
                <span className="text-primary">
                  {user?.fullName || user?.email}
                </span>
              </div>
              <ButtonComponent
                size="sm"
                type="primary"
                customClass="flex-shrink-0"
                text="Back to Admin"
                onClick={(e) => backToAdmin(e)}
              />
            </div>
          </div>
        </div>
      )}
      <Navbar
        collapseOnSelect
        className="navbar-mobile py-2 border-bottom"
        expand="lg"
        bg="white"
        variant="light"
      >
        <Container fluid="xl">
          <Navbar.Brand as={Link} className="py-0" to="/">
            <img src={logo} alt="" width={182} className="img-fluid" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="rounded-0"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto d-flex align-items-start mt-2 mt-lg-0 top-header">
              {user?.role === "admin" ? (
                <NavLink className="nav-link" to="/admin-panel">
                  Back to Admin Panel
                </NavLink>
              ) : (
                <>
                  <NavLink
                    className={`nav-link ${user ? "order-4" : ""}`}
                    to="/pricing"
                  >
                    Pricing
                  </NavLink>

                  <NavLink
                    className={`nav-link me-2 mb-2 mb-lg-0 mt-2 mt-lg-0 ${
                      user ? "order-5" : ""
                    }`}
                    to="/contact"
                  >
                    <span className="header-custom-btn">
                      <img src={question} alt={question} className="me-1" />{" "}
                      Help{" "}
                    </span>
                  </NavLink>

                  {user ? (
                    <>
                      <NavLink className="nav-link order-0" to="/dashboard">
                        Dashboard
                      </NavLink>
                      <NavLink className="nav-link order-0" to="/products">
                        Products
                      </NavLink>
                      <NavLink
                        className="nav-link order-0"
                        to={`/product/${user?.currentProductId}/benefits`}
                      >
                        Calculation
                      </NavLink>
                      <div className="d-flex align-items-start order-5">
                        <ProfilePic
                          imageUrl={user?.imageUrl}
                          className="rounded-circle mt-1"
                          width="32"
                        />
                        <NavDropdown
                          className="user-dropdown"
                          title={user?.fullName || user?.email}
                          id="basic-nav-dropdown"
                          align="end"
                        >
                          <p className="text-gray-500 fs-10 px-3 pt-2 ">
                            MY PROFILE
                          </p>
                          <NavDropdown.Item as={Link} to="/onboarding">
                            <div className="d-flex gap-2 align-items-center px-2 py-12">
                              <img src={onboarding} alt="product" />
                              <p className="fs-14 ps-2">Onboarding</p>
                            </div>
                          </NavDropdown.Item>

                          <NavDropdown.Item as={Link} to="/subscription">
                            <div className="d-flex gap-2 align-items-center px-2 py-12">
                              <img src={billing} alt="product" />
                              <p className="fs-14 ps-2">Billing</p>
                            </div>
                          </NavDropdown.Item>
                          {!isTeamMember && (
                            <NavDropdown.Item as={Link} to="/team-member">
                              <div className="d-flex gap-2 align-items-center px-2 py-12">
                                <img src={teamMembers} alt="product" />
                                <p className="fs-14 ps-2">Team Members</p>
                              </div>
                            </NavDropdown.Item>
                          )}
                          <NavDropdown.Item as={Link} to="/profile">
                            <div className="d-flex gap-2 align-items-center px-2 py-12">
                              <img src={accountSetting} alt="product" />
                              <p className="fs-14 ps-2">Account Settings</p>
                            </div>
                          </NavDropdown.Item>
                          <hr className="m-0" />
                          <NavDropdown.Item
                            as={Link}
                            to="/"
                            onClick={(e) =>
                              adminToken
                                ? backToAdmin(e)
                                : dispatch(logoutThunk())
                            }
                          >
                            <div className="d-flex gap-2 px-2 py-12 align-items-center">
                              <img src={logout} alt="product" />
                              <p className="fs-14 ps-2">Logout</p>
                            </div>
                          </NavDropdown.Item>
                        </NavDropdown>
                      </div>
                    </>
                  ) : (
                    <>
                      <NavLink className="nav-link btn btn-light" to="/login">
                        Login
                      </NavLink>
                      <NavLink
                        className="ms-2 nav-link btn btn-primary text-light"
                        to="/signup"
                      >
                        Get Started
                      </NavLink>
                    </>
                  )}
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
