import { ButtonComponent } from "@components";
import { invite, close, checked } from "@images";

import "./style.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ACCEPT_INVITATION, DELETE_TEAM_MEMBER } from "@graphql/mutations";
import { SweetAlert } from "@services/SweetAlert";
import { Authentication } from "@services/Authentication";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { spinner } from "@images/index";

export const InvitedUsers = () => {
  const searchParams = useSearchParams();
  const invitationToken = searchParams[0].get("invitation_token");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [variables] = useState({
    invitationToken: invitationToken,
  });
  const [
    removeMember,
    { loading: removeMemberLoading, error: removeMemberError },
  ] = useMutation(DELETE_TEAM_MEMBER, {
    onCompleted: () => {
      SweetAlert("Invitation Rejected Successfully", "success");
    },
    onError: (e) => {
      SweetAlert(e, "error");
      console.log(e);
    },
  });
  const handleDelete = async (authenticityResource) => {
    try {
      await removeMember({
        variables: {
          allowToRemove: false,
          authenticityResource,
        },
      });
      navigate("/");
    } catch (e) {
      console.log(e);
    }
  };
  const [
    acceptInvitation,
    { loading: acceptMemberLoading, error: acceptMemberError },
  ] = useMutation(ACCEPT_INVITATION, {
    onCompleted: () => {
      SweetAlert("Invitation Accepted", "success");
    },
    onError: (e) => {
      SweetAlert(e, "error");
      console.log(e);
    },
  });

  return (
    <div className="d-flex align-items-center justify-content-center h-100 px-2">
      <div className="card mw-730 w-100 bg-gray-50">
        <div className="card-body text-center pt-3 pb-0 col-md-9 mx-auto">
          <img
            src={invite}
            alt="invite"
            className="mb-4"
            width="96"
            height="88"
          />
        </div>
        <div className="py-4 px-3 text-center">
          {removeMemberError && (
            <div className="mb-2 text-danger">{removeMemberError.message}</div>
          )}
          {acceptMemberError && (
            <div className="mb-2 text-danger">{acceptMemberError.message}</div>
          )}
          <h6>You have received an invitation</h6>
          <p className="mb-36 text-gray-500">
            You have got an invitation to join a Product on GetPricingPower
          </p>
        </div>
        <div className="d-flex justify-content-center px-3 pb-3">
          <div className="p-2">
            <ButtonComponent
              text={"Reject"}
              type={"btn btn-light"}
              position="start"
              image={removeMemberLoading ? spinner : close}
              onClick={() => handleDelete(invitationToken)}
            />
          </div>
          <div className="p-2">
            <ButtonComponent
              text={"Accept Invitation"}
              type={"primary"}
              position="start"
              image={acceptMemberLoading ? spinner : checked}
              onClick={(e) =>
                Authentication(
                  e,
                  acceptInvitation,
                  variables,
                  dispatch,
                  navigate
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
