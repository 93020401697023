import { OnboardingComponent } from "./OnboardingComponent";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { InputField, LoadingComponent } from "@components";
import { ADD_UPDATE_ONBOARDING_USER_RESPONSE } from "@graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { SweetAlert } from "@services";
import { onboardingList } from "./OnboardingList";
import { DebounceInput } from "react-debounce-input";
import { useOutletContext } from "react-router-dom";
import { SINGLE_ONBOARDING_USER_RESPONSES } from "@graphql/queries";
import "../style.scss";

export const Onboarding = () => {
  const user = useOutletContext();
  const [addUpdateOnboardingUserResponse] = useMutation(
    ADD_UPDATE_ONBOARDING_USER_RESPONSE
  );
  const stepNumber = useSelector((state) => state.onboarding.stepNumber);
  const [savedValue, setSavedValue] = useState();

  const { loading, data } = useQuery(SINGLE_ONBOARDING_USER_RESPONSES, {
    variables: { userId: user?.id },
    fetchPolicy: "network-only",
    onError: () => {
      SweetAlert(
        "Failed to load your previous onboarding responses. Kindly reload the page or check your network connection.",
        "error"
      );
    },
  });

  useEffect(() => {
    setSavedValue(
      data?.singleOnboardingUserResponses?.[`responseQ${stepNumber}`]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, stepNumber]);
  const handleSubmit = async (e, stepNumber) => {
    const { value, dataset } = e.target;
    const { id } = dataset;
    setSavedValue(value);
    if (!id.includes("other")) {
      if (document.querySelector(`.otherInput-${stepNumber}`) !== null) {
        document.querySelector(`.otherInput-${stepNumber}`).style.display =
          "none";
      }
      try {
        await addUpdateOnboardingUserResponse({
          variables: {
            ["responseQ" + stepNumber]: value,
          },
          refetchQueries: [
            { query: SINGLE_ONBOARDING_USER_RESPONSES },
            "singleOnboardingUserResponses",
          ],
        });
      } catch (e) {
        SweetAlert(
          "An error occurred, please try again or check your network connection.",
          "error"
        );
      }
    } else {
      document.querySelector(`.otherInput-${stepNumber}`).style.display =
        "block";
    }
  };

  const handleOtherSubmit = async (e, stepNumber) => {
    const { value } = e.target;
    setSavedValue(`Other - ${value}`);
    try {
      await addUpdateOnboardingUserResponse({
        variables: {
          ["responseQ" + stepNumber]: `Other - ${value}`,
        },
        refetchQueries: [
          { query: SINGLE_ONBOARDING_USER_RESPONSES },
          "singleOnboardingUserResponses",
        ],
      });
    } catch (e) {
      SweetAlert(
        "An error occurred, please try again or check your network connection.",
        "error"
      );
    }
  };

  if (loading) return <LoadingComponent height="calculation" />;

  return onboardingList.map((item, parentIndex) => (
    <OnboardingComponent
      key={parentIndex}
      heading={item?.heading}
      className={stepNumber !== parentIndex ? "d-none" : ""}
    >
      {item?.inputFields?.map((input, index) => (
        <label
          key={index}
          htmlFor={input.name}
          className="form-control mb-12 fs-14 p-11"
        >
          <div className="d-flex align-items-center gap-3">
            {input.name.includes("other") ? (
              <InputField
                fieldtype="radio"
                type="radio"
                name={parentIndex}
                id={input.name}
                data-id={input.name}
                customclass="flex-shrink-0 me-0 mt-s3"
                value={input.text}
                onChange={(e) => handleSubmit(e, stepNumber)}
                checked={savedValue?.includes("Other - ") ? true : undefined}
              />
            ) : (
              <InputField
                fieldtype="radio"
                type="radio"
                name={parentIndex}
                id={input.name}
                data-id={input.name}
                customclass="flex-shrink-0 me-0 mt-s3"
                value={input.text}
                onChange={(e) => handleSubmit(e, stepNumber)}
                checked={
                  !savedValue?.includes("Other - ") && savedValue === input.text
                }
              />
            )}

            <div className="w-100">
              {input.text}
              {input.name.includes("other") && (
                <DebounceInput
                  minLength={1}
                  data-id={input.name}
                  debounceTimeout={1000}
                  onChange={(e) => handleOtherSubmit(e, stepNumber)}
                  className={`${
                    savedValue?.includes("Other - ") ? "" : "hide"
                  } form-control mt-8 otherInput otherInput-${parentIndex}`}
                  placeholder="Specify your option"
                  data-saved={savedValue}
                  value={
                    savedValue?.includes("Other - ")
                      ? savedValue.replace("Other - ", "")
                      : ""
                  }
                />
              )}
            </div>
          </div>
        </label>
      ))}
    </OnboardingComponent>
  ));
};
