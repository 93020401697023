import { Link, Outlet } from "react-router-dom";
import { logo, check, rating } from "@images";
import Carousel from "react-bootstrap/Carousel";
import "./style.scss";

export const AuthLayout = () => {
  return (
    <div className="bg-primary-100">
      <div className="container-xxl">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 order-2 order-lg-1 d-none d-lg-block">
            <div className="mw-436 w-100 mx-auto">
              <h5 className="h5 fw-700 mb-5">
                SMEs, unlock 5-25% in additional profits, inspired by the best
                consulting firms in the world.
              </h5>
              <div className="mb-80">
                <div className="d-flex align-items-center mb-3">
                  <img src={check} alt={check} />{" "}
                  <p className="ms-2">Increased profits</p>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <img src={check} alt={check} />{" "}
                  <p className="ms-2">Improved competitive advantage</p>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <img src={check} alt={check} />{" "}
                  <p className="ms-2">The Right Offer for the Right Customer</p>
                </div>
              </div>
              <Carousel
                variant="dark"
                controls={false}
                indicators={true}
                interval={3000}
              >
                <Carousel.Item>
                  <img src={rating} alt="star" className="mb-3" />
                  <p className="mb-3">
                    Your approach showed us how to measure and monetize our USP.
                  </p>
                  <p className="fs-14">Pricing manager</p>
                  <p className="fs-12 opacity-50">Multinational organization</p>
                </Carousel.Item>
                <Carousel.Item>
                  <img src={rating} alt="star" className="mb-3" />
                  <p className="mb-3">The mini-MBA in pricing.</p>
                  <p className="fs-14">Pricing professional</p>
                  <p className="fs-12 opacity-50">Commodity industry</p>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start order-1 order-lg-2 py-4 min-vh-100">
            <div className="mw-445 w-100">
              <div className="card">
                <div className="card-body">
                  <Link to="/" className="d-block text-center mb-40">
                    <img
                      src={logo}
                      className="img-fluid"
                      alt="Logo"
                      width={182}
                    />
                  </Link>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
