import { Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { ALL_STEPS_COUNTER } from "@graphql/queries";
import { LoadingComponent, ErrorComponent, Header } from "@components";

export const CalculationLayout = () => {
  const user = useSelector((state) => state.auth.user);
  const params = useParams();
  const { loading, data } = useQuery(ALL_STEPS_COUNTER, {
    // skip: !params?.id || user?.subscription === false,
    variables: { productId: params?.id },
    fetchPolicy: "network-only",
  });
  if (loading) return <LoadingComponent height="100" />;
  if (params?.id !== user?.currentProductId)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const count = data?.allStepsCounter;
  return (
    <>
      <Header user={user} />
      <Outlet context={[count, user]} />
    </>
  );
};
