import { useState } from "react";
import { Pagination } from "react-bootstrap";

export const BackendPagination = (props) => {
  const { totalPages, stateCurrentPage } = props;
  const [currentPage, setCurrentPage] = useState(stateCurrentPage);

  const handlePageClick = (event) => {
    setCurrentPage(+event.target.text);
    props.paginationCurrentPage(+event.target.text);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      props.paginationCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      props.paginationCurrentPage(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    let items = [];

    // always include first page
    items.push(
      <Pagination.Item
        key={1}
        active={1 === currentPage}
        onClick={handlePageClick}
      >
        {1}
      </Pagination.Item>
    );

    // add ellipsis and fourth page if we have at least 5 pages
    if (totalPages >= 5 && currentPage >= 5) {
      items.push(<Pagination.Ellipsis disabled={true} />);
    }

    // add pages between second ellipsis (if present) and last page
    for (
      let number = Math.max(2, currentPage - 2);
      number <= Math.min(totalPages - 1, currentPage + 2);
      number++
    ) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={handlePageClick}
        >
          {number}
        </Pagination.Item>
      );
    }

    // add ellipsis before last page if we have at least 5 pages
    if (totalPages > 4 && currentPage + 3 <= totalPages) {
      items.push(<Pagination.Ellipsis disabled={true} />);
    }
    items.push(
      <Pagination.Item
        key={totalPages}
        active={totalPages === currentPage}
        onClick={handlePageClick}
      >
        {totalPages}
      </Pagination.Item>
    );

    return items;
  };

  return (
    totalPages > 1 && (
      <Pagination className="float-end">
        <Pagination.Prev
          onClick={handlePrevClick}
          disabled={currentPage === 1}
        />
        {getPaginationItems()}
        <Pagination.Next
          onClick={handleNextClick}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    )
  );
};
