export const startPackage = [
  {
    startPlan: "1 user",
  },
  {
    startPlan: "1 product or service offering",
  },
  {
    startPlan: "Up to 4 customer segments",
  },
  {
    startPlan: "3 hours of live coaching",
  },
];

export const growPackage = [
  {
    growPlan: "Up to 3 users",
  },
  {
    growPlan: "1 product or service offering",
  },
  {
    growPlan: "Up to 8 customer segments",
  },
  {
    growPlan: "4.5 hours of live coaching",
  },
];

export const leadPackage = [
  {
    leadPlan: "Up to 5 users",
  },
  {
    leadPlan: "Unlimited products or service offerings",
  },
  {
    leadPlan: "6 hours of live coaching",
  },
  {
    leadPlan: "Upload & download features",
  },
];

export const accordionArray = [
  {
    title: (
      <>
        What’s the purpose of the GetPricingPower.com Value-Based pricing
        application?
      </>
    ),
    body: (
      <>
        <div className="mb-2">
          <strong>Unlock 5-25% in additional profits</strong> through clear and
          pragmatic guidance to set or optimize your prices and increase your
          company's financial performance.
        </div>
        <div>
          Via an integrated workflow and advanced calculation modules, you
          design and implement your{" "}
          <strong>value-based pricing approach</strong>. This approach is
          notoriously the most complex to implement. We simplify it for you by
          offering clear guidance based on proven frameworks used by top-tier
          global consulting firms.
        </div>
      </>
    ),
  },
  {
    title: <>What are the benefits of the Value-Based pricing application?</>,
    body: (
      <>
        Through the app, you can <strong>improve pricing</strong> for existing
        products or services, set price points for <strong>new products</strong>{" "}
        or services, identify different price points for{" "}
        <strong>different segments</strong> and{" "}
        <strong>craft complex offers</strong> quickly for new and existing
        customers.
      </>
    ),
  },
  {
    title: <>For whom does this framework work best?</>,
    body: (
      <>
        This approach fits small & medium companies who offer{" "}
        <strong>highly differentiated</strong> as well as (near){" "}
        <strong>commoditized</strong> products and/or services, including
        software and SaaS applications.
      </>
    ),
  },
  {
    title: <>How does it work?</>,
    body: (
      <>
        <div className="mb-2">
          You complete different steps in a workflow for yourself and your team,
          which includes advanced calculation module with optimal segmented
          price points as a result.
        </div>
        <div>
          Don’t worry, it’s not a black box. You’ll be able to explain your
          investors and other stakeholders how you got to the price points.
        </div>
      </>
    ),
  },
  {
    title: (
      <>
        I don’t have experience in Pricing, can I complete the process by
        myself?
      </>
    ),
    body: (
      <>
        <div className="mb-2">
          Experience in pricing is not a hard requirement, a business and/or
          managerial background however is preferred.
        </div>
        <div>
          We offer video guidance, coaching and training to ensure you get to
          optimal financial performance through price setting & optimization for
          your company.
        </div>
      </>
    ),
  },
  {
    title: (
      <>How is the GetPricingPower.com app different from other pricing apps?</>
    ),
    body: (
      <>
        <div className="mb-2">
          We built our approach on the premise that pricing is at the heart of
          any organization’s financial performance. Hence, we start with the
          basics: develop your optimal price and structure your market into
          different segment.
        </div>
        <div className="mb-2">
          You will learn that this approach is extremely valuable.
        </div>
        <div className="mb-2">
          It’s been used by top-tier management consultancy firms across the
          globe to optimize prices, develop and sustain a competitive advantage,
          resulting in a boost in profits on the short, medium as well as the
          long term.
        </div>
        <div>
          We do not offer a black-box approach involving machine learning.
        </div>
      </>
    ),
  },
  {
    title: <>What’s your unique edge?</>,
    body: (
      <>
        We base our pricing approach on frameworks of leading consulting
        companies and make them available and accessible to medium-sized and
        large enterprises who otherwise wouldn’t consider or have access to
        price optimization.
      </>
    ),
  },
  {
    title: <>Can I collaborate with my team?</>,
    body: (
      <>
        Since pricing is at the heart of your company’s financial performance,
        our next release includes functionalities for team collaboration.
      </>
    ),
  },
  {
    title: <>What are the payment methods?</>,
    body: (
      <>
        You can subscribe to our plans via Stripe, an industry leader that
        provides different options for secure payment processing.
      </>
    ),
  },
  {
    title: <>Is my data secure?</>,
    body: (
      <>
        We use industry-standard security and protection. Get in touch via our
        contact page for more information.
      </>
    ),
  },
];
