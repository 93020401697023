import { SettingsSidebar } from "./SettingsSidebar";
import Form from "react-bootstrap/Form";

export const Notifications = () => {
  return (
    <div className="container-xxl py-5">
      <div className="mw-730 w-100 mx-auto">
        <SettingsSidebar />
        <h6 className="text-gray-900 mb-32">Notifications</h6>
        <form>
          <div className="mb-4">
            <Form.Check
              type="switch"
              id="one"
              label="Send me email updates every week"
              className="notification"
            />
          </div>
          <div className="mb-4">
            <Form.Check
              type="switch"
              id="tw0"
              label="Subscribe to weekly newsletter"
              className="notification"
            />
          </div>
          <div className="mb-4">
            <Form.Check
              type="switch"
              id="three"
              label="Update me of software updates via email"
              className="notification"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
