import React, { useState } from "react";
import "../style.scss";
import { useQuery, useMutation } from "@apollo/client";
import { PRODUCT_WEIGHT_SEQUENCE } from "@graphql/queries";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { BULK_ACTIONS_WEIGHT_ALLOCATIONS } from "@graphql/mutations";
import { SweetAlert } from "@services";
import { CalculationSteps } from "../CalculationSteps";
import { LoadingComponent } from "@components";
import { NoFound } from "../NoFound";
import { WeightPriceTableHeadings } from "../WeightPriceTableHeadings";

export const Weight = () => {
  const isSubscribed = useOutletContext()[1].subscription;
  const isTeamMember = useOutletContext()[1].isTeamMemberOfCurrentProduct;
  const valueDriversCount = useOutletContext()[0].valueDriversCount;
  const segmentsCount = useOutletContext()[0].segmentsCount;
  const navigate = useNavigate();
  const [valueDrivers, setValueDrivers] = useState([]);
  const [totalValue, setTotalValue] = useState();

  const handleFocus = (e) => {
    e.target.select();
  };

  // query for customer chracteristic start
  const params = useParams();

  const { loading, data } = useQuery(PRODUCT_WEIGHT_SEQUENCE, {
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
    skip: valueDriversCount === 0,
    variables: { productId: params?.id },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const productWeights = data?.productWeightInSequence;
      setTotalValue(
        productWeights.reduce(
          (accumulator, currentValue) => {
            currentValue.segments.forEach((segment, index) => {
              const key = `segment${index + 1}`;
              accumulator[key] += segment.weightAllocation;
            });
            return accumulator;
          },
          {
            segment1: 0,
            segment2: 0,
            segment3: 0,
            segment4: 0,
            segment5: 0,
            segment6: 0,
            segment7: 0,
            segment8: 0,
          }
        )
      );

      setValueDrivers(
        productWeights.map((item) => {
          const values = {};
          item.segments.forEach((segment, index) => {
            values[`segment${index + 1}`] = segment.weightAllocation;
          });
          return {
            ...item,
            values,
          };
        })
      );
    },
  });

  const [bulkActionsWeightAllocation, { loading: bulkLoading }] = useMutation(
    BULK_ACTIONS_WEIGHT_ALLOCATIONS,
    {
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
    }
  );

  const errorsShow = () => {
    const els = document.querySelectorAll(".text-danger");
    for (let x = 0; x < els.length; x++) els[x].style.display = "block";
  };

  const errorsHide = () => {
    const els = document.querySelectorAll(".text-danger");
    for (let x = 0; x < els.length; x++) els[x].style.display = "none";
  };

  const bulkMutation = async (link) => {
    const variables = [];

    valueDrivers.forEach((driver) => {
      const { valueDriver, values } = driver;

      Object.keys(values).forEach((segment) => {
        const segmentId = values[`${segment}Id`];
        const value = values[segment];

        if (segmentId && value !== null) {
          variables.push({
            productId: params?.id,
            valueDriverId: valueDriver?.id,
            segmentId,
            value,
          });
        }
      });
    });
    if (variables === undefined || variables?.length === 0) {
      navigate(`/product/${params?.id}/${link}`);
    } else {
      try {
        await bulkActionsWeightAllocation({
          variables: {
            input: { weightAllocationAttributes: variables },
          },
        });
        navigate(`/product/${params?.id}/${link}`);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleButtons = (e, link) => {
    if (isError() && e.target.id !== "previous") {
      if (isError() && !isTeamMember) {
        errorsShow();
      } else {
        bulkMutation(link);
      }
    } else {
      bulkMutation(link);
    }
  };

  if (loading) return <LoadingComponent height="calculation" />;

  const isError = () => {
    for (let i = 1; i <= segmentsCount; i++) {
      if (totalValue?.[`segment${i}`] !== 100) {
        return true;
      }
    }
    return false;
  };

  const handleChange = (index, key, event, segmentId) => {
    const newObj = {
      ...valueDrivers[index].values,
      [key]: Number(event.target.value),
      [`${key}Id`]: segmentId,
    };
    const newArray = [...valueDrivers];
    newArray[index].values = newObj;
    handleTotal(key);
    setValueDrivers(newArray);
  };
  const handleIncrement = (index, key, segmentId) => {
    const newObj = {
      ...valueDrivers[index].values,
      [key]: valueDrivers?.[index].values[key] + 1,
      [`${key}Id`]: segmentId,
    };
    const newArray = [...valueDrivers];
    newArray[index].values = newObj;
    handleTotal(key);
    setValueDrivers(newArray);
  };
  const handleDecrement = (index, key, segmentId) => {
    const newObj = {
      ...valueDrivers[index].values,
      [key]:
        valueDrivers?.[index].values[key] !== 0
          ? valueDrivers?.[index].values[key] - 1
          : 0,
      [`${key}Id`]: segmentId,
    };
    const newArray = [...valueDrivers];
    newArray[index].values = newObj;
    handleTotal(key);
    setValueDrivers(newArray);
  };

  const handleTotal = (key) => {
    errorsHide();
    let count = 0;
    valueDrivers.forEach((valueDriver) => {
      count += valueDriver.values[key];
    });
    setTotalValue({ ...totalValue, [key]: count });
  };

  const handleRowTotal = (index) => {
    let count = 0;
    const values = valueDrivers[index].values;

    for (let i = 0; i < segmentsCount; i++) {
      count += values?.[`segment${i + 1}`];
    }

    return count;
  };

  const segmentNames = [];
  data?.productWeightInSequence[0]?.segments?.map((segment) =>
    segmentNames.push(segment?.name)
  );

  return (
    <form>
      <div className="border-bottom">
        <div className="container-fluid">
          <div className="stepper d-flex justify-content-center align-items-center">
            <CalculationSteps bulkMutation={bulkMutation} />
            <div className="ms-4 ms-xl-5 d-flex align-items-center">
              <button
                disabled={bulkLoading || valueDriversCount === 0}
                type="button"
                id="previous"
                className="btn btn-light btn-sm me-2"
                onClick={(e) => handleButtons(e, "segments")}
              >
                Previous
              </button>
              <button
                type="button"
                id="next"
                onClick={(e) => handleButtons(e, "price")}
                disabled={bulkLoading || valueDriversCount === 0}
                className="btn btn-primary btn-sm"
              >
                Next
              </button>
              {bulkLoading && (
                <i className="fa-solid fa-spinner fa-spin ms-2"></i>
              )}
            </div>
          </div>
        </div>
      </div>

      {valueDriversCount === 0 ? (
        <NoFound text="Value Drivers" link="value-drivers" />
      ) : (
        <>
          {isError() && (
            <div className="alert alert-warning text-danger text-center hide">
              Weight of all segments must be equal to 100%
            </div>
          )}
          <div className="container-xxl pt-4 weight-section-space">
            <h6 className="mb-3">Weight Allocation</h6>
            <div className="table-responsive">
              <table className="table user-table calculation-table mb-0">
                <WeightPriceTableHeadings
                  isSubscribed={isSubscribed}
                  segmentNames={segmentNames}
                  segmentsCount={segmentsCount}
                />
                <tbody className={isTeamMember ? "pe-none " : ""}>
                  {valueDrivers?.map((driver, parentIndex) => {
                    const valueDriver = driver?.valueDriver;
                    const valueDriverAttrs = [];
                    if (valueDriver?.type === "") {
                      valueDriverAttrs.push(valueDriver?.category);
                    } else if (valueDriver?.category === "") {
                      valueDriverAttrs.push(valueDriver?.type);
                    } else {
                      valueDriverAttrs.push(
                        valueDriver?.type,
                        valueDriver?.category
                      );
                    }
                    return (
                      <tr key={parentIndex}>
                        <td>
                          <p>{valueDriver?.name}</p>
                          <p className="text-gray-500">
                            {valueDriverAttrs.join(" • ")}
                          </p>
                        </td>
                        {driver?.segments?.map((segment, index) => {
                          return (
                            <td key={index}>
                              <div className="d-flex align-items-center justify-content-center w-100">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDecrement(
                                      parentIndex,
                                      `segment${index + 1}`,
                                      segment?.segmentId
                                    )
                                  }
                                  className="btn btn-light fs-20 btn-sm increment-btn"
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  min={0}
                                  onFocus={(e) => handleFocus(e)}
                                  onFocusCapture={(e) => handleFocus(e)}
                                  value={
                                    driver?.values?.[`segment${index + 1}`] || 0
                                  }
                                  placeholder="0"
                                  onChange={(event) =>
                                    handleChange(
                                      parentIndex,
                                      `segment${index + 1}`,
                                      event,
                                      segment?.segmentId
                                    )
                                  }
                                  className="form-control text-center mx-2 field-increment"
                                />
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleIncrement(
                                      parentIndex,
                                      `segment${index + 1}`,
                                      segment?.segmentId
                                    )
                                  }
                                  className="btn btn-light fs-20 btn-sm increment-btn"
                                >
                                  +
                                </button>
                              </div>
                            </td>
                          );
                        })}

                        <td className="text-center">
                          {handleRowTotal(parentIndex)}
                        </td>
                      </tr>
                    );
                  })}

                  <tr className="bg-success-550">
                    <td>Total Value in %</td>
                    {valueDrivers[0]?.segments?.map((_, index) => (
                      <td className="text-center" key={index}>
                        <div
                          className={`py-1 px-2
                              ${
                                totalValue?.[`segment${index + 1}`] === 100
                                  ? "weight-badge-success py-1 px-2"
                                  : "weight-badge-danger py-1 px-2"
                              }`}
                        >
                          {totalValue?.[`segment${index + 1}`]}%
                        </div>
                      </td>
                    ))}

                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </form>
  );
};
