import { useMutation } from "@apollo/client";
import { DESTROY_BENEFIT } from "@graphql/mutations";
import { SweetAlert } from "@services";
import { SharedTableFields } from "../SharedTableFields";
import { ALL_STEPS_COUNTER } from "@graphql/queries";

export const BenefitsTableFields = (props) => {
  const { remove, values, errors } = props;

  const [destroyBenefit, { loading: destroyLoading }] = useMutation(
    DESTROY_BENEFIT,
    {
      onCompleted: () => {
        SweetAlert("Benefit has been deleted", "success");
      },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    }
  );

  const handleRemove = async (remove, index, id) => {
    try {
      await destroyBenefit({
        variables: {
          benefitId: id,
        },
      });
      const removeBenefit = () => remove(index);
      removeBenefit();
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  const Fields = [
    {
      type: "text",
      name: "id",
    },
    {
      type: "text",
      name: "name",
      placeholder: "Enter benefit name",
    },
    {
      type: "text",
      name: "description",
      placeholder: "Enter assumption",
    },
    {
      type: "switch",
      name: "verified",
    },
    {
      type: "switch",
      name: "quantifiable",
    },
    {
      type: "switch",
      name: "tangible",
    },
    {
      type: "number",
      name: "value",
      placeholder: "Enter value",
    },
    {
      type: "text",
      name: "unitOfMeasure",
      placeholder: "Enter UoM",
    },
  ];

  return (
    <SharedTableFields
      destroyLoading={destroyLoading}
      values={values}
      remove={remove}
      fields={Fields}
      lastFieldIndex={7}
      handleRemove={handleRemove}
      errors={errors}
    />
  );
};
