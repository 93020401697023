import { empty } from "@images";

export const NullFound = (props) => {
  const text = props?.text;
  return (
    <div className="card border rounded-0 px-2 text-center py-80">
      <div className="card-body">
        <img src={empty} alt="empty" className="mb-3" />
        <p className="text-center text-gray-500">{text}</p>
      </div>
    </div>
  );
};
