import { infoGray } from "@images";
import { TooltipComponent } from "./Tooltip";

export const InfoTooltip = ({ text }) => {
  return (
    <TooltipComponent overlayText={text} placement="top">
      <img src={infoGray} className="ms-1" alt="Tooltip" />
    </TooltipComponent>
  );
};
