import { useState } from "react";
import { profileImage } from "@images";
import Modal from "react-bootstrap/Modal";

export const ProfilePic = (props) => {
  const { imageUrl, className, previewImage, width } = props;
  const [show, setShow] = useState(false);
  const image =
    previewImage ||
    (imageUrl || imageUrl !== null
      ? `https://gppapi.herokuapp.com${imageUrl}`
      : profileImage);
  return (
    <>
      <img
        className={`cursor-zoom ${className ? className : ""}`}
        src={image}
        width={width}
        height={width}
        alt="Profile Pic"
        onClick={(e) => setShow(true)}
      />

      <Modal
        className="zoom-modal"
        size="lg"
        show={show}
        onHide={(e) => setShow(false)}
        centered
      >
        <Modal.Body>
          <img src={image} alt="Profile Pic" />
        </Modal.Body>
      </Modal>
    </>
  );
};
