import { Header } from "@components";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

export const StaticPagesLayout = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <>
      <Header user={user} />
      <Outlet context={user} />
    </>
  );
};
