import { logoutThunk } from "@store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { SidebarLinks } from "./SidebarLinks.js";
import { logOut } from "@images";
import "./style.scss";

export const SidebarPartial = () => {
  const dispatch = useDispatch();
  const collapsed = useSelector((state) => state.sidebarCollapse.collapsed);

  return (
    <ul
      className={`nav nav-pills sidebar-links ${
        collapsed ? "nav-flush text-center" : ""
      }`}
    >
      {SidebarLinks?.map((item, index) => (
        <li key={index}>
          <NavLink to={item.link} className="nav-link fw-medium p-3">
            <img src={item.icon} alt={item.name} />
            {collapsed ? (
              <span className="tooltip">{item.name}</span>
            ) : (
              <span>{item.name}</span>
            )}
          </NavLink>
        </li>
      ))}
      <li>
        <Link
          className="nav-link fw-medium p-3"
          to="/"
          onClick={() => dispatch(logoutThunk())}
        >
          <img src={logOut} alt="Logout" />
          {collapsed ? (
            <span className="tooltip">Logout</span>
          ) : (
            <span>Logout</span>
          )}
        </Link>
      </li>
    </ul>
  );
};
