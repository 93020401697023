import SidebarCanvas from "./SidebarCanvas";
import { SidebarPartial } from "./SidebarPartial";
import { useSelector } from "react-redux";

export const Sidebar = (props) => {
  const collapsed = useSelector((state) => state.sidebarCollapse.collapsed);
  return (
    <div>
      <SidebarCanvas />
      <div
        className={`d-none d-lg-block sidebar side-animation position-fixed ${
          collapsed ? "collapsed" : "expand"
        }`}
      >
        <SidebarPartial />
      </div>
    </div>
  );
};
