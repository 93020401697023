import { SharedTableHeadings } from "../SharedTableHeadings";

export const CharacteristicsTableHeadings = () => {
  const Headings = [
    {
      text: "Id",
    },
    {
      text: "Customer Characteristics",
      tooltip:
        "List all the characteristics of your customers, e.g. the geographic region, skills,...",
      width: "w-240-p",
    },
    {
      text: "Dimension 1",
      width: "w-249-p",
    },
    {
      text: "Dimension 2",
      width: "w-249-p",
    },
    {
      text: "Dimension 3",
      width: "w-249-p",
    },
    {
      text: "Dimension 4",
      width: "w-249-p",
    },
    {
      text: "Dimension 5",
      width: "w-249-p",
    },
    {
      text: "Dimension 6",
      width: "w-249-p",
    },
    {
      text: "Dimension 7",
      width: "w-249-p",
    },
    {
      text: "Dimension 8",
      width: "w-267-p",
    },
  ];
  return <SharedTableHeadings headings={Headings} />;
};
