import { InfoTooltip } from "@components";
import { useLocation } from "react-router-dom";

export const SharedTableHeadings = (props) => {
  const location = useLocation();
  const { headings } = props;
  return (
    <thead>
      <tr className="border-bottom">
        {headings?.map((item, index) => (
          <th
            key={index}
            className={`${index === 0 ? "d-none" : ""} ${
              item?.width ? item?.width : ""
            } align-middle`}
          >
            <div
              className={`d-flex align-items-center ${
                location?.pathname?.includes("weight") && index !== 1
                  ? "justify-content-center"
                  : ""
              }`}
            >
              {item?.text}
              {item?.tooltip && <InfoTooltip text={item?.tooltip} />}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
