import { ModalComponent, StripeLoading, LoadingComponent } from "@components";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FETCH_INVITED_MEMBERS, GET_CHECKOUT_URL } from "@graphql/queries";
import { SweetAlert } from "@services/SweetAlert";
import { BULK_ARCHIVE_MEMBERS } from "@graphql/mutations";

export const ChangePlanModal = (props) => {
  const { plan, maxMembers, currentPlan } = props;
  const [show, setShow] = useState(false);
  const [userIds, setUserIds] = useState([]); // Initialize the userIds state with useState
  const [getCheckoutUrl, { loading: checkoutLoading, error: checkoutError }] =
    useLazyQuery(GET_CHECKOUT_URL, {
      variables: { subscriptionPlan: plan },
      onCompleted: (data) => {
        window.location.assign(data?.getCheckoutUrl?.url);
      },
      onError: (e) => {
        SweetAlert(e, "error");
      },
    });

  const { loading, data, error } = useQuery(FETCH_INVITED_MEMBERS, {
    variables: { category: "ALL", archive: true },
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log(e);
    },
  });

  const [bulkArchiveMembers, { loading: archiveLoading, error: archiveError }] =
    useMutation(BULK_ARCHIVE_MEMBERS, {
      onCompleted: () => {
        getCheckoutUrl();
      },
    });

  // Event handler for checkbox change
  const handleCheckboxChange = (event) => {
    const selectedUserId = event.target.id;
    if (event.target.checked) {
      // If the checkbox is checked, add the user ID to the array
      setUserIds([...userIds, selectedUserId]);
    } else {
      // If the checkbox is unchecked, remove the user ID from the array
      setUserIds(userIds.filter((id) => id !== selectedUserId));
    }
  };

  const handleArchive = async () => {
    try {
      await bulkArchiveMembers({
        variables: {
          id: userIds,
        },
      });
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  if (loading) return <LoadingComponent height="100" />;

  const teamMembers = data?.invitedUsers;

  return (
    <>
      <button
        className={
          plan === "lead"
            ? "btn btn-lg btn-light rounded-large fw-700 text-primary radius-100 w-100 py-20 text-0D4BB5 mt-4"
            : "btn custom-btn w-100 mt-32"
        }
        onClick={() => (teamMembers.length === 0 ? getCheckoutUrl() : setShow(true))}
        disabled={currentPlan === plan || checkoutLoading}
      >
        {checkoutLoading && <i className="fas fa-spinner fa-spin me-2"></i>}
        {currentPlan === plan ? "Current Plan" : "Choose Plan"}
      </button>
      <ModalComponent
        title="Team Member Selection for New Plan"
        closeModal={() => {
          setShow(false);
          setUserIds([]);
        }}
        showModal={show}
        closeButtonText="Close"
        closeButtonType="light"
        submitButtonText="Change Plan"
        submitButtonType="primary"
        handleSubmit={handleArchive}
        loading={checkoutLoading || archiveLoading || loading}
      >
        {checkoutLoading || archiveLoading ? (
          <StripeLoading loading={checkoutLoading || archiveLoading} />
        ) : (
          <>
            You are changing your plan that may supports fewer or more team
            members than you currently have. Please choose up to {maxMembers}{" "}
            team member(s) to keep on the new plan. Other team members will be
            removed from your account.
            <br />
            <div className="weight-badge-warning px-3 py-1">
              Changes you will do here will not be reverted in case you will not
              complete your payment while changing your plan.
            </div>
            {error && <div className="text-danger my-2">{error?.message}</div>}
            {checkoutError && (
              <div className="text-danger my-2">{checkoutError?.message}</div>
            )}
            {archiveError && (
              <div className="text-danger my-2">{archiveError?.message}</div>
            )}
            <ul className="list-group mt-3">
              {teamMembers?.map((user) => (
                <label
                  key={user?.id}
                  htmlFor={user?.id}
                  className={
                    userIds.length === maxMembers && !userIds.includes(user?.id)
                      ? "op-0-5 pe-none"
                      : ""
                  }
                >
                  <li className="list-group-item">
                    <Form.Check
                      defaultChecked={userIds.includes(user?.id)}
                      type="checkbox"
                      id={user?.id}
                      label={user?.email}
                      onChange={handleCheckboxChange} // Attach the event handler to each checkbox
                    />
                  </li>
                </label>
              ))}
            </ul>
          </>
        )}
      </ModalComponent>
    </>
  );
};
