export const exportcsv = (base64) => {
  const decodedCSV = window.atob(base64);
  const blob = new Blob([decodedCSV], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = `exportedData${Date.now()}.csv`;
  document.body.appendChild(a);
  a.click();

  // Cleanup
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
