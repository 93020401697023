import { checkList } from "@images";
import { ModalComponent, ButtonComponent } from "@components";
import { ListGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

export const SalesFunnelModal = (props) => {
  const navigate = useNavigate();
  const { children, className, handlePlan, loading, error } = props;
  const [show, setShow] = useState();
  const paidPackage = [
    "Up to 5 users",
    "Unlimited products or service offerings",
    "Up to 8 customer segments",
    "6 hours of live coaching",
    "Upload & download features",
  ];
  return (
    <>
      <button type="button" className={className} onClick={() => setShow(true)}>
        {children}
      </button>
      <ModalComponent
        closeModal={() => setShow(false)}
        showModal={show}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
      >
        {error && (
          <div className="text-danger mb-2">
            There are few errors in saving your form, kindly resolve these
            errors and try again or check your network connection.
          </div>
        )}
        <h3 className="text-center mb-2">Unlock all features</h3>
        <p className="text-gray-600 text-center mb-4">
          Unleash the power of your product with{" "}
          <Link to="/" className="text-reset">
            GetPricingPower.com
          </Link>
          . Find the right price for your product and the best value for your
          customers.
        </p>
        <div className="px-0 px-lg-4">
          <div className="card price-card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <div>
                <h6>Lead Plan</h6>
                <p className="text-gray-500">The most demanded</p>
              </div>
              <div className="d-flex align-items-center">
                <h3 className="fw-bold">13,990€ </h3>
                <p className="fs-14 text-gray-500"> /year</p>
              </div>
            </div>
            <hr className="my-0" />
            <div className="card-body py-3">
              <p className="fs-12 text-gray-500 mb-2">
                By upgrading to this plan you will get:
              </p>
              <ListGroup variant="flush">
                {paidPackage.map((paid, index) => (
                  <ListGroup.Item
                    className="px-0 border-0 d-flex align-items-center"
                    key={index}
                  >
                    <img src={checkList} alt={checkList} className="me-2" />
                    {paid}
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <ButtonComponent
                size="lg"
                type="primary"
                text={
                  loading ? (
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  ) : (
                    "Upgrade Now"
                  )
                }
                width="100"
                onClick={() =>
                  handlePlan ? handlePlan() : navigate("/dashboard?plan=lead")
                }
                customClass="mt-3"
              />
              <p className="fs-12 text-center mt-3 mb-1">
                <span>Still not sure?</span>{" "}
                <Link to="/pricing">Explore Plans</Link>
              </p>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
