import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useMutation } from "@apollo/client";
import { IMPERSONATE_USER, MODIFY_ACCESS_USER } from "@graphql/mutations";
import { SweetAlert } from "@services";
import { ButtonComponent } from "@components";

export default function UserTable({ data }) {
  const [modifyAccessUser] = useMutation(MODIFY_ACCESS_USER);
  const [impersonateUser, { loading }] = useMutation(IMPERSONATE_USER);

  const handleActiveChange = async (event, user) => {
    const { checked } = event.target;
    try {
      await modifyAccessUser({
        variables: {
          userId: user.id,
          revokeAccess: !checked,
        },
      });
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  const impersonatedUser = async (event, id) => {
    event.target.disabled = true;
    const iconElement = document.createElement("i");
    iconElement.className = "fa-solid fa-spinner fa-spin";

    event.target.innerHTML = "";
    event.target.appendChild(iconElement);
    try {
      const data = await impersonateUser({
        variables: {
          userId: id,
        },
      });
      const adminToken = localStorage.getItem("token");
      localStorage.setItem("adminToken", adminToken);
      localStorage.removeItem("token");
      localStorage.setItem(
        "token",
        data?.data?.impersonateUser?.impersonatedToken
      );
      window.location.assign("/dashboard");
    } catch (e) {
      SweetAlert(e, "error");
      event.target.disabled = false;
      event.target.innerHTML = "Login As";
    }
  };

  return (
    <Table className="table user-table">
      <thead>
        <tr>
          <th>User Name</th>
          <th>Email address</th>
          <th>Access</th>
          <th>Subscription</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((user, index) => (
          <tr key={index}>
            <td>{user?.fullName}</td>
            <td>{user?.email}</td>
            <td>
              {/* look here */}
              <Form.Check
                type="switch"
                id={user?.id}
                label={`${user?.revokeAccess ? "Revoked" : "Granted"}`}
                defaultChecked={!user?.revokeAccess}
                onChange={(e) => handleActiveChange(e, user)}
              />
            </td>
            <td>
              {user?.subscription === true ? "Subscribed" : "Not Subscribed"}
            </td>
            <td>
              <div className="d-flex align-items-center gap-2">
                <ButtonComponent
                  disabled={loading}
                  type="light"
                  text="Login As"
                  onClick={(e) => impersonatedUser(e, user?.id)}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
