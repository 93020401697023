import { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import UserTable from "./UserTable";
import { useQuery } from "@apollo/client";
import { ALL_USERS } from "@graphql/queries";
import { LoadingComponent, ErrorComponent, NullFound } from "@components";

export const Users = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { loading, error, data } = useQuery(ALL_USERS, {
    variables: { skipAdmin: true },
    fetchPolicy: "network-only",
  });

  if (loading) return <LoadingComponent height="100" />;

  if (error)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const allUser = data.allUsers.nodes;

  const search = () => {
    const filtered = allUser.filter(
      (user) =>
        user.fullName?.toLowerCase().includes(searchTerm) ||
        user.email.toLowerCase().includes(searchTerm)
    );
    return filtered;
  };
  const filterResult = search();

  return (
    <>
      <div className="row align-items-center">
        <div className="col-sm-8 mb-4">
          <h5 className="">Users</h5>
        </div>
        <div className="col-sm-4 mb-4">
          <DebounceInput
            minLength={1}
            debounceTimeout={0}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control"
            placeholder="Search by Name or Email"
          />
        </div>
      </div>
      {filterResult.length ? (
        <div className="table-responsive">
          <UserTable data={filterResult} />
        </div>
      ) : (
        <NullFound text="No Data Found" />
      )}
    </>
  );
};
