import { useMutation } from "@apollo/client";
import { UPDATE_BENEFITS_STATUS } from "@graphql/mutations";
import { SweetAlert } from "@services";
import { useState } from "react";
import { TooltipComponent } from "@components/Tooltip";
import { Field } from "formik";
import { InputField } from "@components/FormFields";
import { errorIcon, successIcon } from "@images/index";
import { useOutletContext } from "react-router-dom";
import { Badges } from "@components/Badges";
const Fields = [
  {
    type: "text",
    name: "id",
  },
  {
    type: "text",
    name: "name",
    placeholder: "Enter benefit name",
  },
  {
    type: "text",
    name: "description",
    placeholder: "Enter assumption",
  },
  {
    type: "switch",
    name: "verified",
  },
  {
    type: "switch",
    name: "quantifiable",
  },
  {
    type: "switch",
    name: "tangible",
  },
  {
    type: "number",
    name: "value",
    placeholder: "Enter value",
  },
  {
    type: "text",
    name: "unitOfMeasure",
    placeholder: "Enter UoM",
  },
  {},
  {},
];

const TeamMemberFields = [
  {
    type: "text",
    name: "id",
  },
  {
    type: "text",
    name: "name",
    placeholder: "Enter benefit name",
  },
  {
    type: "text",
    name: "description",
    placeholder: "Enter assumption",
  },
  {
    type: "switch",
    name: "verified",
  },
  {
    type: "switch",
    name: "quantifiable",
  },
  {
    type: "switch",
    name: "tangible",
  },
  {
    type: "number",
    name: "value",
    placeholder: "Enter value",
  },
  {
    type: "text",
    name: "unitOfMeasure",
    placeholder: "Enter UoM",
  },
  {},
];
export const BenefitsSuggestionTableFields = (props) => {
  const { remove, values, errors, status, data } = props;
  const isTeamMember = useOutletContext()[1].isTeamMemberOfCurrentProduct;
  const productID = useOutletContext()[1].productId;
  const [fieldsArray] = useState(isTeamMember ? TeamMemberFields : Fields);
  const [inputFocus] = useState([]);
  const [
    updateBenefitStatus,
    { loading: updateBenefitStatusLoading, error: updateBenefitStatusError },
  ] = useMutation(UPDATE_BENEFITS_STATUS, {});

  const handleUpdateBenefitStatusApprove = async (
    productId,
    benefitId,
    remove,
    index,
    values
  ) => {
    try {
      await updateBenefitStatus({
        variables: {
          productId,
          benefitId,
          status: "APPROVED",
        },
      });
      handleRemove(remove, index, values?.variables?.[index].id);
      SweetAlert("Benefit has been approved", "success");
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateBenefitStatusReject = async (
    productId,
    benefitId,
    remove,
    index,
    values
  ) => {
    try {
      await updateBenefitStatus({
        variables: {
          productId,
          benefitId,
          status: "DECLINED",
        },
      });
      handleRemove(remove, index, values?.variables?.[index].id);
      SweetAlert("Benefit has been declined", "success");
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemove = async (remove, index, id) => {
    const removeBenefit = () => remove(index);
    removeBenefit();
  };

  const variables = values?.variables;
  return (
    <>
      {variables.map((_, index) => (
        <tr
          className={`${
            status.variables2[index]?.status === "approved" ||
            status.variables2[index]?.status === "declined"
              ? "pe-none"
              : ""
          }`}
          key={index}
        >
          {fieldsArray?.map((item, fieldIndex) => {
            const fieldVariableName = variables?.[index]?.[item?.name];
            const notQuantifiable =
              item?.name === "value" &&
              variables?.[index]?.quantifiable === false;
            if (notQuantifiable) {
              variables[index].value = 0;
            }
            const fieldName = `variables.${index}.${item?.name}`;
            const fieldErrors = errors?.variables?.[index]?.[item?.name];
            const tooltip = (boolean) => {
              inputFocus[index.toString() + fieldIndex] = boolean;
            };
            return (
              <td
                key={fieldIndex}
                className={`${fieldIndex === 0 ? "d-none " : ""}${
                  fieldIndex !== 9 && !isTeamMember ? "pe-none" : ""
                }`}
              >
                {fieldIndex <= 7 ? (
                  <TooltipComponent
                    overlayText={
                      inputFocus[index.toString() + fieldIndex] === true
                        ? undefined
                        : fieldVariableName !== ("" || true || false)
                        ? fieldVariableName
                        : undefined
                    }
                    placement="top"
                  >
                    <div>
                      <div>
                        <Field
                          onMouseOver={() => tooltip(false)}
                          onMouseOut={() => tooltip(false)}
                          onFocus={() => tooltip(true)}
                          onBlur={() => tooltip(false)}
                          as={InputField}
                          className={`${
                            fieldErrors
                              ? "border-red ms-n10"
                              : item?.type !== "switch"
                              ? " ms-n10"
                              : ""
                          }${
                            item?.type !== "switch"
                              ? " form-control"
                              : " form-check-input"
                          }`}
                          placeholder={item?.placeholder}
                          fieldtype={item?.type}
                          type={
                            item?.type === "switch" ? "checkbox" : item?.type
                          }
                          text={
                            item?.type === "switch"
                              ? fieldVariableName
                                ? "Yes"
                                : "No"
                              : ""
                          }
                          name={fieldName}
                          disabled={notQuantifiable}
                          readOnly={item?.name === "id"}
                        />
                        {fieldErrors && (
                          <div className="text-danger hide ms-n10">
                            {fieldErrors}
                          </div>
                        )}
                      </div>
                    </div>
                  </TooltipComponent>
                ) : fieldIndex === 8 ? (
                  isTeamMember === false ? (
                    <div className="flex-column gap-2">
                      <p>
                        {data.productBenefits[index].owner.fullName ||
                          data.productBenefits[index].owner.email}
                      </p>
                      <p className="text-gray-500">
                        {data.productBenefits[index].createdAt}
                      </p>
                    </div>
                  ) : (
                    <Badges status={status.variables2[index]?.status} />
                  )
                ) : fieldIndex === 9 && isTeamMember === false ? ( //here
                  <>
                    <div
                      className={`d-flex ${
                        updateBenefitStatusLoading ? "pe-none op-0-5" : ""
                      }`}
                    >
                      <div
                        onClick={() =>
                          handleUpdateBenefitStatusApprove(
                            productID,
                            _.id,
                            remove,
                            index,
                            values
                          )
                        }
                      >
                        <img
                          src={successIcon}
                          alt="accept"
                          className="btn btn-light border-1 "
                        />
                      </div>
                      <div
                        onClick={() =>
                          handleUpdateBenefitStatusReject(
                            productID,
                            _.id,
                            remove,
                            index,
                            values
                          )
                        }
                        className="ps-2"
                      >
                        <img
                          src={errorIcon}
                          alt="reject"
                          className="btn btn-light border-1  "
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </td>
            );
          })}
        </tr>
      ))}
    </>
  );
};
