import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "@graphql/mutations";
import { SettingsSidebar } from "./SettingsSidebar";
import { SweetAlert, HandleChange } from "@services";
import { useOutletContext } from "react-router-dom";
import { SubmitButton, InputField } from "@components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.scss";

export const ChangePassword = () => {
  const user = useOutletContext();

  const [updateUser, { error, loading }] = useMutation(CHANGE_PASSWORD);

  const [variables, setVariables] = useState({
    password: "",
    passwordConfirmation: "",
  });

  useEffect(() => {
    setVariables({
      email: user?.email,
      id: user?.id,
    });
  }, [user]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateUser({
        variables,
      });
      SweetAlert("Your password has been successfully updated.", "success");
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  return (
    <div className="container-xxl py-5">
      <div className="mw-730 w-100 mx-auto">
        <SettingsSidebar />
        <h6 className="text-gray-900 mb-32">Change Password</h6>
        <form onSubmit={handleFormSubmit}>
          {error && <div className="mb-2 text-danger">{error.message}</div>}
          <Row className="align-items-center">
            <Col sm={8} md={5}>
              <div className="mb-3">
                <InputField
                  onChange={(e) => HandleChange(e, variables, setVariables)}
                  type="password"
                  required={true}
                  name="password"
                  placeholder="Create a new password"
                  text="New Password"
                />
              </div>
              <div className="mb-5">
                <InputField
                  onChange={(e) => HandleChange(e, variables, setVariables)}
                  type="password"
                  required={true}
                  name="passwordConfirmation"
                  placeholder="Re-enter your  new password"
                  text="Confirm New Password"
                />
              </div>
            </Col>
          </Row>
          <SubmitButton loading={loading} size="lg" text="Change Password" />
        </form>
      </div>
    </div>
  );
};
