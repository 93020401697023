import { checkList, linkden, whiteLogo, whiteTick } from "@images/index";
import { SweetAlert } from "@services/SweetAlert";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_CONTACTOR } from "@graphql/mutations";
import { Link, useOutletContext } from "react-router-dom";
import {
  accordionArray,
  growPackage,
  leadPackage,
  startPackage,
} from "./PackageFaq";
import { AccordionComponent } from "@components/Accordion";
import { ChangePlanModal } from "@components/ChangePlanModal";

export const Pricing = () => {
  const user = useOutletContext();
  const currentPlan = user?.subscriptionPlan?.title;
  const [confirmCard, setConfirmCard] = useState(false);
  const [variables, setVariables] = useState({
    fullName: "",
    email: "",
    password: "",
    enquireAbout: "",
    message: "",
  });

  const [createContactor, { loading: contactLoading, error }] =
    useMutation(CREATE_CONTACTOR);


  const handleChange = (e) => {
    setVariables({ ...variables, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createContactor({
        variables,
      });
      setConfirmCard(true);
      e.target.reset();
    } catch (e) {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    }
  };

  return (
    <div>
      <div className="bg-F8F8F8 py-64 py-lg-130">
        <div className="container-xl">
          <div className="text-center mb-64 mb-lg-174">
            <h4 className="fw-700 mb-2">
              Personalized pricing plans for your business
            </h4>
            <p className="fs-18 text-666666 fw-normal">
              Everything that is worth having is worth paying for.
            </p>
            <p className="fs-18 text-666666 fw-normal">
              Choose your preferred plan or chat with our sales team to ask for
              a custom offer.
            </p>
          </div>
          <div className="card px-2 px-md-45 py-4 py-lg-40">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-sm-6 mb-5 mb-xl-0">
                <div className="mw-239 mx-auto">
                  <h5 className="mb-2">Start</h5>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h4 className="fw-700">3,990€</h4>
                    <p className="text-666666 fs-17 fw-normal">/year</p>
                  </div>
                  <p className="text-666666 fs-15 fw-normal mb-4">
                    Recommended for startups
                  </p>
                  {startPackage.map((paid, index) => (
                    <div
                      className="d-flex gap-2 align-items-center mb-2"
                      key={index}
                    >
                      <img src={checkList} alt="check" />
                      <p className="fs-12 fw-medium text-666666">
                        {paid.startPlan}{" "}
                      </p>
                    </div>
                  ))}

                  {user ? (
                    <ChangePlanModal
                      plan="start"
                      maxMembers={1}
                      currentPlan={currentPlan}
                    />
                  ) : (
                    <Link
                      to="/login?plan=start"
                      className="btn custom-btn w-100 mt-32"
                    >
                      Choose Plan
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 mb-5 mb-xl-0">
                <div className="mw-239 mx-auto">
                  <h5 className="mb-2">Grow</h5>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h4 className="fw-700">7,990€</h4>
                    <p className="text-666666 fs-17 fw-normal">/year</p>
                  </div>
                  <p className="text-666666 fs-15 fw-normal mb-4">
                    Recommended for SMEs
                  </p>
                  {growPackage.map((paid, index) => (
                    <div
                      className="d-flex gap-2 align-items-center mb-2"
                      key={index}
                    >
                      <img src={checkList} alt="check" />
                      <p className="fs-12 fw-medium text-666666">
                        {paid.growPlan}{" "}
                      </p>
                    </div>
                  ))}
                  {user ? (
                    <ChangePlanModal
                      plan="grow"
                      maxMembers={3}
                      currentPlan={currentPlan}
                    />
                  ) : (
                    <Link
                      to="/login?plan=grow"
                      className="btn custom-btn w-100 mt-34"
                    >
                      Choose Plan
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 mb-5 mb-xl-0">
                <div className="mw-292 mx-auto">
                  <div className="card popular-card px-28 pt-32 pb-12 mt--130">
                    <div className="mw-239">
                      <div className="text-end mb-3">
                        <div className="badge bg-light text-0D4BB5 radius-100 fw-700 px-3 py-2">
                          MOST POPULAR
                        </div>
                      </div>
                      <h5 className="mb-2 text-light">Lead</h5>
                      <div className="d-flex align-items-center gap-2 mb-2 text-light">
                        <h4 className="fw-700">13,990€</h4>
                        <p className="text-666666 fs-17 fw-normal text-light">
                          /year
                        </p>
                      </div>
                      <p className="text-666666 fs-15 fw-normal mb-4 text-light">
                        Recommended for large companies
                      </p>
                      {leadPackage.map((paid, index) => (
                        <div
                          className="d-flex gap-2 align-items-center mb-2"
                          key={index}
                        >
                          <img src={whiteTick} alt="check" />
                          <p className="fs-12 fw-medium text-light">
                            {paid.leadPlan}
                          </p>
                        </div>
                      ))}
                      {user ? (
                        <ChangePlanModal
                          plan="lead"
                          maxMembers={5}
                          currentPlan={currentPlan}
                        />
                      ) : (
                        <Link
                          to="/login?plan=lead"
                          className="btn btn-lg btn-light rounded-large fw-700 text-primary radius-100 w-100 py-20 text-0D4BB5 mt-4"
                        >
                          Choose Plan
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 mb-5 mb-xl-0">
                <div className="mw-239 mx-auto">
                  <h5 className="mb-2">Enterprise</h5>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h4 className="fw-700">Let's talk</h4>
                  </div>
                  <p className="text-666666 fs-15 fw-normal mb-95">
                    For tailored solutions at enterprise scale, connect with our
                    sales team. Your unique needs deserve a personalized offer.
                  </p>
                  <a
                    href="mailto:sales@getpricingpower.com"
                    className="btn custom-outline-btn w-100"
                  >
                    Talk to sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xl py-64 py-lg-130">
        <h4 className="fw-700 text-center mb-lg-3 mb-0">
          Got questions? We have the answers.
        </h4>
        <AccordionComponent
          accordionArray={accordionArray}
          accordionClass="pricing-accordion"
        />
        <div className="text-center">
          <a
            href="#contactForm"
            className="btn custom-outline-btn mt-64 py-20 px-5 btn-lg fw-700 radius-100"
          >
            Get more answers
          </a>
        </div>
      </div>
      <div className="gradient-bg">
        <div className="container-xl py-5 py-lg-100">
          <div className="d-flex justify-content-center justify-content-sm-between aling-items-center flex-wrap gap-3">
            <h4 className="fw-700 text-light text-center text-sm-left">
              Accelerate Profitable Growth
            </h4>
            <a
              href="https://calendly.com/getpricingpower/pricing-power-diagnostic"
              target="_blank"
              className="btn btn-light btn-lg radius-100 fw-700"
              rel="noreferrer"
            >
              Book a demo
            </a>
          </div>
        </div>
      </div>
      <div className="container-xl py-64 py-lg-130" id="contactForm">
        <div className="mw-637 mx-auto">
          <p className="text-uppercase mb-4 text-primary text-center fs-18 fw-700">
            Contact us
          </p>
          <h4 className="fw-700 text-center mb-2">
            Let's discuss your pricing needs
          </h4>
          <p className="fs-18 text-666666 text-center mb-5">
            Leave your name, email, and message in the form, and we'll be in
            touch soon to continue the discussion.
          </p>
          {confirmCard ? (
            <div className="card confirm-card px-4 py-64">
              <div className="mw-498 mx-auto text-center">
                <img
                  src={checkList}
                  className="mb-40"
                  width={58}
                  height={58}
                  alt="check"
                />
                <p className="text-uppercase fs-18 fw-bold text-black mb-3">
                  Thank You for Getting in Touch!
                </p>
                <p className="text-666666 fs-18 fw-normal mb-40">
                  Your message has been successfully received, and we appreciate
                  your interest in connecting with GetPricingPower.com. We will
                  respond to your inquiry as soon as possible.
                </p>
                <button
                  className="btn btn-outline-dark rounded-100 fw-bold border-2 btn-lg"
                  onClick={() => {
                    setConfirmCard(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              {error && (
                <div className="mb-2 text-danger">{error?.message}</div>
              )}
              <div className="row">
                <div className="col-sm-6 mb-36">
                  <label className="mb-12 fs-18 fw-700">Name</label>
                  <input
                    type="tex"
                    className="form-control bg-F8F8F8 border-0 p-4 contact-field"
                    placeholder="Enter your name"
                    name="fullName"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-6 mb-36">
                  <label className="mb-12 fs-18 fw-700">Email</label>
                  <input
                    type="email"
                    className="form-control bg-F8F8F8 border-0 p-4 contact-field"
                    placeholder="Enter your email"
                    name="email"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-12 mb-36">
                  <label className="mb-12 fs-18 fw-700">Enquire About</label>
                  <select
                    className="form-select bg-F8F8F8 border-0 p-4 contact-field"
                    name="enquireAbout"
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select one option</option>
                    <option value="Inquire about pricing plan">
                      Inquire about pricing plan
                    </option>
                    <option value="Need help with pricing flow">
                      Need help with pricing flow
                    </option>
                  </select>
                </div>
                <div className="col-sm-12 mb-36">
                  <label className="mb-12 fs-18 fw-700">Message</label>
                  <textarea
                    className="form-control bg-F8F8F8 border-0 p-4 contact-field"
                    rows={4}
                    placeholder="Please type your message here..."
                    name="message"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary radius-100 fw-700 py-20 px-45 btn-lg"
                  disabled={contactLoading}
                >
                  {" "}
                  {contactLoading ? (
                    <div className="spinner-grow" role="status"></div>
                  ) : (
                    "Send message"
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <footer className="pt-68 pb-32 bg-1E1E1E">
        <div className="container-xl">
          <div className="d-flex align-items-center justify-content-between gap-2">
            <Link to="/">
              {" "}
              <img src={whiteLogo} alt="logo" />
            </Link>
            <a
              href="https://www.linkedin.com/company/getpricingpower/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkden} alt="linkden" />
            </a>
          </div>
          <hr className="border-light mt-65 mb-32" />
          <p className="text-light fs-18 fw-normal text-center">
            Copyright © 2023 GetPricingPower | All Rights Reserved
          </p>
        </div>
      </footer>
    </div>
  );
};
