import ListGroup from "react-bootstrap/ListGroup";
import { backArrow, user } from "@images";
import "./style.scss";
import { useQuery } from "@apollo/client";
import { LoadingComponent, ErrorComponent, ButtonComponent } from "@components";
import { SINGLE_ONBOARDING_USER_RESPONSES } from "@graphql/queries";
import { useParams } from "react-router-dom";

export const SingleSurveyResult = () => {
  const params = useParams();

  const { loading, error, data } = useQuery(SINGLE_ONBOARDING_USER_RESPONSES, {
    variables: { userId: params?.id },
    fetchPolicy: "network-only",
  });

  if (loading) return <LoadingComponent height="100" />;

  if (error)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const singleResponse = data?.singleOnboardingUserResponses;

  if (!singleResponse)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const questions = [
    {
      question: "Which market are you active in?",
      answer: singleResponse?.responseQ1?.replace("Other - ", ""),
    },
    {
      question:
        "Which sales channels do you primarily use to grow your business?",
      answer: singleResponse?.responseQ2?.replace("Other - ", ""),
    },
    {
      question:
        "At which geographical level do you require your pricing to be reviewed? Select most appropriate?",
      answer: singleResponse?.responseQ3,
    },
    {
      question: "Which stage is your project or company in?",
      answer: singleResponse?.responseQ4,
    },
    {
      question: "What is your most important offer to your customers?",
      answer: singleResponse?.responseQ5?.replace("Other - ", ""),
    },
    {
      question:
        "What is your company's principal objective for the next 2-3 years?",
      answer: singleResponse?.responseQ6,
    },
    {
      question:
        "Are you building a pricing strategy for a new or an existing product/(digital)service?",
      answer: singleResponse?.responseQ7,
    },
    {
      question: "how many products need to have their pricing reviewed?",
      answer: singleResponse?.responseQ8,
    },
    {
      question: "Your company's current financial performance is:",
      answer: singleResponse?.responseQ9,
    },
    {
      question: "When was the last time you reviewed your price?",
      answer: singleResponse?.responseQ10?.replace("Other - ", ""),
    },
    {
      question:
        "Do you have clear insight in your company's fixed and variable costs?",
      answer: singleResponse?.responseQ11,
    },
    {
      question:
        "To your estimation how innovative is your business? (0 -> traditional business & 5 -> very innovative business)",
      answer: singleResponse?.responseQ12,
    },
    {
      question: "How do you want to position your company against competitors?",
      answer: singleResponse?.responseQ13,
    },
    {
      question:
        "To your estimate, how likely will your customer buy from your competitor if you increase price? (0 -> traditional business & 5 -> very innovative business)",
      answer: singleResponse?.responseQ14,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <ButtonComponent
            image={backArrow}
            link="/survey-results/"
            position="start"
            size="lg"
            type="light"
            customClass="d-inline me-3"
            onlyIcon="true"
          />
          <h5>Survey Details</h5>
        </div>
        <div className="d-flex">
          <img src={user} alt="Profile" />
          <div className="ms-2">
            <p>{singleResponse.user.fullName}</p>
            <p className="text-gray-500 fs-14">{singleResponse.user.email}</p>
          </div>
        </div>
      </div>

      <ListGroup variant="flush">
        {questions?.map((item, index) => (
          <ListGroup.Item className="ps-0 py-3" key={index}>
            <p className="text-gray-600 mb-2">{item?.question}</p>
            <h6>{item?.answer || "No Answer Given"}</h6>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};
