import { useState } from "react";
import Table from "react-bootstrap/Table";
import { upload, spinner } from "@images";
import "./style.scss";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  ALL_ONBOARDING_USER_RESPONSE,
  EXPORT_USER_RESPONSES,
} from "@graphql/queries";
import {
  LoadingComponent,
  ErrorComponent,
  NullFound,
  ButtonComponent,
  BackendPagination,
} from "@components";
import { Link } from "react-router-dom";
import { SweetAlert } from "@services";

export const SurveyResults = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const paginationCurrentPage = (paginationCurrentPage) => {
    setCurrentPage(paginationCurrentPage);
  };

  const { loading, error, data } = useQuery(ALL_ONBOARDING_USER_RESPONSE, {
    variables: {
      page: currentPage,
    },
    fetchPolicy: "network-only",
  });

  const [exportUsersResponses, { loadingExport }] = useLazyQuery(
    EXPORT_USER_RESPONSES,
    {
      onCompleted: () => {
        SweetAlert(
          "All survey responses have been exported. It will be sent on your email soon. Kindly check your email.",
          "success"
        );
      },
      onError: () => {
        SweetAlert(
          "An error occurred. Please try again or check your network connection.",
          "error"
        );
      },
    }
  );

  if (loading) return <LoadingComponent height="100" />;

  if (error)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const results = data?.allOnboardingUserResponses?.userResponses;
  const totalPages = data?.allOnboardingUserResponses?.totalPages;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h5>Survey Results</h5>
        {results.length > 0 && (
          <ButtonComponent
            image={loadingExport ? spinner : upload}
            position="start"
            size="lg"
            type="light"
            text="Export"
            onClick={() => exportUsersResponses()}
          />
        )}
      </div>
      {results.length > 0 ? (
        <>
          <div className="table-responsive mb-2">
            <Table className="table user-table text-nowrap mb-0">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Which market are you active in?</th>
                  <th>
                    Which sales channels do you primarily use to grow your
                    business?
                  </th>
                  <th>
                    At which geographical level do you require your pricing to
                    be reviewed? Select most appropriate?
                  </th>
                  <th>Which stage is your project or company in?</th>
                  <th>What is your most important offer to your customers?</th>
                  <th>
                    What is your company's principal objective for the next 2-3
                    years?
                  </th>
                  <th>
                    Are you building a pricing strategy for a new or an existing
                    product/(digital)service?
                  </th>
                  <th>
                    how many products need to have their pricing reviewed?
                  </th>
                  <th>Your company's current financial performance is:</th>
                  <th>When was the last time you reviewed your price?</th>
                  <th>
                    Do you have clear insight in your company's fixed and
                    variable costs?
                  </th>
                  <th>
                    {
                      "To your estimation how innovative is your business? (0 -> traditional business & 5 -> very innovative business)"
                    }
                  </th>
                  <th>
                    How do you want to position your company against
                    competitors?
                  </th>
                  <th>
                    {
                      "To your estimation how innovative is your business? (0 -> traditional business & 5 -> very innovative business)"
                    }
                  </th>
                </tr>
              </thead>
              <tbody>
                {results?.map((question) => (
                  <tr key={question?.id}>
                    <td>
                      <Link to={`/survey-results/${question?.user.id}`}>
                        {" "}
                        {question?.user.fullName}{" "}
                      </Link>
                    </td>
                    <td>
                      {question?.responseQ1?.replace("Other - ", "") ||
                        "No Answer Given"}
                    </td>
                    <td>
                      {question?.responseQ2?.replace("Other - ", "") ||
                        "No Answer Given"}
                    </td>
                    <td>{question?.responseQ3 || "No Answer Given"}</td>
                    <td>{question?.responseQ4 || "No Answer Given"}</td>
                    <td>
                      {question?.responseQ5?.replace("Other - ", "") ||
                        "No Answer Given"}
                    </td>
                    <td>{question?.responseQ6 || "No Answer Given"}</td>
                    <td>{question?.responseQ7 || "No Answer Given"}</td>
                    <td>{question?.responseQ8 || "No Answer Given"}</td>
                    <td>{question?.responseQ9 || "No Answer Given"}</td>
                    <td>
                      {question?.responseQ10?.replace("Other - ", "") ||
                        "No Answer Given"}
                    </td>
                    <td>{question?.responseQ11 || "No Answer Given"}</td>
                    <td>{question?.responseQ12 || "No Answer Given"}</td>
                    <td>{question?.responseQ13 || "No Answer Given"}</td>
                    <td>{question?.responseQ14 || "No Answer Given"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <BackendPagination
            totalPages={totalPages}
            paginationCurrentPage={paginationCurrentPage}
            stateCurrentPage={currentPage}
          />
        </>
      ) : (
        <NullFound text="No Data Found" />
      )}
    </>
  );
};
