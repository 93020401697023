import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { SweetAlert } from "@services/SweetAlert";
import { DELETE_TEAM_MEMBER, RESEND_INVITATION } from "@graphql/mutations";
import { FETCH_INVITED_MEMBERS } from "@graphql/queries";
import { TopBar } from "./TopBar";
import { LoadingComponent } from "@components/Loading";
import { Badges } from "@components/Badges";
import { useDispatch } from "react-redux";
import { userData } from "@store/authSlice";

export const Invites = () => {
  const dispatch = useDispatch();
  const { loading, data } = useQuery(FETCH_INVITED_MEMBERS, {
    variables: { category: "INVITEES", archive: false },
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log(e);
    },
  });

  const [
    removeMember,
    { loading: removeMemberLoading, error: removeMemberError },
  ] = useMutation(DELETE_TEAM_MEMBER, {
    onCompleted: () => {
      SweetAlert("Member Removed", "success");
    },
    refetchQueries: [{ query: FETCH_INVITED_MEMBERS }, "invitedUsers"],
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
  });
  const handleDelete = async (authenticityResource) => {
    const { data } = await removeMember({
      variables: {
        allowToRemove: true,
        authenticityResource,
      },
    });
    dispatch(userData(data?.removeMember));
  };
  const [
    ResendInvitation,
    { loading: resendInvitationLoading, error: resendInvitationError },
  ] = useMutation(RESEND_INVITATION, {
    onCompleted: () => {
      SweetAlert("Invitation Resent", "success");
    },
    onError: (e) => {
      SweetAlert(e, "error");
    },
  });
  const handleRezendInvite = (id) => {
    ResendInvitation({
      variables: {
        id,
      },
    });
  };

  if (loading) return <LoadingComponent height="100" />;

  return (
    <div className="container-xl py-4">
      <TopBar />
      {removeMemberError && (
        <div className="text-danger mb-2">{removeMemberError?.message}</div>
      )}
      {resendInvitationError && (
        <div className="text-danger mb-2">{resendInvitationError?.message}</div>
      )}
      <table className="table user-table  my-2">
        <thead>
          <th>Email Address</th>
          <th>Invitation Status</th>
          <th>Actions</th>
        </thead>
        <tbody>
          {data?.invitedUsers?.length ? (
            data?.invitedUsers?.map((users) => (
              <tr key={users?.id}>
                <td>{users.email}</td>
                <td>
                  <Badges status={users.invitationStatus} />
                </td>
                <td>
                  <button
                    type="button"
                    disabled={removeMemberLoading || resendInvitationLoading}
                    className="btn btn-light btn-sm me-2"
                    onClick={() => handleRezendInvite(users.id)}
                  >
                    Resend Invite
                  </button>

                  <button
                    type="button"
                    disabled={removeMemberLoading || resendInvitationLoading}
                    className="btn btn-danger btn-sm me-2"
                    onClick={() => handleDelete(users.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className="text-center">
                No Invited Member found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
