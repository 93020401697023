import { useMutation } from "@apollo/client";
import {
  BULK_UPDATE_SEGMENTS,
  ADD_UPDATE_VALUE_IN_SEGMENT,
  DESTROY_SEGMENT,
} from "@graphql/mutations";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { SweetAlert } from "@services";
import { SegmentsTableHeadings } from "./SegmentsTableHeadings";
import "../style.scss";
import { LoadingComponent, InfoTooltip } from "@components";
import { useQuery } from "@apollo/client";
import { SEGMENTS_AND_VALUES, ALL_STEPS_COUNTER } from "@graphql/queries";
import { useState } from "react";
import { CalculationSteps } from "../CalculationSteps";
import { NoFound } from "../NoFound";

export const Segments = () => {
  const user = useOutletContext()[1];
  const plan = user.subscriptionPlan.title;
  const isSubscribed = user.subscription;
  const characteristicsCount = useOutletContext()[0].characteristicsCount;
  const customerValuesCount = useOutletContext()[0].customerValuesCount;
  const isTeamMember = user.isTeamMemberOfCurrentProduct;
  const segmentsCount = useOutletContext()[0].segmentsCount;
  const navigate = useNavigate();
  const params = useParams();
  const [segment, setSegment] = useState([]);
  const [total, setTotal] = useState();

  // logic
  const isError = () => {
    if (!isTeamMember) {
      return (
        total?.marketSize <= 0 ||
        total?.marketSize > 100 ||
        total?.importance <= 0 ||
        total?.importance > 100
      );
    }
  };

  const { loading, data } = useQuery(SEGMENTS_AND_VALUES, {
    skip: characteristicsCount === 0 || customerValuesCount === 0,
    variables: { productId: params?.id },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const segments = data?.segmentsAndValues?.segments;
      const newArray = segments.map(
        ({ id, name, position, marketSize, importance }) => ({
          id,
          name,
          position,
          marketSize,
          importance,
          productId: params?.id,
        })
      );
      setSegment(newArray);
      setTotal({
        marketSize: segments.reduce((acc, obj) => acc + obj?.marketSize, 0),
        importance: segments.reduce((acc, obj) => acc + obj?.importance, 0),
      });
    },
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
  });
  const [bulkUpdateSegments, { loading: bloading }] = useMutation(
    BULK_UPDATE_SEGMENTS,
    {
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
    }
  );

  const [destroySegment, { loading: dloading }] = useMutation(DESTROY_SEGMENT, {
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
    refetchQueries: [
      { query: SEGMENTS_AND_VALUES, ALL_STEPS_COUNTER },
      "segmentsAndValues",
      "allStepsCounter",
    ],
  });

  const [addUpdateValueInSegment, { loading: uloading }] = useMutation(
    ADD_UPDATE_VALUE_IN_SEGMENT,
    {
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
    }
  );

  if (loading) return <LoadingComponent height="calculation" />;

  const errorsShow = () => {
    const els = document.querySelectorAll(".text-danger");
    for (let x = 0; x < els.length; x++) els[x].style.display = "block";
  };

  const errorsHide = () => {
    const els = document.querySelectorAll(".text-danger");
    for (let x = 0; x < els.length; x++) els[x].style.display = "none";
  };

  const handleSelectChange = async (event) => {
    errorsHide();
    const { segmentid, characteristicid, segmentposition } =
      event.target.dataset;
    try {
      await addUpdateValueInSegment({
        variables: {
          segmentId: segmentid,
          customerCharacteristicId: characteristicid,
          customerValueId: event.target.value,
          position: +segmentposition,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const bulkMutation = async (link, variables) => {
    if (variables === undefined || variables?.length === 0) {
      navigate(`/product/${params?.id}/${link}`);
    } else {
      try {
        await bulkUpdateSegments({
          variables: { input: { segmentsAttributes: variables } },
        });
        navigate(`/product/${params?.id}/${link}`);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const deleteSegment = async (id) => {
    try {
      await destroySegment({
        variables: { segmentId: id },
      });
      SweetAlert("Segment has been deleted successfully", "success");
    } catch (e) {
      console.log(e);
    }
  };

  const handleButtons = (e, link) => {
    e.preventDefault();
    const variables = [...segment];
    if (e?.target?.id === "next" && isError()) {
      errorsShow();
    } else {
      bulkMutation(link, variables);
    }
  };

  const handleMarketSize = (e, index) => {
    segment[index].marketSize = +e.target.value;
    setTotal({
      ...total,
      marketSize: segment.reduce((acc, obj) => acc + obj.marketSize, 0),
    });
  };

  const handleImportance = (e, index) => {
    segment[index].importance = +e.target.value;
    setTotal({
      ...total,
      importance: segment.reduce((acc, obj) => acc + obj.importance, 0),
    });
  };
  let characteristics;
  let segments;
  if (data) {
    characteristics = data?.segmentsAndValues?.characteristics;
    segments = data?.segmentsAndValues?.segments;
  }

  return (
    <form>
      <div className="border-bottom">
        <div className="container-fluid">
          <div className="stepper d-flex justify-content-center align-items-center">
            <CalculationSteps
              bulkMutation={bulkMutation}
              variables={[...segment]}
            />
            <div className="ms-4 ms-xl-5 d-flex align-items-center">
              <button
                type="button"
                className="btn btn-light btn-sm me-2"
                id="previous"
                onClick={(e) => handleButtons(e, "characteristics")}
                disabled={
                  bloading ||
                  uloading ||
                  characteristicsCount === 0 ||
                  customerValuesCount === 0
                }
              >
                Previous
              </button>
              <button
                onClick={(e) => handleButtons(e, "weight")}
                id="next"
                type="button"
                className="btn btn-primary btn-sm"
                disabled={
                  bloading ||
                  uloading ||
                  characteristicsCount === 0 ||
                  customerValuesCount === 0
                }
              >
                Next
              </button>
              {(bloading || uloading) && (
                <i className="fa-solid fa-spinner fa-spin ms-2"></i>
              )}
            </div>
          </div>
        </div>
      </div>

      {characteristicsCount === 0 ? (
        <NoFound text="Characteristics" link="characteristics" />
      ) : customerValuesCount === 0 ? (
        <NoFound text="Customer Values" link="characteristics" />
      ) : (
        <>
          {isError() && (
            <div className="alert alert-warning text-danger text-center hide">
              Total percentage of Market size and Strategic importance must be
              greater than 0 and less than or equal to 100
            </div>
          )}
          <div className="container-xxl pt-4 segment-section-space">
            <div className="table-responsive">
              <h6 className="mb-3">Customer Segments</h6>
              <table className="table user-table calculation-table mb-0">
                <SegmentsTableHeadings
                  isTeamMember={isTeamMember}
                  plan={plan}
                  isSubscribed={isSubscribed}
                  segmentsCount={segmentsCount}
                />
                <tbody className={isTeamMember ? "pe-none " : ""}>
                  {characteristics?.map((singleChr, index) => (
                    <tr key={index}>
                      <td className="d-none">{singleChr?.id}</td>
                      <td>{singleChr?.name}</td>
                      {segments?.map((segment, segmentIndex) => {
                        const selectedValue = singleChr?.selectedValues?.find(
                          (value) =>
                            value?.segmentPosition === segment?.position
                        )?.value;
                        return (
                          <td className="position-relative" key={segmentIndex}>
                            <select
                              className="form-select ms-n1"
                              data-segmentid={segment?.id}
                              data-characteristicid={singleChr?.id}
                              data-segmentposition={segment?.position}
                              onChange={handleSelectChange}
                              defaultValue={selectedValue?.id || ""}
                            >
                              <option disabled={true} value="">
                                Select Value
                              </option>
                              {singleChr?.customerValues?.map(
                                (customer, customerIndex) => (
                                  <option
                                    key={customerIndex}
                                    value={customer?.id}
                                  >
                                    {customer?.value}
                                  </option>
                                )
                              )}
                            </select>
                            {index === 0 && segmentIndex > 3 && (
                              <div
                                className={`segment-destroy ${
                                  dloading ? "pe-none op-0-5" : ""
                                }`}
                                onClick={() => deleteSegment(segment?.id)}
                              >
                                <i className="fa-solid fa-trash text-gray-400 fs-14"></i>
                              </div>
                            )}
                          </td>
                        );
                      })}
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="table-responsive mt-5">
              <table className="table user-table calculation-table mb-0">
                <thead>
                  <tr className="border-bottom">
                    <th className="w-13-p py-2 align-middle">
                      <p className="ps-2 fs-16 d-flex align-items-center">
                        Segment Name
                        <InfoTooltip text="Add a name to identify your customer segments" />
                      </p>
                    </th>
                    {segments?.map((_, index) => (
                      <th
                        key={index}
                        className={`w-13-p py-2 align-middle ${
                          isTeamMember ? "pe-none " : ""
                        }`}
                      >
                        <input
                          type="text"
                          name="name"
                          required={true}
                          className="form-control"
                          defaultValue={
                            segment[index]?.name !== ""
                              ? segment[index]?.name
                              : `Segment ${index + 1}`
                          }
                          onChange={(e) => {
                            segment[index].name = e.target.value;
                          }}
                        />
                      </th>
                    ))}
                    <th className="w-4-p"></th>
                  </tr>
                </thead>
                <tbody className={isTeamMember ? "pe-none " : ""}>
                  <tr>
                    <td className="bg-gray-50 py-2">
                      <p className="ps-2 fs-16 d-flex align-items-center">
                        Market Size
                        <InfoTooltip text="Market size of the segment" />
                      </p>
                    </td>
                    {segments?.map((_, index) => (
                      <td
                        key={index}
                        className={`bg-gray-50 py-2 ${
                          isTeamMember ? "pe-none " : ""
                        }`}
                      >
                        <div className="input-group">
                          <input
                            min={0}
                            step={1}
                            type="number"
                            name="marketSize"
                            className="form-control small-input"
                            defaultValue={segment[index]?.marketSize}
                            onChange={(e) => handleMarketSize(e, index)}
                          />
                          <span className="input-group-text">%</span>
                        </div>
                      </td>
                    ))}
                    <td className="bg-gray-50 py-2">
                      <div
                        className={`text-end ${
                          total?.marketSize <= 0 || total?.marketSize > 100
                            ? "text-dangerous"
                            : ""
                        }`}
                      >
                        {total?.marketSize}%
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-gray-50 py-2">
                      <p className="ps-2 fs-16 d-flex align-items-center">
                        Strategic Importance
                        <InfoTooltip text="Strategic importance of the segment" />
                      </p>
                    </td>
                    {segments?.map((_, index) => (
                      <td
                        key={index}
                        className={`bg-gray-50 py-2 ${
                          isTeamMember ? "pe-none " : ""
                        }`}
                      >
                        <div className="input-group">
                          <input
                            min={0}
                            step={1}
                            type="number"
                            name="importance"
                            className="form-control small-input"
                            defaultValue={segment[index]?.importance}
                            onChange={(e) => handleImportance(e, index)}
                          />
                          <span className="input-group-text">%</span>
                        </div>
                      </td>
                    ))}
                    <td className="bg-gray-50 py-2">
                      <div
                        className={`text-end ${
                          total?.importance <= 0 || total?.importance > 100
                            ? "text-dangerous"
                            : ""
                        }`}
                      >
                        {total?.importance}%
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </form>
  );
};
