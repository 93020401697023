import "./style.scss";
import { useQuery } from "@apollo/client";
import { ADMIN_DASHBOARD_ANALYTICS } from "@graphql/queries";
import { LoadingComponent, ErrorComponent, ButtonComponent } from "@components";

export const AdminPanel = () => {
  const { loading, error, data } = useQuery(ADMIN_DASHBOARD_ANALYTICS, {
    fetchPolicy: "network-only",
  });

  if (loading) return <LoadingComponent height="100" />;

  if (error)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const usersStatic = data?.adminDashboardAnalytics;

  return (
    <>
      <h5 className="mb-4">Dashboard</h5>
      <div className="row">
        <div className="col-sm-6 mb-3">
          <div className="card border">
            <div className="card-body">
              <h6 className="text-gray-500 mb-2">Total Users</h6>
              <h4 className="mb-4">{usersStatic?.usersCount}</h4>
              <ButtonComponent link="/users" type="light" text="View Users" />
            </div>
          </div>
        </div>
        <div className="col-sm-6 mb-3">
          <div className="card border">
            <div className="card-body">
              <h6 className="text-gray-500 mb-2">Paid Users</h6>
              <h4 className="mb-4">{usersStatic?.paidUsersCount}</h4>
              <ButtonComponent link="/users" type="light" text="View Users" />
            </div>
          </div>
        </div>
        <div className="col-sm-6 mb-3">
          <div className="card border">
            <div className="card-body">
              <h6 className="text-gray-500 mb-2">Submitted Survey Forms</h6>
              <h4 className="mb-4">
                {usersStatic?.usersOnboardingResponsesCount}
              </h4>
              <ButtonComponent
                link="/survey-results"
                type="light"
                text="View Survey Forms"
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6 mb-3">
          <div className="card border">
            <div className="card-body">
              <h6 className="text-gray-500 mb-2">User Queries</h6>
              <h4 className="mb-4">{usersStatic?.contactorsCount}</h4>
              <ButtonComponent
                link="/queries"
                type="light"
                text="View Queries Table"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
