import { NoDataFound } from "./NoDataFound";

export const Cardsdash = ({ title, mapping, currency, myTodos }) => {
  return (
    <div className="col-sm-6 col-lg-3 mb-3">
      <div className="card border h-100 d-flex flex-column">
        <div className="card-header bg-white border-bottom text-center fs-20">
          {title}
        </div>
        {title === "Value per segment" && !myTodos?.calculatePriceModel ? (
          <NoDataFound />
        ) : mapping?.length ? (
          <div className="card-body p-2 bg-primary-50">
            {mapping?.map((item, index) => {
              const valueDriverAttrs = [
                item?.type || "",
                item?.category || "",
              ].filter(Boolean);
              return (
                <div
                  className="card border px-12 py-2  d-block mb-1"
                  key={index}
                >
                  <p className="fs-14 mb-1">
                    {item?.name ||
                      item?.segmentName ||
                      "Segment " + (index + 1)}
                  </p>
                  {title === "Benefits" ? (
                    <>
                      <p className="text-gray-600 fs-12 mb-2">
                        {currency}
                        {+(item?.value).toFixed(2) || "0"}
                        <span
                          className={
                            item?.unitOfMeasure ? "d-inline" : "d-none"
                          }
                        >
                          /
                        </span>
                        {item?.unitOfMeasure || ""}
                      </p>
                      <div
                        className={`dashboard-badge d-inline-block ${
                          item?.verified ? "success" : "danger"
                        }`}
                      >
                        {item?.verified ? "Verified Assumption" : "Unverified"}
                      </div>
                    </>
                  ) : title === "Value Drivers" ? (
                    <>
                      <p className="text-gray-500 fs-12">
                        {valueDriverAttrs.join(" • ")}
                      </p>
                    </>
                  ) : title === "Customer Characteristics" ? (
                    <>
                      <p className="text-gray-600 fs-12">
                        {item?.totalValues || "0"} value
                        {item?.totalValues !== 1 && "s"}
                      </p>
                    </>
                  ) : title === "Value per segment" ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="text-gray-600 fs-12">
                        {item?.valueDriversCount || "0"} Value Driver
                        {item?.valueDriversCount !== 1 && "s"}
                      </p>
                      <div className="dashboard-badge primary d-inline-block">
                        {currency}
                        {parseInt(item?.totalPrice) || "0"}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </div>
  );
};
