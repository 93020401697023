import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ErrorComponent, LoadingComponent } from "@components";
import {
  AuthLayout,
  MainLayout,
  StaticPagesLayout,
  AdminLayout,
  CalculationLayout,
} from "@layouts";
import {
  ForgotPassword,
  Login,
  SetPassword,
  Signup,
  AdminLogin,
} from "@pages/Authentication";
import {
  ChangePassword,
  EditProfile,
  Notifications,
  Profile,
  Subscription,
} from "@pages/Settings";
import { Pricing, Home, Contact } from "@pages/StaticPages";
import { NotFound } from "@pages/NotFound";
import { Dashboard, Onboarding, Thankyou } from "@pages/User";
import {
  AdminPanel,
  Users,
  SurveyResults,
  SingleSurveyResult,
  Queries,
} from "@pages/Admin";
import {
  Benefits,
  ValueDrivers,
  Characteristics,
  Segments,
  Weight,
  Price,
} from "@pages/Calculation";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { FETCH_USER } from "@graphql/queries";
import { userData } from "@store/authSlice";
import { TeamMembers } from "@pages/User/Team Members/TeamMembers";
import { Invites } from "@pages/User/Team Members/Invites";
import { InvitedUsers } from "@pages/User/InvitedUsers";
import { BenefitsSuggestion } from "@pages/Calculation/Benefits/BenefitsSuggestion";
import { ValueDriversSuggestion } from "@pages/Calculation/ValueDrivers/ValueDriversSuggestion";
import Products from "@pages/Products";

export const Routers = () => {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const userFromState = authState.user?.id && authState.user;
  const { loading, error, data } = useQuery(FETCH_USER, {
    skip: userFromState || !authState.isAuthenticated,
  });

  useEffect(() => {
    const user = userFromState || (data && data?.fetchUser);
    dispatch(userData(user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.fetchUser]);

  if (loading) return <LoadingComponent height="100" />;

  if (error)
    return (
      <ErrorComponent
        heading={error.message}
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const user = userFromState || data?.fetchUser;

  const commonRoutes = () => {
    return (
      <>
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Navigate to="/" />} />
        <Route path="/signup" element={<Navigate to="/" />} />
        <Route path="/admin" element={<Navigate to="/" />} />
        <Route path="/team-member" element={<TeamMembers />} />
        <Route path="/invites" element={<Invites />} />
        <Route path="/forgot-password" element={<Navigate to="/" />} />
        <Route path="/set-password" element={<Navigate to="/" />} />
        <Route path="/invitation" element={<InvitedUsers />} />
      </>
    );
  };
  return (
    <Routes>
      <Route element={<StaticPagesLayout />}>
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/invitation" element={<InvitedUsers />} />
      </Route>
      {authState.isAuthenticated ? (
        <>
          {user?.role === "admin" ? (
            /* If Admin */
            <Route element={<AdminLayout />}>
              <Route path="/admin-panel" element={<AdminPanel />} />
              <Route path="/users" element={<Users />} />
              <Route path="/survey-results" element={<SurveyResults />} />
              <Route
                path="/survey-results/:id"
                element={<SingleSurveyResult />}
              />
              <Route path="/queries" element={<Queries />} />
              <Route path="/" element={<Navigate to="/admin-panel" />} />
              {commonRoutes()}
            </Route>
          ) : (
            <>
              <Route element={<MainLayout />}>
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/products" element={<Products />} />
                {user?.subscription === true ||
                user?.isTeamMemberOfCurrentProduct ? (
                  <>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                  </>
                ) : (
                  <>
                    <Route
                      path="/dashboard"
                      element={<Navigate to="/pricing" />}
                    />
                    <Route path="/" element={<Navigate to="/pricing" />} />
                  </>
                )}
                {commonRoutes()}
              </Route>
              {
                <Route element={<CalculationLayout />}>
                  {user?.subscription === true ||
                  user?.isTeamMemberOfCurrentProduct ? (
                    <>
                      <Route
                        path="/product/:id/benefits"
                        element={<Benefits />}
                      />
                      <Route
                        path="/product/:id/benefitSuggestion"
                        element={<BenefitsSuggestion />}
                      />
                      <Route
                        path="/product/:id/valueDriverSuggestion"
                        element={<ValueDriversSuggestion />}
                      />
                      <Route
                        path="/product/:id/value-drivers"
                        element={<ValueDrivers />}
                      />
                      <Route
                        path="/product/:id/characteristics"
                        element={<Characteristics />}
                      />
                      <Route
                        path="/product/:id/segments"
                        element={<Segments />}
                      />
                      <Route path="/product/:id/weight" element={<Weight />} />
                      <Route path="/product/:id/price" element={<Price />} />
                    </>
                  ) : (
                    <Route
                      path="/product/:id/benefits"
                      element={<Navigate to="/pricing" />}
                    />
                  )}
                </Route>
              }
            </>
          )}
        </>
      ) : (
        <>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Route>
          <Route path="/admin" element={<AdminLogin />} />
        </>
      )}
    </Routes>
  );
};
