import { usersIcon, cog, info, phone, dashboarc } from "@images";

export const SidebarLinks = [
  {
    name: "Dashboard",
    link: "/admin-panel",
    icon: dashboarc,
  },
  {
    name: "Users",
    link: "/users",
    icon: usersIcon,
  },
  {
    name: "Survey Results",
    link: "/survey-results",
    icon: info,
  },
  {
    name: "Queries",
    link: "/queries",
    icon: phone,
  },
  {
    name: "Settings",
    link: "/profile",
    icon: cog,
  },
];

// export const SettingsNestedLinks = [
//   {
//     "name": "Profile",
//     "link": "/profile",
//     "icon": usersIcon
//   },
//   {
//     "name": "Edit Profile",
//     "link": "/edit-profile",
//     "icon": cog
//   },
//   {
//     "name": "Change Password",
//     "link": "/change-password",
//     "icon": cog
//   },
// ]
