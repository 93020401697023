import { AdminHeader, Sidebar } from "@components";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

export const AdminLayout = () => {
  const user = useSelector((state) => state.auth.user);
  const collapsed = useSelector((state) => state.sidebarCollapse.collapsed);
  return (
    <>
      <AdminHeader />
      <div className="d-flex h-100 mt-66">
        <Sidebar user={user} />
        <div
          className={`main-content w-100 ${collapsed ? "ms-92-c" : "ms-280-c"}`}
        >
          <div className="container-xxl py-5">
            <Outlet context={user} />
          </div>
        </div>
      </div>
    </>
  );
};
