import React from "react";
import { Form } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { FETCH_INVITED_MEMBERS } from "@graphql/queries";
import { LoadingComponent } from "@components/Loading";
import { DELETE_TEAM_MEMBER, REVOKE_ACCESS } from "@graphql/mutations";
import { SweetAlert } from "@services/SweetAlert";
import { TopBar } from "./TopBar";
import { useDispatch } from "react-redux";
import { userData } from "@store/authSlice";

export const TeamMembers = () => {
  const dispatch = useDispatch();
  const [
    removeMember,
    { loading: removeMemberLoading, error: removeMemberError },
  ] = useMutation(DELETE_TEAM_MEMBER, {
    onCompleted: () => {
      SweetAlert("Member Removed", "success");
    },
    refetchQueries: [{ query: FETCH_INVITED_MEMBERS }, "invitedUsers"],
    onError: (e) => {
      SweetAlert(e, "error");
    },
  });
  const handleDelete = async (authenticityResource) => {
    const { data } = await removeMember({
      variables: {
        allowToRemove: true,
        authenticityResource,
      },
    });
    dispatch(userData(data?.removeMember));
  };

  const { loading, error, data } = useQuery(FETCH_INVITED_MEMBERS, {
    variables: { category: "TEAM_MEMBERS", archive: false },
    fetchPolicy: "network-only",
    onError: (e) => {
      SweetAlert(e, "error");
    },
  });

  const [RevokeAccess] = useMutation(REVOKE_ACCESS, {
    refetchQueries: [{ query: FETCH_INVITED_MEMBERS }, "invitedUsers"],
  });

  if (loading) return <LoadingComponent height="100" />;

  const handleActiveChange = async (event, user) => {
    const { checked } = event.target;
    try {
      await RevokeAccess({
        variables: {
          userId: user.id,
          revokeAccess: !checked,
        },
      });
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  return (
    <>
      <div className="container-xl py-4">
        <TopBar />
        {error && <div className="text-danger mb-2">{error?.message}</div>}
        {removeMemberError && (
          <div className="text-danger mb-2">{removeMemberError?.message}</div>
        )}
        <table className="table user-table calculation-table my-2">
          <thead>
            <th>Email Address</th>
            <th>Access</th>
            <th>Actions</th>
          </thead>
          <tbody>
            {data?.invitedUsers.length ? (
              data?.invitedUsers?.map((users) => (
                <tr key={users?.id}>
                  <td>{users.email}</td>
                  <td>
                    <Form.Check
                      type="switch"
                      id={users?.id}
                      label={`${
                        users?.revokeProductAccess ? "Revoked" : "Granted"
                      }`}
                      defaultChecked={!users?.revokeProductAccess}
                      onChange={(e) => handleActiveChange(e, users)}
                    />
                  </td>

                  <td>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm me-2"
                      onClick={() => handleDelete(users.id.toString())}
                      disabled={removeMemberLoading}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center">
                  No Team Members found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
