import { useMutation } from "@apollo/client";
import { LOGIN } from "@graphql/mutations";
import { LoginPartial } from "./LoginPartial";

export const AdminLogin = () => {
  const [loginUser, { error, loading }] = useMutation(LOGIN);

  return (
    <div className="col-12 d-flex align-items-center justify-content-center order-1 order-lg-2 py-4 min-vh-100 bg-primary-100">
      <div className="mw-445 w-100 mx-auto">
        <div className="card">
          <div className="card-body">
            <h5 className="text-center mb-32 text-gray-700">Admin Login</h5>
            <LoginPartial
              mutation={loginUser}
              error={error}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
