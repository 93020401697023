import React from "react";

export const Badges = (props) => {
  const { status } = props;

  let color =
    status === "pending"
      ? { text: "custom-pending", background: "custom-pending-background" }
      : status === "approved"
      ? { text: "custom-approved", background: "custom-approved-background" }
      : status === "declined"
      ? { text: "custom-declined", background: "custom-declined-background" }
      : "";

  return (
    <span className={`badge ${color.text} rounded-pill ${color.background}`}>
      {status === "pending"
        ? "Pending"
        : status === "approved"
        ? "Approved"
        : status === "declined"
        ? "Declined"
        : ""}
    </span>
  );
};
