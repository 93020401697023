import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  stepNumber: 0,
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.stepNumber = state.stepNumber + 1;
    },
    previousStep: (state) => {
      state.stepNumber = state.stepNumber - 1;
    },
    resetSteps: (state) => {
      state.stepNumber = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { nextStep, previousStep, resetSteps } = onboardingSlice.actions;

export default onboardingSlice.reducer;
