import { useMutation } from "@apollo/client";
import { ModalComponent } from "@components/Modal";
import { NEW_UPDATE_PRODUCT } from "@graphql/mutations";
import { USER_PRODUCTS } from "@graphql/queries";
import { SweetAlert } from "@services/SweetAlert";
import React, { useState } from "react";

export default function EditProductModal({
  editShowModal,
  editCloseModal,
  activeProduct,
}) {
  const [variables, setVariables] = useState({
    name: "",
    currency: "USD",
  });

  const [productArguments, { loading, error }] = useMutation(
    NEW_UPDATE_PRODUCT,
    {
      variables: {
        id: activeProduct.id,
      },
      refetchQueries: [{ query: USER_PRODUCTS }],
    }
  );

  const handlaChange = (e) => {
    const { name, value } = e.target;
    setVariables((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await productArguments({
        variables: {
          ...variables,
        },
      });
      SweetAlert("Product updated successfully");
      editCloseModal();
    } catch (error) {
      SweetAlert(error);
    }
  };

  return (
    <div>
      <ModalComponent
        title="Edit Product"
        showModal={editShowModal}
        closeModal={editCloseModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        submitButtonText={
          loading ? (
            <>
              <i className="fas fa-spinner fa-spin me-2"></i>Update Product
            </>
          ) : (
            "Update Product"
          )
        }
        submitButtonType="primary"
        handleSubmit={handleSubmit}
      >
        {error && <div className="mb-2 text-danger">{error.message}</div>}
        <form>
          <label className="mb-1">Enter Name of Product</label>
          <input
            type="text"
            name="name"
            className="form-control mb-3"
            required
            onChange={handlaChange}
            defaultValue={activeProduct.name}
          />
        </form>
      </ModalComponent>
    </div>
  );
}
