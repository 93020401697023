import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "@graphql/mutations";
import { SettingsSidebar } from "./SettingsSidebar";
import { SweetAlert, HandleChange } from "@services";
import { useOutletContext } from "react-router-dom";
import { SubmitButton, InputField, TooltipComponent } from "@components";
import { useDispatch } from "react-redux";
import { userData } from "@store/authSlice";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ProfilePic } from "./ProfilePic";

export const EditProfile = () => {
  const user = useOutletContext();

  const [updateUser, { error, loading }] = useMutation(UPDATE_USER);

  const [variables, setVariables] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    setVariables({
      email: user?.email,
      fullName: user?.fullName,
      id: user?.id,
    });
  }, [user]);

  const handleSelectImage = (e) => {
    const file = e.target.files[0];
    setVariables({ ...variables, image: file });
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader.result);
    });
    fileReader.readAsDataURL(file);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = await updateUser({
        variables,
      });
      dispatch(userData(data.data.updateUser));
      SweetAlert("Your profile has been successfully updated.", "success");
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  return (
    <div className="container-xxl py-5">
      <div className="mw-730 w-100 mx-auto">
        <SettingsSidebar />
        <h6 className="text-gray-900 mb-32">Edit Profile</h6>
        <form onSubmit={handleFormSubmit}>
          {error && <div className="mb-2 text-danger">{error.message}</div>}

          <Row className="align-items-center mb-4">
            <Col sm={3} className="mb-2 mb-sm-0">
              <label className="fs-14">Profile Photo</label>
            </Col>
            <Col sm={9}>
              <ProfilePic
                previewImage={previewImage}
                imageUrl={user?.imageUrl}
                width={50}
                className="me-3"
              />
              <input
                accept="image/gif, image/jpeg, image/jpg, image/png"
                type="file"
                className="d-none"
                id="image"
                onChange={handleSelectImage}
              />
              <label htmlFor="image" className="btn btn-light btn-sm">
                Update
              </label>
            </Col>
          </Row>
          <Row className="align-items-center mb-4">
            <Col sm={3} className="mb-2 mb-sm-0">
              <label className="fs-14">Full Name</label>
            </Col>
            <Col sm={9}>
              <InputField
                onChange={(e) => HandleChange(e, variables, setVariables)}
                type="text"
                defaultValue={user?.fullName}
                required={false}
                name="fullName"
                placeholder="Enter your full name"
              />
            </Col>
          </Row>
          <Row className="align-items-center mb-5">
            <Col sm={3} className="mb-2 mb-sm-0">
              <label className="fs-14">Email Address</label>
            </Col>
            <Col sm={9}>
              <TooltipComponent
                overlayText={
                  user?.provider !== ""
                    ? "As you have been authenticated with Google, you are not authorized to modify the email associated with your account"
                    : undefined
                }
                placement="bottom"
              >
                <div>
                  <InputField
                    disabled={user?.provider !== ""}
                    onChange={(e) => HandleChange(e, variables, setVariables)}
                    type="email"
                    defaultValue={user?.email}
                    required={false}
                    name="email"
                    placeholder="Enter your email address"
                  />
                </div>
              </TooltipComponent>
            </Col>
          </Row>
          <SubmitButton loading={loading} size="lg" text="Update Profile" />
        </form>
      </div>
    </div>
  );
};
