import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { REGISTER } from "@graphql/mutations";
import { Authentication, HandleChange } from "@services";
import { SubmitButton, InputField } from "@components";
import { BelowAuthForm } from "./BelowAuthForm";
import { LoginWithGoogle } from "./LoginWithGoogle";

export const Signup = () => {
  const dispatch = useDispatch();
  const [registerUser, { error, loading }] = useMutation(REGISTER);
  const navigate = useNavigate();

  const [variables, setVariables] = useState({
    fullName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    rememberMe: true,
  });

  return (
    <>
      <h5 className="text-center mb-32 text-gray-700">
        Create Account to Get Started
      </h5>
      <form
        onSubmit={(e) =>
          Authentication(e, registerUser, variables, dispatch, navigate)
        }
      >
        {error && <div className="mb-2 text-danger">{error.message}</div>}
        <div className="mb-3">
          <InputField
            onChange={(e) => HandleChange(e, variables, setVariables)}
            type="text"
            required={true}
            name="fullName"
            placeholder="Enter your full name"
            text="Full Name"
          />
        </div>
        <div className="mb-3">
          <InputField
            onChange={(e) => HandleChange(e, variables, setVariables)}
            type="email"
            required={true}
            name="email"
            placeholder="Enter your email address"
            text="Email Address"
          />
        </div>
        <div className="mb-3">
          <InputField
            onChange={(e) => HandleChange(e, variables, setVariables)}
            type="password"
            required={true}
            name="password"
            placeholder="Enter a strong 8 digit password"
            text="Password"
          />
        </div>
        <div className="mb-40">
          <InputField
            onChange={(e) => HandleChange(e, variables, setVariables)}
            type="password"
            required={true}
            name="passwordConfirmation"
            placeholder="Re-enter your password"
            text="Confirm Password"
          />
        </div>
        <SubmitButton
          loading={loading}
          width="100"
          size="lg"
          text="Create Account"
        />
      </form>
      <LoginWithGoogle path="Signup" />
      <BelowAuthForm page="login" />
    </>
  );
};
