import React, { useState } from "react";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_BENEFIT,
  IMPORT_BENEFITS,
  UPDATE_BULK_BENEFITS,
} from "@graphql/mutations";
import {
  useNavigate,
  useParams,
  useOutletContext,
  Link,
} from "react-router-dom";
import { SweetAlert } from "@services";
import "../style.scss";
import { ALL_STEPS_COUNTER, EXPORT_BENEFITS, PRODUCT_BENEFITS } from "@graphql/queries";
import { LoadingComponent, SalesFunnelModal } from "@components";
import { CalculationSteps } from "../CalculationSteps";
import { spinner } from "@images";
import ExampleCSV from "../benefits-template.csv";
import { ImportModal } from "@components/ImportModal";
import { InputField } from "@components/FormFields";
import { Form } from "react-bootstrap";
import { BenefitsSuggestionTableFields } from "./BenefitsSuggestionTableFields";
import { BenefitsSuggestionTableHeadings } from "./BenefitsSuggestionTableHeadings";
import { exportcsv } from "src/utils/exportcsv";
import { TooltipComponent } from "@components/Tooltip";
import { ButtonComponent } from "@components/Buttons";

export const BenefitsSuggestion = () => {
  const user = useOutletContext()[1];
  const plan = user.subscriptionPlan.title;
  const currency = user?.defaultProductCurrency.iso_code;
  const isSubscribed = user.subscription;
  const benefitsLimit = user.subscriptionPlan?.features?.benefits?.limit;
  const isTeamMember = user.isTeamMemberOfCurrentProduct;
  const benefitsCount = useOutletContext()[0].benefitsCount;
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    variables: [],
  });
  const [benefitsLoading, setBenefitsLoading] = useState(false);

  const [valuesForStatus, setValuesForStatus] = useState({
    variables2: [{}],
  });

  const [exportBenefits, { loading: eloading }] = useLazyQuery(
    EXPORT_BENEFITS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        exportcsv(data.exportBenefits.exportCsvData);
      },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
    }
  );

  const params = useParams();

  const [createBenefit, { loading: cloading }] = useMutation(CREATE_BENEFIT, {
    variables: { productId: params?.id },
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
    refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
  });

  const onCompletedQuery = (data) => {
    const benefits = data?.productBenefits;
    if (benefits.length === 0) {
      setBenefitsLoading(false);
    } else {
      setBenefitsLoading(true);
      const variables = [];
      const variables2 = [];
      for (let i = 0; i < benefits.length; i++) {
        const benefit = benefits[i];
        const variable = {
          id: benefit?.id,
          name: benefit?.name,
          description: benefit?.description,
          verified: benefit?.verified,
          quantifiable: benefit?.quantifiable,
          tangible: benefit?.tangible,
          value: benefit?.value,
          unitOfMeasure: benefit?.unitOfMeasure,
        };
        const variable2 = {
          status: benefit?.status,
        };
        variables.push(variable);
        variables2.push(variable2);
      }
      setInitialValues({
        variables,
      });
      setValuesForStatus({
        variables2,
      });
    }
  };

  const { data: productBefefitsData, loading } = useQuery(PRODUCT_BENEFITS, {
    variables: { productId: params?.id, isMainView: false },
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      onCompletedQuery(data);
    },
  });

  const [productBenefits, { refetch }] = useLazyQuery(PRODUCT_BENEFITS, {
    variables: { productId: params?.id, isMainView: false },
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      onCompletedQuery(data);
    },
  });

  const [importErrors, setImportErrors] = useState();
  const [importBenefits, { loading: iloading, error: ierror }] = useMutation(
    IMPORT_BENEFITS,
    {
      variables: { productId: params?.id },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
        productBenefits();
      },
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    }
  );

  const importFile = async (file) => {
    try {
      const { data } = await importBenefits({
        variables: { file },
      });
      productBenefits();
      if (data?.importBenefits?.success === true) {
        setImportErrors(false);
        SweetAlert("Benefits imported successfully.", "success");
      } else {
        setImportErrors(data?.importBenefits?.errors);
      }
    } catch (e) {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
    }
  };

  const [updateBulkBenefits, { loading: bulkLoading, error: bulkError }] =
    useMutation(UPDATE_BULK_BENEFITS, {
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    });

  if (loading) return <LoadingComponent height="calculation" />;

  const errorsShow = () => {
    const els = document.querySelectorAll(".text-danger");
    for (let x = 0; x < els.length; x++) els[x].style.display = "block";
    const list = document.querySelectorAll(".border-red");
    for (let l = 0; l < list.length; l++)
      list[l].classList.add("border-danger");
  };

  const inputFieldSchema = Yup.object().shape({
    id: Yup.string(),
    name: isTeamMember && Yup.string().required("Name is required"),
    description: Yup.string(),
    verified: Yup.boolean(),
    quantifiable: Yup.boolean(),
    tangible: Yup.boolean(),
    value:
      isTeamMember &&
      Yup.number()
        .required("Value can't be empty")
        .test("Is positive?", "Value can't be negative", (value) => value >= 0),
    unitOfMeasure:
      isTeamMember &&
      Yup.string().when("quantifiable", {
        is: true,
        then: (uom) => uom.required("UoM is required"),
      }),
  });

  const handleNext = async (values, errors) => {
    errorsShow();

    if (!errors?.variables) {
      try {
        await updateBulkBenefits({
          variables: {
            input: { benefitsAttributes: values?.variables },
          },
        });
        navigate(`/product/${params?.id}/value-drivers`);
      } catch (e) {
        SweetAlert(e, "error");
        console.log(e);
      }
    }
  };

  const handlePlan = async (values) => {
    try {
      await updateBulkBenefits({
        variables: { input: { benefitsAttributes: values?.variables } },
      });
      navigate("/dashboard?plan=true");
    } catch (e) {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
    }
  };

  const instructions = [
    <div>
      <Link
        to={ExampleCSV}
        download="Benefits-Template.csv"
        rel="noreferrer"
        target="_blank"
        className="text-decoration-underline"
      >
        Download
      </Link>
      the template
    </div>,
    "Don't change or add more header names.",
    "Write the required Benefit name and avoid duplicates.",
    "Use only 'Yes' or 'No' for Verified Assumption, Quantifiable, and Tangible fields.",
    "If Quantifiable is 'Yes', enter a numerical value in the field—don't leave it empty or use other characters.",
    "Don't refresh the page while importing the data.",
  ];

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        variables: Yup.array().of(inputFieldSchema),
      })}
    >
      {({ values, handleSubmit, errors, validateForm }) => (
        <form>
          <div className="border-bottom">
            <div className="container-fluid">
              <div className="stepper d-flex justify-content-center align-items-center">
                <CalculationSteps />
                <div className="ms-4 ms-xl-5 d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-light btn-sm me-2"
                    disabled={true}
                  >
                    Previous
                  </button>
                  <button
                    disabled={bulkLoading || benefitsCount === 0}
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => handleNext(values, errors)}
                  >
                    Next
                  </button>
                  {bulkLoading && (
                    <i className="fa-solid fa-spinner fa-spin ms-2"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
          {errors?.variables && (
            <div className="alert alert-warning text-danger text-center hide">
              There are some errors in the form. Kindly fill the form properly
              without errors
            </div>
          )}
          <div className="container-xxl py-4">
            <div className="d-flex align-items-center justify-content-between mb-3 gap-2">
              <h6>Benefits</h6>
              <div>
                <div className="d-flex align-items-center ">
                  <Link
                    to={`/product/${params?.id}/benefits`}
                    className=" text-black me-4"
                  >
                    Main View
                  </Link>
                  <Link to={`/product/${params?.id}/benefits`}>
                    <Form.Check
                      type="switch"
                      id="one"
                      checked={true}
                      className="pe-none"
                      readOnly
                    />
                  </Link>
                  <Link to={`#`} className=" text-black me-4">
                    Suggestion View
                  </Link>
                  {plan === "lead" ? (
                    benefitsCount === 0 ? (
                      <TooltipComponent overlayText="Add at-least one value driver to export">
                        <div className="d-inline-block">
                          <ButtonComponent
                            type="outline-primary"
                            size="sm"
                            icon="download"
                            position="start"
                            disabled={true}
                            iconType="solid"
                            text="Export"
                          />
                        </div>
                      </TooltipComponent>
                    ) : (
                      <ButtonComponent
                        type="outline-primary"
                        size="sm"
                        icon={
                          eloading ? "spinner fa-spin" : "download"
                        }
                        position="start"
                        iconType="solid"
                        text="Export"
                        onClick={() => exportBenefits()}
                      />
                    )
                  
                  ) : (
                    <SalesFunnelModal className="btn btn-outline-primary btn-sm">
                      <i className="fas fa-download me-2"></i>Export
                    </SalesFunnelModal>
                  )}
                </div>
              </div>
            </div>
            <FieldArray name="variables">
              {({ push, remove }) => {
                return (
                  <div className="table-responsive">
                    <table className="table user-table calculation-table mb-0">
                      <BenefitsSuggestionTableHeadings currency={currency} />
                      <tbody>
                        <BenefitsSuggestionTableFields
                          data={productBefefitsData}
                          remove={remove}
                          values={values}
                          errors={errors}
                          status={valuesForStatus}
                        />
                        {/* {productBefefitsData?.productBenefits.length === 0 && (
                          <tr>
                            <td className="text-center" colSpan={9}>
                              No benefits present.
                            </td>
                          </tr>
                        )} */}
                        {cloading && (
                          <tr className="text-center spinner-table-loading">
                            <td colSpan={8}>
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        )}
                        {isTeamMember ? (
                          <tr className="row-action-btn">
                            <td colSpan={12} className="p-0">
                              {benefitsCount === benefitsLimit &&
                              isSubscribed === false ? (
                                <SalesFunnelModal
                                  loading={bulkLoading}
                                  error={bulkError}
                                  handlePlan={() => handlePlan(values)}
                                  className="btn w-100 text-start text-primary border-0 rounded-0 px-3 py-4 cursor-pointer"
                                >
                                  + Add Benefit
                                </SalesFunnelModal>
                              ) : (
                                <button
                                  type="button"
                                  className="btn w-100 text-start text-primary border-0 rounded-0 px-3 py-4 cursor-pointer"
                                  onClick={async () => {
                                    const validationErrors =
                                      await validateForm();
                                    if (
                                      Object.keys(validationErrors).length !== 0
                                    ) {
                                      handleSubmit();
                                      errorsShow();
                                    } else {
                                      const addBenefit = createBenefit();
                                      addBenefit
                                        .then((result) => {
                                          const id =
                                            result.data.createBenefit.id;
                                          push({
                                            id: id,
                                            name: "",
                                            description: "",
                                            verified: false,
                                            quantifiable: false,
                                            tangible: false,
                                            value: 0,
                                            unitOfMeasure: "",
                                          });
                                        })
                                        .catch((error) => {
                                          // Handle any errors
                                        });
                                    }
                                  }}
                                >
                                  + Add Benefit
                                </button>
                              )}
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                );
              }}
            </FieldArray>
          </div>
        </form>
      )}
    </Formik>
  );
};
