export const InputField = (props) => {
  const { name, text, type, fieldtype, customclass } = props;
  const isCheck =
    fieldtype === "checkbox" || fieldtype === "radio" || fieldtype === "switch";
  return (
    <div
      className={`${isCheck ? "form-check" : ""}${
        fieldtype === "switch" ? " form-switch" : ""
      }`}
    >
      {!isCheck && text && (
        <label htmlFor={name} className="form-label">
          {text}
        </label>
      )}
      <input
        className={`${customclass ? customclass : ""} ${
          isCheck ? "form-check-input" : "form-control"
        }`}
        minLength={type === "password" ? 6 : null}
        id={name}
        {...props}
      />
      {isCheck && text && (
        <label htmlFor={name} className="form-check-label">
          {text}
        </label>
      )}
    </div>
  );
};

export const SelectField = (props) => {
  const { name, text, children } = props;

  return (
    <>
      {text && (
        <label htmlFor={name} className="form-label">
          {text}
        </label>
      )}
      <select className="form-select" name={name} id={name} {...props}>
        {children}
      </select>
    </>
  );
};
