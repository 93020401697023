import { useMutation } from "@apollo/client";
import { SharedTableHeadings } from "../SharedTableHeadings";
import { UPDATE_PRODUCT } from "@graphql/mutations";
import { SweetAlert } from "@services";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userData } from "@store/authSlice";

export const BenefitsTableHeadings = (props) => {
  const { currency } = props;
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const params = useParams();
  const dispatch = useDispatch();
  const handleCurrency = async (e) => {
    try {
      const data = await updateProduct({
        variables: {
          id: params?.id,
          currency: e.target.value,
        },
      });
      dispatch(userData(data.data.updateProduct.user));
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  const Headings = [
    {
      text: "Id",
    },
    {
      text: "Benefits",
      tooltip: "List all benefits your solution offers to your customers",
      width: "w-227-p",
    },
    {
      text: "Assumptions",
      tooltip:
        "Assumptions are verified or unverified suppositions you make about what exactly constitutes the value offered to your customers",
      width: "w-227-p",
    },
    {
      text: "Verified Assumption",
      tooltip:
        "Your customers indicate that your assumptions on the benefits you provide are valid? Toggle yes/no",
      width: "w-160-p",
    },
    {
      text: "Quantifiable",
      tooltip:
        "You are able to derive a monetary value from your benefit? Toggle yes/no",
      width: "w-120-p",
    },
    {
      text: "Tangible",
      tooltip:
        "The benefit is directly measurable, e.g. increase in cash flow (tangible). The benefit is not directly measurable, e.g. increase in customer satisfaction (intangible)",
      width: "w-100-p",
    },
    {
      text: (
        <div className="currency-select">
          <select value={currency} onChange={(e) => handleCurrency(e)}>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
        </div>
      ),
      tooltip: "Click to choose currency",
      width: "w-120-p",
    },
    {
      text: "UoM",
      tooltip:
        "Unit of Measure, e.g. EUR or USD per month, or per year. Increase in NPS score,...",
    },
  ];
  return <SharedTableHeadings headings={Headings} />;
};
