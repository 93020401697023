export const onboardingList = [
  {
    heading: "Welcome to GetPricingPower.com!",
  },
  {
    heading: "Which market are you active in?",
    inputFields: [
      {
        name: "b2b",
        text: "B2B",
      },
      {
        name: "b2c",
        text: "B2C",
      },
      {
        name: "b2b2c",
        text: "B2B2C",
      },
      {
        name: "other0",
        text: "Other (please specify)",
      },
    ],
  },
  {
    heading: "Which sales channels do you primarily use to grow your business?",
    inputFields: [
      {
        name: "direct_sales",
        text: "Direct sales (via account managers)",
      },
      {
        name: "indirect_sales",
        text: "Indirect sales (via distributors)",
      },
      {
        name: "online_sales",
        text: "Online sales",
      },
      {
        name: "other1",
        text: "Other (please specify)",
      },
    ],
  },
  {
    heading:
      "At which geographical level do you require your pricing to be reviewed? Select most appropriate?",
    inputFields: [
      {
        name: "regional",
        text: "Regional",
      },
      {
        name: "national",
        text: "National",
      },
      {
        name: "european_union",
        text: "European Union",
      },
      {
        name: "global",
        text: "Global",
      },
    ],
  },
  {
    heading: "Which stage is your project or company in?",
    inputFields: [
      {
        name: "pre-seed",
        text: "Idea - (pre-seed stage, no revenue yet)",
      },
      {
        name: "seed-stage",
        text: "Startup (Seed Stage)",
      },
      {
        name: "seriesA",
        text: "Startup (Series A)",
      },
      {
        name: "traditional-business",
        text: "Starter (traditional business)",
      },
      {
        name: "sme",
        text: "SME (stable revenue stream)",
      },
      {
        name: "erb",
        text: "Enterprise Business",
      },
    ],
  },
  {
    heading: "What is your most important offer to your customers?",
    inputFields: [
      {
        name: "products",
        text: "Products",
      },
      {
        name: "Services",
        text: "Services",
      },
      {
        name: "saas",
        text: "Software (SaaS) or Apps ",
      },
      {
        name: "other2",
        text: "Other (please specify)",
      },
    ],
  },
  {
    heading:
      "What is your company's principal objective for the next 2-3 years?",
    inputFields: [
      {
        name: "increase_share",
        text: "Increase market share",
      },
      {
        name: "increase_revenue",
        text: "Increase (recurring) revenue",
      },
      {
        name: "increase_margin",
        text: "Increase absolute margin",
      },
      {
        name: "increase_margin_revenue",
        text: "Increase margin in percent of revenue",
      },
      {
        name: "maximize_profit",
        text: "Maximize profit",
      },
      {
        name: "not_sure",
        text: "I’m not sure",
      },
    ],
  },

  {
    heading:
      "Are you building a pricing strategy for a new or an existing product/(digital)service?",
    inputFields: [
      {
        name: "new_product",
        text: "New Product",
      },
      {
        name: "existing_product",
        text: "Existing Product",
      },
    ],
  },
  {
    heading: "How many products need to have their pricing reviewed?",
    inputFields: [
      {
        name: "1product",
        text: "1 product",
      },
      {
        name: "10product",
        text: "A portfolio of less than 10 products",
      },
      {
        name: "tenPlus",
        text: "A portfolio of between 10 to 50 products",
      },
      {
        name: "fiftyPlus",
        text: "A portfolio of more than 50 products",
      },
    ],
  },
  {
    heading: "Your company's current financial performance is:",
    inputFields: [
      {
        name: "above_expectations",
        text: "Above expectations",
      },
      {
        name: "meets_expectations",
        text: "Meets expectations",
      },
      {
        name: "below_expectations",
        text: "Below expectations",
      },
    ],
  },

  {
    heading: "When was the last time you reviewed your price?",
    inputFields: [
      {
        name: "none",
        text: "I don't have a pricing structure yet ",
      },
      {
        name: "not reviewed",
        text: "I never reviewed my price",
      },
      {
        name: "monthAgo",
        text: "Less than three months ago",
      },
      {
        name: "threetosix",
        text: "Between 3 and 6 months ago",
      },
      {
        name: "six",
        text: "Between 6 months and 1 year ago",
      },
      {
        name: "yearAgo",
        text: "More than 1 year ago",
      },
      {
        name: "other3",
        text: "Other (please specify)",
      },
    ],
  },

  {
    heading:
      "Do you have clear insight in your company's fixed and variable costs?",
    inputFields: [
      {
        name: "yes",
        text: "Yes",
      },
      {
        name: "no",
        text: "No",
      },
    ],
  },
  {
    heading: (
      <div>
        To your estimation how innovative is your business?{" "}
        <span className="fs-16">
          (0 -> traditional business & 5 -> very innovative business)
        </span>
      </div>
    ),
    inputFields: [
      {
        name: "0a",
        text: "0",
      },
      {
        name: "1a",
        text: "1",
      },
      {
        name: "2a",
        text: "2",
      },
      {
        name: "3a",
        text: "3",
      },
      {
        name: "4a",
        text: "4",
      },
      {
        name: "5a",
        text: "5",
      },
    ],
  },
  {
    heading: "How do you want to position your company against competitors?",
    inputFields: [
      {
        name: "niche_player",
        text: "Niche player: small market share, segmentation other firms overlook or ignore",
      },
      {
        name: "challenger",
        text: "Challenger: low to medium market share, challenges the leader",
      },
      {
        name: "follower",
        text: "Follower: no offensive posture against the leader",
      },
      {
        name: "market_leader",
        text: "Market leader: largest market share",
      },
    ],
  },
  {
    heading: (
      <div>
        To your estimate, how likely will your customer buy from your competitor
        if you increase price?
        <span className="fs-16">
          (0 -> unlikely to buy from competitors & 5 -> very likely to buy from
          competitor)
        </span>
      </div>
    ),
    inputFields: [
      {
        name: "0",
        text: "0",
      },
      {
        name: "1",
        text: "1",
      },
      {
        name: "2",
        text: "2",
      },
      {
        name: "3",
        text: "3",
      },
      {
        name: "4",
        text: "4",
      },
      {
        name: "5",
        text: "5",
      },
    ],
  },
  {
    heading: "Schedule workshop",
  },
];
