import React, { useState } from "react";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_CHARACTERISTIC,
  BULK_ACTION_ON_CHARACTERISTICS,
} from "@graphql/mutations";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { CharacteristicsTableHeadings } from "./CharacteristicsTableHeadings";
import { CharacteristicsTableFields } from "./CharacteristicsTableFields";
import "../style.scss";
import {
  ALL_STEPS_COUNTER,
  CUSTOMER_CHARACTERISTICS,
  EXPORT_CUSTOMER_CHARACTERISTICS,
} from "@graphql/queries";
import { SweetAlert } from "@services";
import { CalculationSteps } from "../CalculationSteps";
import { LoadingComponent, ButtonComponent } from "@components";
import { exportcsv } from "src/utils/exportcsv";
import { TooltipComponent } from "@components/Tooltip";
import { SalesFunnelModal } from "@components/SalesFunnelModal";

export const Characteristics = () => {
  const user = useOutletContext()[1];
  const plan = user.subscriptionPlan.title;
  const characteristicsCount = useOutletContext()[0].characteristicsCount;
  const isTeamMember = user.isTeamMemberOfCurrentProduct;
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    variables: [
      {
        id: "",
        name: "",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: "",
        value6: "",
        value7: "",
        value8: "",
      },
    ],
  });

  const params = useParams();

  const [createCharacteristic, { loading: cloading }] = useMutation(
    CREATE_CHARACTERISTIC,
    {
      variables: { productId: params?.id },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    }
  );

  const [exportCustomerCharacteristics, { loading: eloading }] = useLazyQuery(
    EXPORT_CUSTOMER_CHARACTERISTICS,
    {
      variables: { productId: params?.id },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        exportcsv(data.exportCustomerCharacteristics.exportCsvData);
      },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
    }
  );

  const { loading } = useQuery(CUSTOMER_CHARACTERISTICS, {
    variables: { productId: params?.id },
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const characteristics = data?.customerCharacteristics;
      if (characteristics.length === 0) {
        const addCharacteristic = createCharacteristic();
        addCharacteristic
          .then((result) => {
            const id = result.data.createCharacteristic.id;
            setInitialValues({
              variables: [
                {
                  id: id,
                  name: "",
                  value1: "",
                  value2: "",
                  value3: "",
                  value4: "",
                  value5: "",
                  value6: "",
                  value7: "",
                  value8: "",
                },
              ],
            });
          })
          .catch((error) => {
            // Handle any errors
          });
      } else {
        const variables = [];

        for (let i = 0; i < characteristics.length; i++) {
          const characteristic = characteristics[i];
          const variable = {
            id: characteristic.id,
            name: characteristic.name,
            value1: characteristic?.customerValues[0]?.value,
            value2: characteristic?.customerValues[1]?.value,
            value3: characteristic?.customerValues[2]?.value,
            value4: characteristic?.customerValues[3]?.value,
            value5: characteristic?.customerValues[4]?.value,
            value6: characteristic?.customerValues[5]?.value,
            value7: characteristic?.customerValues[6]?.value,
            value8: characteristic?.customerValues[7]?.value,
          };
          variables.push(variable);
        }
        setInitialValues({
          variables,
        });
      }
    },
  });

  const [bulkActionsOnCharacteristics, { loading: uloading, error }] =
    useMutation(BULK_ACTION_ON_CHARACTERISTICS, {
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    });

  const errorsShow = () => {
    const els = document.querySelectorAll(".text-danger");
    for (let x = 0; x < els.length; x++) els[x].style.display = "block";
    const list = document.querySelectorAll(".border-red");
    for (let l = 0; l < list.length; l++)
      list[l].classList.add("border-danger");
  };

  const inputFieldSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().required("Name is required"),
    value1: Yup.string().required("Required Field"),
    value2: Yup.string(),
    value3: Yup.string(),
    value4: Yup.string(),
    value5: Yup.string(),
    value6: Yup.string(),
    value7: Yup.string(),
    value8: Yup.string(),
  });

  const bulkMutation = async (link, values) => {
    if (values?.variables === undefined || values?.variables?.length === 0) {
      navigate(`/product/${params?.id}/${link}`);
    } else {
      const variables = values?.variables.map((item) => {
        const valuesAttributes = [];
        for (let v = 1; v <= 8; v++) {
          if (item?.[`value${v}`]) {
            valuesAttributes.push({
              position: v,
              value: item?.[`value${v}`],
            });
          } else {
            valuesAttributes.push({
              position: v,
              value: "",
            });
          }
        }

        return {
          id: item?.id,
          name: item?.name,
          valuesAttributes: valuesAttributes,
        };
      });
      try {
        await bulkActionsOnCharacteristics({
          variables: {
            input: { characteristicsAttributes: variables },
          },
        });
        navigate(`/product/${params?.id}/${link}`);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleButtons = (values, errors, e, link) => {
    if (e?.target?.id === "next" && errors?.variables) {
      errorsShow();
    } else {
      bulkMutation(link, values);
    }
  };

  if (loading) return <LoadingComponent height="calculation" />;

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        variables: Yup.array().of(inputFieldSchema),
      })}
    >
      {({ values, handleSubmit, errors, validateForm }) => (
        <form>
          <div className="border-bottom">
            <div className="container-fluid">
              <div className="stepper d-flex justify-content-center align-items-center">
                <CalculationSteps
                  bulkMutation={bulkMutation}
                  variables={values}
                />
                <div className="ms-4 ms-xl-5 d-flex align-items-center">
                  <button
                    disabled={uloading}
                    type="button"
                    id="previous"
                    className="btn btn-light btn-sm me-2"
                    onClick={(e) =>
                      handleButtons(values, errors, e, "value-drivers")
                    }
                  >
                    Previous
                  </button>
                  <button
                    disabled={uloading || characteristicsCount === 0}
                    type="button"
                    id="next"
                    className="btn btn-primary btn-sm"
                    onClick={(e) =>
                      handleButtons(values, errors, e, "segments")
                    }
                  >
                    Next
                  </button>
                  {uloading && (
                    <i className="fa-solid fa-spinner fa-spin ms-2"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
          {errors?.variables && (
            <div className="alert alert-warning text-danger text-center hide">
              There are some errors in the form. Kindly fill the form properly
              without errors
            </div>
          )}
          {error && (
            <div className="alert alert-warning text-danger text-center">
              {error?.message}
            </div>
          )}
          <div className="container-xxl py-4 characteristics-section-space">
            <div className="d-flex align-items-center justify-content-between mb-3 gap-2">
              <h6>Customer Characteristics</h6>
              {!isTeamMember &&
                (plan === "lead" ? (
                  characteristicsCount === 0 ? (
                    <TooltipComponent overlayText="Add at-least one characteristic to export">
                      <div className="d-inline-block">
                        <ButtonComponent
                          type="outline-primary"
                          size="sm"
                          icon="download"
                          position="start"
                          disabled={true}
                          iconType="solid"
                          text="Export"
                        />
                      </div>
                    </TooltipComponent>
                  ) : (
                    <ButtonComponent
                      type="outline-primary"
                      size="sm"
                      icon={eloading ? "spinner fa-spin" : "download"}
                      position="start"
                      iconType="solid"
                      text="Export"
                      onClick={() => exportCustomerCharacteristics()}
                    />
                  )
                ) : (
                  <SalesFunnelModal className="btn btn-outline-primary btn-sm">
                    <i className="fas fa-download me-2"></i>Export
                  </SalesFunnelModal>
                ))}
            </div>
            <FieldArray name="variables">
              {({ push, remove }) => {
                return (
                  <div className="table-responsive">
                    <table className="table user-table calculation-table mb-0">
                      <CharacteristicsTableHeadings />
                      <tbody className={isTeamMember ? "pe-none " : ""}>
                        <CharacteristicsTableFields
                          remove={remove}
                          values={values}
                          errors={errors}
                        />
                        <tr className="row-action-btn">
                          <td
                            colSpan={9}
                            className="px-3 py-4 cursor-pointer"
                            onClick={async () => {
                              const validationErrors = await validateForm();
                              if (Object.keys(validationErrors).length !== 0) {
                                handleSubmit();
                                errorsShow();
                              } else {
                                const addCharacteristic =
                                  createCharacteristic();
                                addCharacteristic
                                  .then((result) => {
                                    const id =
                                      result.data.createCharacteristic.id;
                                    push({
                                      id: id,
                                      name: "",
                                      value1: "",
                                      value2: "",
                                      value3: "",
                                      value4: "",
                                      value5: "",
                                      value6: "",
                                      value7: "",
                                      value8: "",
                                    });
                                    // Do something with the extracted data
                                  })
                                  .catch((error) => {
                                    // Handle any errors
                                  });
                              }
                            }}
                          >
                            + Add Characteristic
                          </td>
                        </tr>
                        {cloading && (
                          <tr className="text-center spinner-table-loading">
                            <td colSpan={8}>
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                );
              }}
            </FieldArray>
          </div>
        </form>
      )}
    </Formik>
  );
};
