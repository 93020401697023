import { useMutation } from "@apollo/client";
import { ButtonComponent } from "@components/Buttons";
import { ModalComponent } from "@components/Modal";
import { DESTROY_PRODUCT, SWITCH_PRODUCT } from "@graphql/mutations";
import { edit, trash } from "@images/index";
import { SweetAlert } from "@services/SweetAlert";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EditProductModal from "./EditProductModal";
import { useDispatch } from "react-redux";
import { userData } from "@store/authSlice";

export default function ProductsTable({ data, onRefetch }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeProduct, setActiveProduct] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [editshowModal, setEditShowModal] = useState(false);

  const [destroyProduct] = useMutation(DESTROY_PRODUCT);
  const [switchProduct, { loading: switchLoading, error: switchError }] =
    useMutation(SWITCH_PRODUCT);

  const handleSwitchProduct = async (productId) => {
    try {
      const { data } = await switchProduct({ variables: { productId } });
      dispatch(userData(data.switchProduct));
      navigate(`/`);
    } catch (error) {
      console.error("Error switching product:", error);
    }
  };

  const handleDeleteConfirmation = async (id) => {
    try {
      await destroyProduct({ variables: { id } });
      SweetAlert("Product deleted successfully");
      onRefetch();
      handleCloseModal();
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  const promptDelete = (product) => {
    setActiveProduct(product);
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const promptUpdate = (product) => {
    setActiveProduct(product);
    handleOpenEditModal();
  };

  const handleOpenEditModal = () => {
    setEditShowModal(true);
  };
  const handleCloseEditModal = () => {
    setEditShowModal(false);
  };

  return (
    <>
      {switchError && (
        <div className="mb-2 text-danger">{switchError.message}</div>
      )}
      <div className="row">
        {data?.map((product, index) => (
          <div className="col-lg-6 mb-3" key={index}>
            <div className="card border p-3 h-100 d-flex justify-content-between flex-column">
              <div>
                <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
                  <p className="fs-20 fw-semibold">{product.name}</p>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    {product?.isCurrentProduct && (
                      <span className="badge-product fs-12 text-center">
                        Current Product
                      </span>
                    )}
                    <span
                      className={`badge custom-${
                        product?.role === "Owner" ? "approved" : "pending"
                      } rounded-pill custom-${
                        product?.role === "Owner" ? "approved" : "pending"
                      }-background`}
                    >
                      {product?.role}
                    </span>
                  </div>
                </div>
                <div className="row gx-3">
                  <div className="col-12 col-sm-4 col-md-4">
                    <div className="card p-2 bg-primary-50 mb-3">
                      <p className="mb-2 text-gray-500">Idea Stage</p>
                      <h6>{product.specification.idealStageCount}</h6>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4 col-md-4">
                    <div className="card p-2 bg-primary-50 mb-3">
                      <p className="mb-2 text-gray-500">In Development</p>
                      <h6>{product?.specification?.inDevelopmentCount}</h6>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4 col-md-4">
                    <div className="card p-2 bg-primary-50 mb-3">
                      <p className="mb-2 text-gray-500">Functional</p>
                      <h6>{product?.specification?.functionalCount}</h6>
                    </div>
                  </div>
                </div>
                <div className="row gx-1 gx-sm-20 mb-3">
                  <div className="col-4">
                    <div className="dashboard-badge success">
                      {product?.specification?.coreCount} Core
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="dashboard-badge primary">
                      {product?.specification?.nonCoreCount} Non Core
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="dashboard-badge gray">
                      {product?.specification?.hygienicCount} Hygienic
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <p className="fs-12 text-gray-500">Segment name</p>
                  </div>
                  <div className="col-4">
                    <p className="fs-12 text-gray-500 text-end">
                      Value Offered
                    </p>
                  </div>
                  <div className="col-4">
                    <p className="fs-12 text-gray-500 text-end">
                      Proposed Price
                    </p>
                  </div>
                </div>
              </div>
              {product?.specification?.segmentsSpec?.map((info, index) => (
                <div className="border-bottom" key={index}>
                  <div className="row">
                    <div className="col-4 py-2">
                      {info.segment_name || `Segment ${index + 1}`}
                    </div>
                    <div className="col-4 py-2 text-end">
                      ${info.total_price}
                    </div>
                    <div className="col-4 py-2 text-end">
                      ${info.psychological_pricing}
                    </div>
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-end align-items-center gap-3 mt-3">
                {product?.role === "Owner" ? (
                  <>
                    <img
                      src={edit}
                      alt="edit"
                      className="cursor-pointer"
                      onClick={() => promptUpdate(product)}
                    />
                    {!product?.isCurrentProduct ? (
                      <img
                        src={trash}
                        alt="trash"
                        className="cursor-pointer"
                        onClick={() => promptDelete(product)}
                      />
                    ) : (
                      <Link to="/">
                        <i className="far fa-eye text-gray-500"></i>
                      </Link>
                    )}
                  </>
                ) : (
                  product?.isCurrentProduct && (
                    <Link to="/">
                      <i className="far fa-eye text-gray-500"></i>
                    </Link>
                  )
                )}
                {product?.isCurrentProduct ? (
                  <Link
                    to={`/product/${product?.slug}/benefits`}
                    className="btn btn-primary"
                  >
                    Modify Product
                  </Link>
                ) : (
                  <ButtonComponent
                    disabled={switchLoading}
                    type="primary"
                    text="Switch Product"
                    onClick={() => handleSwitchProduct(product.id)}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <ModalComponent
        showModal={showModal}
        closeModal={handleCloseModal}
        title={
          <div className="d-flex align-items-center gap-12">Delete product</div>
        }
        children={
          <p className="fs-14 text-gray-600 mb-3">
            Are you sure you want to delete this product from your list?
          </p>
        }
        submitButtonText="Delete"
        handleSubmit={() => handleDeleteConfirmation(activeProduct.id)}
        closeButtonText="Cancel"
        size="md"
        submitButtonType="danger"
        closeButtonType="light"
      />
      <EditProductModal
        editShowModal={editshowModal}
        editCloseModal={handleCloseEditModal}
        activeProduct={activeProduct || {}}
      />
    </>
  );
}
