import { ButtonComponent } from "@components";
import { Link, useSearchParams } from "react-router-dom";

export const BelowAuthForm = (props) => {
  const { page } = props;
  const searchParams = useSearchParams();
  const plan = searchParams[0].get("plan");

  return page === "forgot-password" || page === "reset-password" ? (
    <ButtonComponent
      link={`/login${plan ? `?plan=${plan}` : ""}`}
      size="lg"
      type="text"
      text="Back to login"
      width="100"
      customClass="mt-3"
    />
  ) : (
    <div className="text-center mt-32">
      <span className="text-gray-400">
        {page === "signup"
          ? "Don't have an account? "
          : page === "login"
          ? "Already have an account? "
          : ""}
      </span>
      <Link to={`/${page}${plan ? `?plan=${plan}` : ""}`}>
        {page.charAt(0).toUpperCase() + page.slice(1)}
      </Link>
    </div>
  );
};
