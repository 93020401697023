import { useMutation } from "@apollo/client";
import { LOGIN } from "@graphql/mutations";
import { LoginPartial } from "./LoginPartial";
import { BelowAuthForm } from "./BelowAuthForm";
import { LoginWithGoogle } from "./LoginWithGoogle";

export const Login = () => {
  const [loginUser, { error, loading }] = useMutation(LOGIN);

  return (
    <>
      <h5 className="text-center mb-32 text-gray-700">Welcome Back</h5>
      <LoginPartial mutation={loginUser} error={error} loading={loading} />
      <LoginWithGoogle path="Login" />
      <BelowAuthForm page="signup" />
    </>
  );
};
