import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isImportError: undefined,
};

export const importErrorsSlice = createSlice({
  name: "importErrors",
  initialState,
  reducers: {
    addImportError: (state, data) => {
      state.isImportError = data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addImportError } = importErrorsSlice.actions;

export default importErrorsSlice.reducer;
