import "../style.scss";
import React, { useEffect, useState } from "react";
import { Formik, FieldArray, Field } from "formik";
import * as Yup from "yup";
import {
  ModalComponent,
  LoadingComponent,
  TooltipComponent,
  InputField,
  SalesFunnelModal,
  InfoTooltip,
  ButtonComponent,
} from "@components";
import { trash, plus, spinner } from "@images";
import {
  useNavigate,
  useParams,
  useOutletContext,
  Link,
} from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  ALL_STEPS_COUNTER,
  EXPORT_VALUE_DRIVERS,
  PRODUCT_BENEFITS,
  PRODUCT_VALUE_DRIVERS,
} from "@graphql/queries";
import { ValueDriverModal } from "./ValueDriverModal";
import { SweetAlert } from "@services";
import {
  BULK_ACTION_ON_DIMENSIONS,
  CREATE_VALUE_DRIVER,
  DESTROY_VALUE_DRIVER,
  IMPORT_VALUE_DRIVERS,
} from "@graphql/mutations";
import { CalculationSteps } from "../CalculationSteps";
import { NoFound } from "../NoFound";
import { ImportModal } from "@components/ImportModal";
import ExampleCSV from "../value-drivers-template.csv";
import { exportcsv } from "src/utils/exportcsv";
import humanizeString from "humanize-string";
import { Form } from "react-bootstrap";

export const ValueDrivers = () => {
  const user = useOutletContext()[1];
  const plan = user.subscriptionPlan.title;
  const isSubscribed = user.subscription;
  const benefitsCount = useOutletContext()[0].benefitsCount;
  const valueDriversCount = useOutletContext()[0].valueDriversCount;
  const isTeamMember = user.isTeamMemberOfCurrentProduct;
  const params = useParams();
  const navigate = useNavigate();

  const [benefitIds, setBenefitIds] = useState();
  const [addValueDriver, setAddValueDriver] = useState();
  const [allValues, setAllValues] = useState();
  const { loading } = useQuery(PRODUCT_BENEFITS, {
    skip: benefitsCount === 0,
    variables: { productId: params?.id },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setBenefitIds(data?.productBenefits);
    },
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
  });

  const [exportValueDrivers, { loading: eloading }] = useLazyQuery(
    EXPORT_VALUE_DRIVERS,
    {
      variables: { productId: params?.id },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        exportcsv(data.exportValueDrivers.exportCsvData);
      },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
    }
  );

  const [createValueDriver, { loading: cloading }] = useMutation(
    CREATE_VALUE_DRIVER,
    {
      variables: { productId: params?.id },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
    }
  );

  const [show, setShow] = useState(false);
  const [showValueDriverModal, setShowValueDriverModal] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [highestValue, setHighestValue] = useState();
  const [dimval, setDimVal] = useState([1, 2, 3, 4]);
  const [removeValues, setRemoveValues] = useState();
  const [modalState, setModalState] = useState();

  const [modalValue, setModalValue] = useState();

  const [destroyValueDriver, { loading: dloading }] = useMutation(
    DESTROY_VALUE_DRIVER,
    {
      onCompleted: () => {
        SweetAlert("Value Driver has been deleted", "success");
      },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    }
  );

  const [bulkActionOnDimensions, { loading: bloading, error: berror }] =
    useMutation(BULK_ACTION_ON_DIMENSIONS, {
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    });

  const handleRemove = async (remove, index, id) => {
    try {
      await destroyValueDriver({
        variables: {
          valueDriverId: id,
        },
      });
      const removeValueDriver = () => remove(index);
      removeValueDriver();
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  const onCompletedQuery = (data) => {
    const allValueDrivers = data?.productValueDrivers;
    const highestValue = allValueDrivers?.highestValue;
    setHighestValue(highestValue);
    const valueDrivers = allValueDrivers?.valueDrivers;
    const variables = [];
    for (let i = 0; i < valueDrivers.length; i++) {
      const valueDriver = valueDrivers[i];
      const variable = {
        valueDriver: { ...valueDriver, benefitId: valueDriver?.benefit?.id },
      };

      for (let i = 0; i < 4; i++) {
        const dimensionTitleKey = `dimension_${i + 1}_Title`;
        const dimensionValueKey = `dimension_${i + 1}_Value`;
        const dimension = valueDriver?.restrictDimensions[i];

        variable[dimensionTitleKey] = dimension?.value;
        variable[dimensionValueKey] = dimension?.numericalValue
          ? dimension?.numericalValue
          : "";
      }

      for (let h = 5; h <= 8; h++) {
        if (highestValue === h) {
          for (let v = 4; v < h; v++) {
            variable[`dimension_${v + 1}_Title`] = valueDriver
              .restrictDimensions[v]
              ? valueDriver.restrictDimensions[v].value
              : "";
            variable[`dimension_${v + 1}_Value`] = valueDriver
              .restrictDimensions[v]
              ? valueDriver.restrictDimensions[v].numericalValue || ""
              : "";
          }
        }
      }
      variables.push(variable);
      setInitialValues({
        variables,
      });
    }
  };

  const { loading: vloading } = useQuery(PRODUCT_VALUE_DRIVERS, {
    skip: benefitsCount === 0,
    variables: { productId: params?.id },
    fetchPolicy: "network-only",
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
    onCompleted: (data) => {
      onCompletedQuery(data);
    },
  });
  const [productValueDrivers] = useLazyQuery(PRODUCT_VALUE_DRIVERS, {
    skip: benefitsCount === 0,
    variables: { productId: params?.id },
    fetchPolicy: "network-only",
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
    onCompleted: (data) => {
      onCompletedQuery(data);
    },
  });
  const data = {
    name: "Dimension",
  };
  const [tabHeader, settabHeader] = useState([]);

  const addth = () => {
    settabHeader([...tabHeader, data]);
  };

  useEffect(() => {
    for (let i = dimval.length; i < highestValue; i++) {
      dimval.push(i + 1);
    }
    for (let i = 0; i < highestValue - 4; i++) {
      tabHeader.push(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highestValue]);

  const thTabRemove = (index) => {
    const dataRow = [...tabHeader];
    dataRow.splice(index, 1);
    settabHeader(dataRow);
  };

  const errorsShow = () => {
    const els = document.querySelectorAll(".text-danger");
    for (let x = 0; x < els.length; x++) els[x].style.display = "block";
    const list = document.querySelectorAll(".border-red");
    for (let l = 0; l < list.length; l++)
      list[l].classList.add("border-danger");
  };

  const inputFieldSchema = Yup.object().shape({
    valueDriver: Yup.object(),
    dimension_1_Title:
      !isTeamMember && Yup.string().required("Dimension 1 is required"),
    dimension_1_Value: Yup.number(),
    dimension_2_Title: Yup.string(),
    dimension_2_Value: Yup.number(),
    dimension_3_Title: Yup.string(),
    dimension_3_Value: Yup.number(),
    dimension_4_Title: Yup.string(),
    dimension_4_Value: Yup.number(),
    dimension_5_Title: Yup.string(),
    dimension_5_Value: Yup.number(),
    dimension_6_Title: Yup.string(),
    dimension_6_Value: Yup.number(),
    dimension_7_Title: Yup.string(),
    dimension_7_Value: Yup.number(),
    dimension_8_Title: Yup.string(),
    dimension_8_Value: Yup.number(),
  });

  const bulkMutation = async (link, values) => {
    const navigation = () => {
      if (link === "/dashboard?plan=true") {
        navigate("/dashboard?plan=true");
      } else {
        navigate(`/product/${params?.id}/${link}`);
      }
    };
    if (values?.variables === undefined || values?.variables?.length === 0) {
      navigation();
    } else {
      const variables = [];
      // eslint-disable-next-line array-callback-return
      values?.variables?.map((item) => {
        for (let v = 1; v <= 8; v++) {
          variables.push({
            position: v,
            value: item?.[`dimension_${v}_Title`] || "",
            numericalValue: item?.[`dimension_${v}_Value`] || 0,
            valueDriverId: item?.valueDriver?.id,
          });
        }
      });
      try {
        await bulkActionOnDimensions({
          variables: { input: { dimensionAttributes: variables } },
        });
        navigation();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleButtons = (values, errors, e, link) => {
    if (e?.target?.id === "next" && errors?.variables) {
      errorsShow();
    } else {
      bulkMutation(link, values);
    }
  };

  const [importErrors, setImportErrors] = useState();
  const [importValueDrivers, { loading: iloading, error: ierror }] =
    useMutation(IMPORT_VALUE_DRIVERS, {
      variables: { productId: params?.id },
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
        productValueDrivers();
      },
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    });

  const importFile = async (file) => {
    try {
      const { data } = await importValueDrivers({
        variables: { file },
      });
      if (data?.importValueDrivers?.success === true) {
        setImportErrors(false);
        SweetAlert("Value Drivers imported successfully.", "success");
        productValueDrivers();
      } else {
        setImportErrors(data?.importValueDrivers?.errors);
        productValueDrivers();
      }
    } catch (e) {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
    }
  };

  if (loading || vloading) return <LoadingComponent height="calculation" />;

  const instructions = [
    <div>
      <Link
        to={ExampleCSV}
        download="ValueDrivers-Template.csv"
        rel="noreferrer"
        target="_blank"
        className="text-decoration-underline"
      >
        Download
      </Link>{" "}
      the template
    </div>,
    "Don't change or add more header names.",
    "Value Driver name must be unique and it is mandatory field.",
    "Each Value Driver has a Benefit and it will link with any existing Benefit with their name (so write correct Benefit name).",
    "Adding a Dimension is optional, and numerical values are not mandatory. Do not allow to enter only a numerical value without a Dimension.",
    "If you're adding Dimensions, follow the sequence: 1, 2, 3, ...",
    "If you're using an existing Value Driver and want to add Dimensions, follow the rules as above.",
    "Don't refresh the page while importing the data.",
  ];

  function removeAttributesAndShift(arr, numberToRemove) {
    return arr.map((obj) => {
      const titleToRemove = `dimension_${numberToRemove}_Title`;
      const valueToRemove = `dimension_${numberToRemove}_Value`;

      if (titleToRemove in obj && valueToRemove in obj) {
        const newObj = { ...obj };
        delete newObj[titleToRemove];
        delete newObj[valueToRemove];

        for (let i = numberToRemove + 1; i <= 8; i++) {
          const currentTitle = `dimension_${i}_Title`;
          const currentValue = `dimension_${i}_Value`;

          if (currentTitle in newObj && currentValue in newObj) {
            newObj[`dimension_${i - 1}_Title`] = newObj[currentTitle];
            newObj[`dimension_${i - 1}_Value`] = newObj[currentValue];
            delete newObj[currentTitle];
            delete newObj[currentValue];
          }
        }
        return newObj;
      } else {
        return obj;
      }
    });
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          variables: Yup.array().of(inputFieldSchema),
        })}
      >
        {({ values, errors }) => {
          return (
            <form className={``}>
              <div className="border-bottom">
                <div className="container-fluid">
                  <div className="stepper d-flex justify-content-center align-items-center">
                    <CalculationSteps
                      bulkMutation={bulkMutation}
                      variables={values}
                    />
                    <div className="ms-4 ms-xl-5 d-flex align-items-center">
                      <button
                        disabled={bloading || benefitsCount === 0}
                        type="button"
                        id="previous"
                        className="btn btn-light btn-sm me-2"
                        onClick={(e) =>
                          handleButtons(values, errors, e, `benefits`)
                        }
                      >
                        Previous
                      </button>
                      <button
                        disabled={
                          bloading ||
                          benefitsCount === 0 ||
                          valueDriversCount === 0
                        }
                        type="button"
                        id="next"
                        className="btn btn-primary btn-sm"
                        onClick={(e) =>
                          handleButtons(values, errors, e, `characteristics`)
                        }
                      >
                        Next
                      </button>
                      {bloading && (
                        <i className="fa-solid fa-spinner fa-spin ms-2"></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {benefitsCount === 0 ? (
                <NoFound text="Benefits" link="benefits" />
              ) : (
                <>
                  {errors?.variables && (
                    <div className="alert alert-warning text-danger text-center hide">
                      There are some errors in the form. Kindly fill the form
                      properly without errors
                    </div>
                  )}
                  {berror && (
                    <div className="alert alert-warning text-danger text-center">
                      {berror?.message}
                    </div>
                  )}
                  <div className="container-xxl py-4">
                    <div className="d-flex align-items-center justify-content-between mb-3 gap-2">
                      <h6>Value Drivers</h6>
                      <div className="d-flex gap-2 align-items-center">
                        <Link to={`#`} className=" text-black me-4">
                          Main View
                        </Link>
                        <Link
                          to={`/product/${params?.id}/valueDriverSuggestion`}
                        >
                          <Form.Check
                            type="switch"
                            id="one"
                            className="pe-none"
                          />
                        </Link>
                        <Link
                          to={`/product/${params?.id}/valueDriverSuggestion`}
                          className=" text-black me-4  "
                        >
                          Suggestion View
                        </Link>
                        {!isTeamMember &&
                          (plan === "lead" ? (
                            <>
                              <ImportModal
                                instructions={instructions}
                                setErrors={setImportErrors}
                                errors={importErrors}
                                error={ierror}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <InputField
                                    type="file"
                                    onChange={(e) =>
                                      importFile(e.target.files[0])
                                    }
                                    accept=".csv"
                                  />
                                  {iloading && (
                                    <img
                                      src={spinner}
                                      width={40}
                                      alt="Laoding"
                                    />
                                  )}
                                </div>
                              </ImportModal>

                              {valueDriversCount === 0 ? (
                                <TooltipComponent overlayText="Add at-least one value driver to export">
                                  <div className="d-inline-block">
                                    <ButtonComponent
                                      type="outline-primary"
                                      size="sm"
                                      icon="download"
                                      position="start"
                                      disabled={true}
                                      iconType="solid"
                                      text="Export"
                                    />
                                  </div>
                                </TooltipComponent>
                              ) : (
                                <ButtonComponent
                                  type="outline-primary"
                                  size="sm"
                                  icon={
                                    eloading ? "spinner fa-spin" : "download"
                                  }
                                  position="start"
                                  iconType="solid"
                                  text="Export"
                                  onClick={() => exportValueDrivers()}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <SalesFunnelModal className="btn btn-outline-primary btn-sm">
                                <i className="fas fa-upload me-2"></i>Import
                              </SalesFunnelModal>
                              <SalesFunnelModal className="btn btn-outline-primary btn-sm">
                                <i className="fas fa-download me-2"></i>Export
                              </SalesFunnelModal>
                            </>
                          ))}
                      </div>
                    </div>
                    <div className="table-responsive position-relative">
                      <table className="table user-table calculation-table mb-0 driver-table">
                        <thead>
                          <tr className="border-bottom">
                            <th className="w-145-p">
                              <div className="d-flex align-items-center">
                                Value Driver Name
                                <InfoTooltip text="A Value Driver is a feature of the product or service you offer" />
                              </div>
                            </th>
                            <th>
                              Dimension 1
                              <InfoTooltip text="The dimensions of a value driver are are the different options to which value is offered to the customer, from the lowest to highest degree, for one value driver." />
                            </th>
                            <th>Dimension 2</th>
                            <th>Dimension 3</th>
                            <th>Dimension 4</th>
                            {tabHeader.map((oneth, index) => (
                              <th key={index}>
                                {oneth.name} {index + 5}
                              </th>
                            ))}
                            <th className="text-right">
                              {!isTeamMember &&
                                (isSubscribed === true ? (
                                  <button
                                    disabled={
                                      valueDriversCount === 0 ||
                                      dimval.length >= 8
                                    }
                                    type="button"
                                    className="border-0 bg-transparent"
                                    onClick={() => {
                                      const newObj = {
                                        ...values,
                                        variables: values.variables.map(
                                          (obj) => {
                                            return {
                                              ...obj,
                                              [`dimension_${
                                                dimval[dimval.length - 1] + 1
                                              }_Title`]: "",
                                              [`dimension_${
                                                dimval[dimval.length - 1] + 1
                                              }_Value`]: "",
                                            };
                                          }
                                        ),
                                      };
                                      setInitialValues({
                                        ...newObj,
                                        variables: newObj.variables.map(
                                          (mainObj) => {
                                            const ordered = Object.keys(mainObj)
                                              .sort()
                                              .reduce((obj, key) => {
                                                obj[key] = mainObj[key];
                                                return obj;
                                              }, {});
                                            return {
                                              valueDriver: ordered.valueDriver,
                                              ...ordered,
                                            };
                                          }
                                        ),
                                      });
                                      setDimVal([
                                        ...dimval,
                                        dimval[dimval.length - 1] + 1,
                                      ]);
                                      addth();
                                    }}
                                  >
                                    <TooltipComponent
                                      overlayText="You can add upto 8 dimensions for your value driver"
                                      placement="top"
                                    >
                                      <img src={plus} alt={plus} />
                                    </TooltipComponent>
                                  </button>
                                ) : (
                                  <SalesFunnelModal
                                    error={berror}
                                    loading={bloading}
                                    handlePlan={(e) =>
                                      handleButtons(
                                        values,
                                        errors,
                                        e,
                                        "/dashboard?plan=true"
                                      )
                                    }
                                    className="border-0 bg-transparent"
                                  >
                                    <img src={plus} alt={plus} />
                                  </SalesFunnelModal>
                                ))}
                            </th>
                          </tr>
                        </thead>
                        <tbody className={isTeamMember ? "pe-none " : ""}>
                          <td className="p-0" colSpan={20}>
                            <div className="bg-primary p-2 text-center text-white">
                              Titles are required for all dimensions in value
                              driver
                            </div>
                          </td>
                          <FieldArray name="variables">
                            {({ push, remove }) => (
                              <>
                                {values?.variables?.map((val, index) => {
                                  const pairs = Object.keys(val).reduce(
                                    (result, item, index, arr) => {
                                      if ((index + 1) % 2 === 0) {
                                        if (item !== "valueDriver") {
                                          result.push([item, arr[index + 1]]);
                                        }
                                      }
                                      return result;
                                    },
                                    []
                                  );
                                  const valueDriverAttrs = [];
                                  if (val?.valueDriver?.type === "") {
                                    valueDriverAttrs.push(
                                      val?.valueDriver?.category
                                    );
                                  } else if (
                                    val?.valueDriver?.category === ""
                                  ) {
                                    valueDriverAttrs.push(
                                      val?.valueDriver?.type
                                    );
                                  } else {
                                    valueDriverAttrs.push(
                                      val?.valueDriver?.type,
                                      val?.valueDriver?.category
                                    );
                                  }
                                  return (
                                    <tr key={index}>
                                      <td
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setModalState(index);
                                          setAllValues(values);
                                          const attrs =
                                            values?.variables[index]
                                              ?.valueDriver;
                                          const valueDriver = {
                                            id: attrs?.id,
                                            name: attrs?.name,
                                            category: attrs?.category,
                                            featureStatus: attrs?.featureStatus,
                                            description: attrs?.description,
                                            type: attrs?.type,
                                            benefitId: attrs?.benefitId,
                                            unitOfMeasure: attrs?.unitOfMeasure,
                                          };
                                          setModalValue(valueDriver);
                                          setShowValueDriverModal(true);
                                        }}
                                      >
                                        {val?.valueDriver?.name || ""}
                                        <br />
                                        <div className="d-flex text-gray-500 fs-12 gap-3">
                                          {valueDriverAttrs.join(" • ")}
                                        </div>
                                      </td>
                                      {pairs.map((pair, parentIndex) => (
                                        <td key={parentIndex}>
                                          {pair.map((objKey, childIndex) => (
                                            <div key={childIndex}>
                                              <Field
                                                as={InputField}
                                                className={`form-control ${
                                                  errors?.variables?.[index]?.[
                                                    objKey
                                                  ]
                                                    ? "border-red ms-n10"
                                                    : "ms-n10"
                                                }`}
                                                type={
                                                  objKey?.includes("Value")
                                                    ? "number"
                                                    : "text"
                                                }
                                                value={
                                                  values?.variables?.[index]?.[
                                                    objKey
                                                  ]
                                                }
                                                name={`variables.${index}.${objKey}`}
                                                placeholder={`Enter ${humanizeString(
                                                  objKey || ""
                                                )}`}
                                              />
                                              <div className="text-danger hide ms-n10">
                                                {
                                                  errors?.variables?.[index]?.[
                                                    objKey
                                                  ]
                                                }
                                              </div>

                                              {parentIndex >= 4 &&
                                                !objKey.includes("Value") && (
                                                  <div
                                                    className="DeleteDimension"
                                                    type="button"
                                                    onClick={() => {
                                                      thTabRemove();
                                                      setInitialValues({
                                                        variables:
                                                          removeAttributesAndShift(
                                                            values.variables,
                                                            parentIndex + 1
                                                          ),
                                                      });
                                                      const temp = [...dimval];
                                                      temp.pop();
                                                      setDimVal(temp);
                                                    }}
                                                  >
                                                    {isTeamMember ? null : (
                                                      <i className="fa-solid fa-trash text-gray-400 fs-16"></i>
                                                    )}
                                                  </div>
                                                )}
                                            </div>
                                          ))}
                                        </td>
                                      ))}
                                      <td>
                                        {isTeamMember ? (
                                          ""
                                        ) : (
                                          <img
                                            src={trash}
                                            alt={trash}
                                            type="button"
                                            onClick={() => {
                                              setShow(true);
                                              setRemoveValues({
                                                function: remove,
                                                index: index,
                                                id: val?.valueDriver?.id,
                                              });
                                            }}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {!isTeamMember && (
                                  <tr className="row-action-btn">
                                    <td
                                      colSpan={12}
                                      className="cursor-pointer"
                                      onClick={async () => {
                                        const newValueDriver =
                                          createValueDriver();

                                        newValueDriver
                                          .then((result) => {
                                            const valueDriver =
                                              result?.data?.createValueDriver
                                                ?.valueDriver;
                                            if (!cloading) {
                                              setModalState("");
                                              setAllValues(values);
                                              setModalValue(valueDriver);
                                              setShowValueDriverModal(true);
                                              setAddValueDriver(() => push);
                                            }
                                          })
                                          .catch((error) => {
                                            // Handle any errors
                                          });
                                      }}
                                    >
                                      + Add Value Driver
                                    </td>
                                  </tr>
                                )}
                                {cloading && (
                                  <tr className="text-center spinner-table-loading">
                                    <td colSpan={8}>
                                      <div
                                        className="spinner-border text-primary"
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            )}
                          </FieldArray>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </form>
          );
        }}
      </Formik>
      {showValueDriverModal && (
        <ValueDriverModal
          allValues={allValues}
          addValueDriver={addValueDriver}
          modalState={modalState}
          setInitialValues={setInitialValues}
          benefitIds={benefitIds}
          modalValue={modalValue}
          initialValues={initialValues}
          showValueDriverModal={showValueDriverModal}
          setShowValueDriverModal={setShowValueDriverModal}
        />
      )}
      <ModalComponent
        loading={dloading}
        closeModal={() => setShow(false)}
        showModal={show}
        size="sm"
        title="Confirm Delete"
        closeButtonText="No, keep"
        submitButtonText="Yes, Delete"
        submitButtonType="danger"
        closeButtonType="light"
        handleSubmit={() => {
          handleRemove(
            removeValues.function,
            removeValues.index,
            removeValues.id
          );
          setShow(false);
        }}
      >
        Are you sure you want to delete this? This action can’t be undone
      </ModalComponent>
    </>
  );
};
