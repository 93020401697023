import { Link } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { SettingsSidebar } from "./SettingsSidebar";
import { ProfilePic } from "./ProfilePic";

export const Profile = () => {
  const user = useOutletContext();

  return (
    <div className="container-xxl py-5">
      <div className="mw-730 w-100 mx-auto">
        <SettingsSidebar />
        <h6 className="text-gray-900 mb-32">Profile</h6>
        <div className="row mb-3 align-items-center">
          <div className="col-4 col-sm-3">
            <p>Profile Photo</p>
          </div>
          <div className="col-8 col-sm-9">
            <ProfilePic imageUrl={user?.imageUrl} width={50} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4 col-sm-3">
            <p>Full Name</p>
          </div>
          <div className="col-8 col-sm-9">
            <p className="fw-600">{user?.fullName}</p>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-4 col-sm-3">
            <p>Email Address</p>
          </div>
          <div className="col-8 col-sm-9">
            <p className="fw-600">{user?.email}</p>
          </div>
        </div>
        <Link to="/edit-profile" className="btn btn-primary btn-lg">
          Edit
        </Link>
      </div>
    </div>
  );
};
