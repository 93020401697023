import { ButtonComponent } from "@components";
import "../style.scss";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, previousStep, resetSteps } from "@store/onboardingSlice";
import { useEffect } from "react";
import { ONBOARDING_USER_RESPONSE } from "@graphql/mutations";
import { useMutation } from "@apollo/client";
import { SweetAlert } from "@services";
import { Link } from "react-router-dom";

export const OnboardingComponent = (props) => {
  const user = useSelector((state) => state.auth.user);
  const { heading, children, className } = props;
  const stepNumber = useSelector((state) => state.onboarding.stepNumber);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSteps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [onboardingUserResponse] = useMutation(ONBOARDING_USER_RESPONSE);

  const handleSubmit = async () => {
    dispatch(nextStep());
    if (stepNumber === 0) {
      try {
        await onboardingUserResponse({
          variables: {
            userId: user?.id,
          },
        });
      } catch (e) {
        SweetAlert(
          "An error occurred, please try again or check your network connection",
          "error"
        );
      }
    }
  };

  return (
    <div
      className={`d-flex align-items-center justify-content-center h-100 ${
        className ? className : ""
      }`}
    >
      <div className="onboarding-card">
        <div
          className={`d-flex flex-column gap-3 ${
            stepNumber === 0 || stepNumber === 15 ? "flex-column-reverse" : ""
          }`}
        >
          <div className="text-gray-500">
            {stepNumber === 0 ? (
              <>
                <div>
                  Please answer this question based on your experience.
                  Combining the answer to this question, together with other
                  questions help us make an estimation on the right approach to
                  your pricing.
                </div>
                <div className="fw-bold">
                  Upon completing the onboarding questions, you will have the
                  opportunity to schedule your first workshop.
                </div>
              </>
            ) : stepNumber === 15 ? (
              <>
                <div className="mb-2">
                  Please schedule your first workshop, we will discuss an
                  optimal pricing approach based on your inputs
                </div>
                <Link
                  to="https://calendly.com/getpricingpower/pricing-power-diagnostic"
                  target="_blank"
                  className="btn btn-primary btn-sm"
                >
                  Schedule Now{" "}
                </Link>
              </>
            ) : (
              `Question ${stepNumber}/14`
            )}
          </div>
          <div>
            <h6>{heading}</h6>
            {stepNumber !== 0 && stepNumber !== 15 ? (
              <p className="text-sm text-gray-600">(single choice)</p>
            ) : (
              ""
            )}
          </div>
        </div>
        {stepNumber !== 0 && (
          <div className="onboarding-body mt-32">{children}</div>
        )}
        <div className="d-flex align-items-center justify-content-between mt-32">
          {stepNumber !== 15 ? (
            <ButtonComponent
              type="text"
              text="Skip"
              link={`/product/${user?.currentProductId}/benefits`}
            />
          ) : (
            <div></div>
          )}
          <div className="d-flex gap-3">
            {stepNumber !== 0 && stepNumber !== 1 && (
              <ButtonComponent
                type="light"
                text="Back"
                onClick={() => dispatch(previousStep())}
              />
            )}
            {stepNumber === 15 ? (
              <ButtonComponent
                type="primary"
                text="Finish"
                link={`/product/${user?.currentProductId}/benefits`}
              />
            ) : (
              <ButtonComponent
                type="primary"
                text={stepNumber === 0 ? "Get Started" : "Next"}
                onClick={() => handleSubmit()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
