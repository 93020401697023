import { successImage, errorImage } from "@images";
import { ModalComponent, ButtonComponent } from "@components";
import { ListGroup } from "react-bootstrap";
import { useEffect, useState } from "react";

export const ImportModal = (props) => {
  const { instructions, errors, children, error, setErrors } = props;
  const [show, setShow] = useState();
  useEffect(() => {
    errors === false && setShow(false);
  }, [errors]);

  return (
    <>
      <ButtonComponent
        type="outline-primary"
        size="sm"
        icon="upload"
        position="start"
        iconType="solid"
        text="Import"
        onClick={() => setShow(true)}
      />
      <ModalComponent
        title="Import"
        closeModal={() => {
          setShow(false);
          setErrors(false);
        }}
        showModal={show}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
      >
        {errors || error ? (
          <div>
            <div className="mt-n3 mb-2">
              Please fix the following error before uploading the document
            </div>
            <ListGroup variant="flush">
              {errors ? (
                errors?.map((error, index) => (
                  <ListGroup.Item
                    className="px-0 border-0 d-flex align-items-center"
                    key={index}
                  >
                    <img src={errorImage} alt="List" className="me-2" />
                    {error.message}
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item className="px-0 border-0 d-flex align-items-center">
                  <img src={errorImage} alt="List" className="me-2" />
                  {error.message}
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>
        ) : (
          <div>
            <div className="mt-n3 mb-2">
              Follow the instructions below to upload the document
            </div>
            <ListGroup variant="flush">
              {instructions.map((instruction, index) => (
                <ListGroup.Item
                  className="px-0 border-0 d-flex align-items-center"
                  key={index}
                >
                  <img src={successImage} alt="List" className="me-2" />
                  {instruction}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        )}
        <div className="mt-3 mb-2 fw-bold">Select a CSV file to upload</div>
        {children}
      </ModalComponent>
    </>
  );
};
