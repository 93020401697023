import "../style.scss";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { ModalComponent } from "@components";
import { SweetAlert } from "@services";
import { useMutation } from "@apollo/client";
import { DESTROY_VALUE_DRIVER, UPDATE_VALUE_DRIVER } from "@graphql/mutations";
import { ALL_STEPS_COUNTER } from "@graphql/queries";

let obj;
if (window.location.pathname.includes("valueDriverSuggestion")) {
  obj = {
    variables: [
      {
        valueDriver: {
          id: "",
          name: "",
          type: "",
          category: "",
          benefitId: "",
          unitOfMeasure: "",
        },
        dimension_1_Title: "",
        dimension_1_Value: "",
        dimension_2_Title: "",
        dimension_2_Value: "",
        dimension_3_Title: "",
        dimension_3_Value: "",
        dimension_4_Title: "",
        dimension_4_Value: "",
        dimension_5_Title: "",
        dimension_5_Value: "",
        dimension_6_Title: "",
        dimension_6_Value: "",
        dimension_7_Title: "",
        dimension_7_Value: "",
        dimension_8_Title: "",
        dimension_8_Value: "",
      },
    ],
  };
} else {
  obj = {
    variables: [
      {
        valueDriver: {
          id: "",
          name: "",
          type: "",
          category: "",
          benefitId: "",
          unitOfMeasure: "",
        },
        dimension_1_Title: "",
        dimension_1_Value: "",
        dimension_2_Title: "",
        dimension_2_Value: "",
        dimension_3_Title: "",
        dimension_3_Value: "",
        dimension_4_Title: "",
        dimension_4_Value: "",
      },
    ],
  };
}

export const ValueDriverModal = (props) => {
  const {
    modalState,
    modalValue,
    setShowValueDriverModal,
    showValueDriverModal,
    benefitIds,
    initialValues,
    addValueDriver,
    setInitialValues,
    allValues,
  } = props;
  const type = [
    { value: "Core" },
    { value: "Non Core" },
    { value: "Hygienic" },
  ];
  const featureStatus = [
    { value: "Functional" },
    { value: "Idea-stage" },
    { value: "In development" },
  ];
  const category = [
    { value: "Technical" },
    { value: "Service" },
    { value: "Other" },
  ];

  const modalValues = {
    id: "",
    name: "",
    description: "",
    type: "",
    featureStatus: "",
    category: "",
    benefitId: "",
    unitOfMeasure: "",
  };
  const modalFieldSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().required("Name is required"),
    description: Yup.string(),
    type: Yup.string(),
    featureStatus: Yup.string(),
    category: Yup.string(),
    benefitId: Yup.string().required("Benefit is required"),
    unitOfMeasure: Yup.string(),
  });

  const [updateValueDriver, { loading: cloading }] = useMutation(
    UPDATE_VALUE_DRIVER,
    {
      onCompleted: () => {
        setShowValueDriverModal(false);
      },
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    }
  );

  const handleSubmit = async (values) => {
    const variables = {
      id: values?.id,
      name: values?.name,
      category: values?.category,
      featureStatus: values?.featureStatus,
      description: values?.description,
      type: values?.type,
      benefitId: values?.benefitId,
      unitOfMeasure: values?.unitOfMeasure,
    };
    try {
      await updateValueDriver({
        variables,
      });
      if (modalState === "") {
        const temp = {};
        if (initialValues?.variables && initialValues.variables[0]) {
          Object.keys(initialValues.variables[0]).forEach(
            (key) => (temp[key] = "")
          );
        }
        const newObj = initialValues?.variables?.[0] ? temp : obj.variables[0];
        addValueDriver({
          ...newObj,
          valueDriver: values,
        });
        const newArray = { ...newObj, valueDriver: values };
        if (allValues?.variables) {
          setInitialValues({
            variables: [...allValues?.variables, newArray],
          });
        } else {
          setInitialValues({
            variables: [newArray],
          });
        }
      } else {
        const newArray = [...initialValues?.variables];
        newArray[modalState].valueDriver = values;
        setInitialValues({ ...initialValues, variables: newArray });
      }
    } catch (e) {
      SweetAlert(e.message, "error");
    }
  };

  const [destroyValueDriver, { loading: dloading }] = useMutation(
    DESTROY_VALUE_DRIVER,
    {
      onError: (e) => {
        SweetAlert(e, "error");
      },
      refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    }
  );

  const closeModal = async (values) => {
    if (modalState === "" && (!values?.benefitId || !values?.name)) {
      try {
        await destroyValueDriver({
          variables: {
            valueDriverId: values?.id,
          },
        });
        setShowValueDriverModal(false);
      } catch (e) {
        SweetAlert(e, "error");
      }
    } else {
      setShowValueDriverModal(false);
    }
  };

  return (
    <Formik
      initialValues={modalValue?.id ? modalValue : modalValues}
      validationSchema={modalFieldSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleSubmit }) => {
        return (
          <ModalComponent
            loading={cloading || dloading}
            backdrop="static"
            keyboard={false}
            closeModal={() => closeModal(values)}
            showModal={showValueDriverModal}
            title="Value Driver Details"
            closeButtonText="Close"
            submitButtonText="Save"
            closeButtonType="light"
            submitButtonType="primary"
            handleSubmit={handleSubmit}
          >
            <Form onSubmit={handleSubmit}>
              <Field
                className="form-control d-none"
                name="id"
                type="text"
                readOnly={true}
              />
              <div>
                <label className="mb-2" htmlFor="name">
                  Value Driver Name
                </label>
                <Field
                  value={values?.name || ""}
                  className="form-control"
                  name="name"
                  type="text"
                />
                <div className="text-danger mb-3">{errors.name}</div>
              </div>
              <div>
                <label className="mb-2" htmlFor="description">
                  Add Description
                </label>
                <Field
                  value={values?.description || ""}
                  className="form-control mb-3"
                  name="description"
                  type="text"
                />
              </div>

              <div>
                <label className="mb-2" htmlFor="type">
                  Type
                </label>
                <Field
                  value={values?.type || ""}
                  className="form-select mb-3"
                  name="type"
                  as="select"
                >
                  <option value="">Select item</option>
                  {type.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
              </div>
              <div>
                <label className="mb-2" htmlFor="featureStatus">
                  Feature/Value driver status
                </label>
                <Field
                  value={values?.featureStatus || ""}
                  className="form-select mb-3"
                  name="featureStatus"
                  as="select"
                >
                  <option value="">Select item</option>
                  {featureStatus.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
              </div>
              <div>
                <label className="mb-2" htmlFor="category">
                  Category
                </label>
                <Field
                  value={values?.category || ""}
                  className="form-select mb-3"
                  name="category"
                  as="select"
                >
                  <option value="">Select item</option>
                  {category.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="mb-3">
                <label className="mb-2" htmlFor="benefitId">
                  Link Benefit
                </label>
                <Field
                  className="form-select"
                  name="benefitId"
                  as="select"
                  value={values?.benefitId}
                >
                  <option value="">Select item</option>
                  {benefitIds?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name || id}
                    </option>
                  ))}
                </Field>
                <div className="text-danger">{errors.benefitId}</div>
              </div>
              <div className="d-none">
                <label className="mb-2" htmlFor="unitOfMeasure">
                  Unit of measure
                </label>
                <Field
                  value={values?.unitOfMeasure || ""}
                  className="form-control"
                  name="unitOfMeasure"
                  type="text"
                />
              </div>
            </Form>
          </ModalComponent>
        );
      }}
    </Formik>
  );
};
