import { useMutation } from "@apollo/client";
import { ModalComponent } from "@components/Modal";
import { CREATE_PRODUCT } from "@graphql/mutations";
import { USER_PRODUCTS } from "@graphql/queries";
import { SweetAlert } from "@services/SweetAlert";
import React, { useState } from "react";

export default function AddProductModal({ showModal, closeModal, refetch }) {
  const [variables, setVariables] = useState({
    name: "",
    currency: "USD",
  });

  const [productArguments, { loading, error }] = useMutation(CREATE_PRODUCT, {
    refetchQueries: [{ query: USER_PRODUCTS }],
  });

  const handlaChange = (e) => {
    const { name, value } = e.target;
    setVariables((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await productArguments({
        variables: {
          ...variables,
        },
      });
      // Reset form state after successful API call
      setVariables({
        name: "",
        currency: "",
      });
      await refetch();
      SweetAlert("Product added successfully");

      closeModal(true);
    } catch (error) {
      // Handle GraphQL errors
      SweetAlert(error);
      // Do not close the modal on error
    }
  };

  return (
    <div>
      <ModalComponent
        title="Add Product"
        showModal={showModal}
        closeModal={closeModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        submitButtonText={
          loading ? (
            <>
              <i className="fas fa-spinner fa-spin me-2"></i>Create Product
            </>
          ) : (
            "Create Product"
          )
        }
        submitButtonType="primary"
        handleSubmit={handleSubmit}
      >
        {error && <div className="mb-2 text-danger">{error.message}</div>}
        <form>
          <label className="mb-1">Enter Name of Product</label>
          <input
            type="text"
            name="name"
            className="form-control"
            required
            onChange={handlaChange}
            value={variables.name}
          />
        </form>
      </ModalComponent>
    </div>
  );
}
