/* eslint-disable jsx-a11y/role-supports-aria-props */
import "../style.scss";
import { useState } from "react";
import { Collapse } from "react-bootstrap";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { PRICE_MODEL } from "@graphql/queries";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_UPDATE_PRICE_MODEL,
  ADD_UPDATE_PRICE_MODEL_VALUE,
} from "@graphql/mutations";
import { CalculationSteps } from "../CalculationSteps";
import { LoadingComponent, InfoTooltip } from "@components";
import { SweetAlert } from "@services";
import { NoFound } from "../NoFound";
import { WeightPriceTableHeadings } from "../WeightPriceTableHeadings";

export const Price = () => {
  const currency = useOutletContext()[1].defaultProductCurrency.symbol;
  const isSubscribed = useOutletContext()[1].subscription;
  const isTeamMember = useOutletContext()[1].isTeamMemberOfCurrentProduct;
  const valueDriversCount = useOutletContext()[0].valueDriversCount;
  const dimensionsCount = useOutletContext()[0].dimensionsCount;
  const segmentsCount = useOutletContext()[0].segmentsCount;
  const params = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [variables] = useState([]);
  const [resultModels, setResultModels] = useState([]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const { loading, data } = useQuery(PRICE_MODEL, {
    variables: { productId: params?.id },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const segments = data?.priceModelByTabular[0]?.segments;
      let psyValues = {};
      let comValues = {};
      // eslint-disable-next-line array-callback-return
      segments?.map((segment, index) => {
        psyValues = {
          ...psyValues,
          [`segment${index + 1}Name`]:
            segment?.priceModel?.psychologicalPricing,
        };
        comValues = {
          ...comValues,
          [`segment${index + 1}Name`]: segment?.priceModel?.competitorPricing,
        };
        variables.push({
          id: segment?.priceModel?.id,
          productId: params?.id,
          segmentId: segment?.segment?.id,
          psychologicalPricing: segment?.priceModel?.psychologicalPricing,
          competitorPricing: segment?.priceModel?.competitorPricing,
        });
      });
      resultModels[0] = psyValues;
      resultModels[2] = comValues;
      calculateDeltaCompetition();
    },
    onError: (e) => {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error"
      );
      console.log(e);
    },
  });

  const [addUpdatePriceModelValue, { loading: uloading }] = useMutation(
    ADD_UPDATE_PRICE_MODEL_VALUE,
    {
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
      refetchQueries: [{ query: PRICE_MODEL }, "priceModelByTabular"],
    }
  );

  const [addUpdatePriceModel, { loading: bulkLoading }] = useMutation(
    ADD_UPDATE_PRICE_MODEL,
    {
      onError: (e) => {
        SweetAlert(
          "An error occured. Please try again or check your network connection.",
          "error"
        );
        console.log(e);
      },
    }
  );

  const handleChange = async (e) => {
    const { segmentid, pricemodelid, valuedriverid } = e.target.dataset;
    const variables = {
      segmentId: segmentid,
      priceModelId: pricemodelid,
      valueDriverId: valuedriverid,
      dimensionId: e.target.value,
      productId: params?.id,
    };
    try {
      await addUpdatePriceModelValue({
        variables: { input: { valueAttributes: variables } },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const replaceInfinityWithZero = (value) => {
    if (typeof value !== "number" || isNaN(value) || !isFinite(value)) {
      return 0;
    } else {
      const fixedValue = +value.toFixed(2);
      return fixedValue;
    }
  };

  const calculateDeltaCompetition = () => {
    let obj = {};
    for (let i = 0; i <= segmentsCount; i++) {
      obj = {
        ...obj,
        [`segment${i + 1}Name`]:
          (resultModels[0]?.[`segment${i + 1}Name`] /
            resultModels[2]?.[`segment${i + 1}Name`] -
            1) *
          100,
      };
    }

    const newArray = [...resultModels];
    newArray[3] = obj;
    setResultModels(newArray);
  };

  const handleResultFieldChange = (event, key, index) => {
    resultModels[index][key] = +event.target.value;
    const newArray = [...resultModels];
    setResultModels(newArray);
    calculateDeltaCompetition();
  };

  const handleButtons = async (link, e) => {
    const navigation = () => {
      if (e?.target.id === "next") {
        navigate(`/dashboard`);
      } else {
        navigate(`/product/${params?.id}/${link}`);
      }
    };
    if (variables === undefined || variables?.length === 0) {
      navigation();
    } else {
      try {
        await addUpdatePriceModel({
          variables: { input: { priceModelAttributes: variables } },
        });
        navigation();
      } catch (e) {
        console.log(e);
      }
    }
  };

  if (loading) return <LoadingComponent height="calculation" />;

  const priceModel = data?.priceModelByTabular;
  const segments = priceModel[0]?.segments;
  const segmentNames = [];
  segments?.map((segment) => segmentNames.push(segment?.segment?.name));

  return (
    <>
      <div className="border-bottom">
        <div className="container-fluid">
          <div className="stepper d-flex justify-content-center align-items-center">
            <CalculationSteps bulkMutation={handleButtons} />
            <div className="ms-4 ms-xl-5 d-flex align-items-center">
              <button
                type="button"
                id="previous"
                className="btn btn-light btn-sm me-2"
                disabled={
                  bulkLoading ||
                  valueDriversCount === 0 ||
                  dimensionsCount === 0
                }
                onClick={(e) => handleButtons("weight", e)}
              >
                Previous
              </button>
              <button
                type="button"
                id="next"
                onClick={(e) => handleButtons("dashboard", e)}
                disabled={
                  bulkLoading ||
                  valueDriversCount === 0 ||
                  dimensionsCount === 0
                }
                className="btn btn-primary btn-sm"
              >
                Finish
              </button>
            </div>
          </div>
        </div>
      </div>
      {valueDriversCount === 0 ? (
        <NoFound text="Value Drivers" link="value-drivers" />
      ) : dimensionsCount === 0 ? (
        <NoFound text="Dimensions" link="value-drivers" />
      ) : (
        <div className="container-xxl pt-4 price-section-space position-relative">
          {uloading && (
            <div className="text-center spinner-table-loading">
              <td colSpan={8}>
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </td>
            </div>
          )}
          <div className="table-responsive price-table mb-5">
            <h6 className="mb-3">Value Modelling</h6>
            <table className="table user-table calculation-table mb-0">
              <WeightPriceTableHeadings
                isSubscribed={isSubscribed}
                segmentNames={segmentNames}
                segmentsCount={segmentsCount}
              />

              <tbody className={isTeamMember ? "pe-none " : ""}>
                <tr>
                  <td className="bg-gray-50">Market Size</td>
                  {segments?.map((segment, index) => (
                    <td key={index} className="bg-gray-50">
                      {segment?.segment?.marketSize}%
                    </td>
                  ))}
                  <td className="bg-gray-50"></td>
                </tr>
                <tr>
                  <td className="bg-gray-50">Strategic Importance</td>
                  {segments?.map((segment, index) => (
                    <td key={index} className="bg-gray-50">
                      {segment?.segment?.importance}%
                    </td>
                  ))}
                  <td className="bg-gray-50"></td>
                </tr>

                {priceModel?.map((priceModal, index) => {
                  const valueDriver = priceModal?.valueDriver;
                  const segments = priceModal?.segments;
                  const valueDriverAttrs = [];
                  if (valueDriver?.type === "") {
                    valueDriverAttrs.push(valueDriver?.category);
                  } else if (valueDriver?.category === "") {
                    valueDriverAttrs.push(valueDriver?.type);
                  } else {
                    valueDriverAttrs.push(
                      valueDriver?.type,
                      valueDriver?.category
                    );
                  }
                  return (
                    <tr key={index}>
                      <td>
                        {valueDriver?.name}
                        <p className="text-gray-500">
                          {valueDriverAttrs.join(" • ")}
                        </p>
                      </td>
                      {segments?.map((segment, index) => (
                        <td key={index}>
                          <select
                            data-segmentid={segment?.segment?.id}
                            data-valuedriverid={valueDriver?.id}
                            data-pricemodelid={segment?.priceModel?.id}
                            className="ms-n10 form-select"
                            value={segment?.selectedDimension?.id}
                            onChange={(e) => handleChange(e)}
                          >
                            {!segment?.selectedDimension?.id && (
                              <option value="">Select Dimension</option>
                            )}
                            {valueDriver?.restrictDimensions?.map(
                              (dimension) => (
                                <option
                                  key={dimension?.id}
                                  value={dimension?.id}
                                >
                                  {dimension?.value}
                                </option>
                              )
                            )}
                          </select>
                        </td>
                      ))}
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="position-relative price-collpase">
            <div className="table-responsive">
              <table className="table user-table calculation-table mb-0">
                <thead
                  className="cursor-pointer"
                  onClick={handleToggle}
                  aria-controls="table-collapse"
                  aria-expanded={open}
                >
                  <tr className="border-bottom">
                    <th className="w-13-p py-3 bg-success-550">
                      <div className="d-flex align-items-center">
                        Total Gross Value
                        <InfoTooltip text="The price points resulting from your input and our calculations" />
                      </div>
                    </th>
                    {priceModel[0]?.segments?.map((segment, index) => (
                      <th key={index} className="w-13-p py-3 bg-success-550">
                        {" "}
                        {currency}
                        {segment?.priceModel?.totalPrice.toFixed(2)}{" "}
                      </th>
                    ))}
                    <th className="w-4-p bg-success-550">
                      <div
                        className="text-end collapse-icon"
                        onClick={handleToggle}
                        aria-controls="table-collapse"
                        aria-expanded={open}
                      >
                        <i className="fa-solid fa-chevron-up"></i>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </th>
                  </tr>
                </thead>
                <Collapse in={open}>
                  <tbody
                    id="table-collapse"
                    className={isTeamMember ? "pe-none " : ""}
                  >
                    <tr>
                      <td className="bg-gray-50 py-3">
                        <div className="d-flex align-items-center">
                          Price after adjustments
                          <InfoTooltip text="Round the price point to render it more attractive for your potential customers" />
                        </div>
                      </td>
                      {segments?.map((segment, index) => (
                        <td key={index} className="bg-gray-50 py-3">
                          <div className="input-group">
                            <span className="input-group-text fs-14 pe-1 ps-0">
                              {currency}
                            </span>
                            <input
                              className="form-control ps-1"
                              type="number"
                              defaultValue={
                                segment?.priceModel?.psychologicalPricing
                              }
                              onChange={(e) => {
                                const newValue = e.target.value;
                                variables[index].psychologicalPricing =
                                  +newValue;
                                if (newValue >= 0) {
                                  // Only update state if the new value is non-negative
                                  handleResultFieldChange(
                                    e,
                                    `segment${index + 1}Name`,
                                    0
                                  );
                                }
                              }}
                            />
                          </div>
                        </td>
                      ))}
                      <td className="bg-gray-50"></td>
                    </tr>
                    <tr>
                      <td className="bg-gray-50 py-3">
                        <div className="d-flex align-items-center">
                          Delta b/w Bundles
                          <InfoTooltip text="Price delta vs. previous bundle" />
                        </div>
                      </td>
                      {segments?.map((segment, index) => (
                        <td
                          key={index}
                          className={`bg-gray-50 py-3 ${
                            parseFloat(
                              segment?.priceModel?.deltaBwBundles || 0
                            ) >= 0
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {" "}
                          <span className={index === 0 ? "d-none" : ""}>
                            {parseFloat(
                              segment?.priceModel?.deltaBwBundles || 0
                            ).toFixed(2)}
                            %
                          </span>{" "}
                        </td>
                      ))}
                      <td className="bg-gray-50"></td>
                    </tr>
                    <tr>
                      <td className="bg-gray-50 py-3">Competitor Pricing</td>
                      {segments?.map((segment, index) => (
                        <td key={index} className="bg-gray-50 py-3">
                          <div className="input-group">
                            <span className="input-group-text pe-1 ps-0 fs-14">
                              {currency}
                            </span>
                            <input
                              className="form-control ps-1"
                              type="number"
                              defaultValue={
                                segment?.priceModel?.competitorPricing
                              }
                              onChange={(e) => {
                                const newValue = e.target.value;
                                variables[index].competitorPricing = +newValue;
                                if (newValue >= 0) {
                                  // Only update state if the new value is non-negative
                                  handleResultFieldChange(
                                    e,
                                    `segment${index + 1}Name`,
                                    2
                                  );
                                }
                              }}
                            />
                          </div>
                        </td>
                      ))}
                      <td className="bg-gray-50"></td>
                    </tr>
                    <tr>
                      <td className="bg-gray-50 py-3">
                        <div className="d-flex align-items-center">
                          Delta vs Competition
                          <InfoTooltip text="Est. price delta vs. competition" />
                        </div>
                      </td>
                      {segments?.map((_, index) => (
                        <td key={index} className="bg-gray-50 py-3">
                          {replaceInfinityWithZero(
                            resultModels[3]?.[`segment${index + 1}Name`]
                          ) || 0}
                          %
                        </td>
                      ))}
                      <td className="bg-gray-50"></td>
                    </tr>
                  </tbody>
                </Collapse>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
